/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { DataContext } from './EmployeePage';
import DataTable from 'react-data-table-component';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Form from './Form';
import Filter from './Filter';
import StatusLabel from '@components/label/StatusLabel';
import UpdateStatus from './updateStatus';
import datetimeFormatter from '@utils/datetimeFormatter';
import ImportForm from './ImportForm';
import ResetPasswordDialog from './ResetPasswordDialog';
import ViewTypePerformance from './ViewTypePerformance';

function DataList(props) {
  const {
    data,
    reloadData,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalItems,
    filterParams,
    mergeFilters,
    loading,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showTPDetail, setShowTPDetail] = useState(false);
  const [formType, setFormType] = useState('CREATE');

  const [querySearch, setQuerySearch] = useState('');
  const [sort, setSort] = useState('asc');

  useEffect(() => {
    showFilter && setShowFilter(false);
  }, [showFilter]);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showImportForm && setShowImportForm(false);
  }, [showImportForm]);

  useEffect(() => {
    showUpdateStatus && setShowUpdateStatus(false);
  }, [showUpdateStatus]);

  useEffect(() => {
    showResetPassword && setShowResetPassword(false);
  }, [showResetPassword]);

  useEffect(() => {
    showTPDetail && setShowTPDetail(false);
  }, [showTPDetail]);

  const handleEdit = (row) => {
    setFormType('UPDATE');
    setSelectedRow(row);
    setShowForm(true);
  };

  const handleCreate = () => {
    setFormType('CREATE');
    setSelectedRow({});
    setShowForm(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    mergeFilters({ page: page, pageSize: pageSize });
    reloadData(filterParams, signal);

    return () => {
      controller.abort();
    };
  }, [page, pageSize, querySearch, sort]);

  const handleSearch = (e) => {
    setQuerySearch(e.target.value);
    mergeFilters({ q: e.target.value });
  };

  const handleSort = (column, sortDirection) => {
    if (column.sortField) {
      mergeFilters({
        order: column.sortField,
        sort: sortDirection === 'asc' ? 'ASC' : 'DESC',
      });

      setSort(sortDirection);
    }

    //reloadData(params, signal);
  };

  const handlePageChange = (page) => {
    setPage(page);
    mergeFilters({ page: page });
  };

  const handleRowsPerPageChange = (newPageSize, page) => {
    setPageSize(newPageSize);
    setPage(page); // To reset to the first page when changing the page size
    mergeFilters({ page: page, pageSize: newPageSize });
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 2400);
    };

    // Initial check for small screen
    handleResize();

    // Listen for window resize events and update state accordingly
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Load the convertToLocalTime function
  const formatDate = datetimeFormatter();

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <DropdownButton
          title={<i className="fas fa-ellipsis-v"></i>}
          variant="default"
        >
          <Dropdown.Item
            onClick={() => {
              handleEdit(row);
            }}
          >
            <i className="fas fa-edit me-2"></i> Edit Pegawai
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowResetPassword(true);
            }}
          >
            <i className="fas fa-key me-2"></i> Reset Password
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowTPDetail(true);
            }}
          >
            <i className="fas fa-hand-point-right me-2"></i> Kewenangan
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowUpdateStatus(true);
            }}
          >
            {row.status === 'ENABLE' ? (
              <div className="text-danger">
                <i className="fas fa-close me-2"></i> Nonaktifkan Pegawai
              </div>
            ) : (
              <div className="text-success">
                <i className="fas fa-check me-2"></i> Aktifkan Pegawai
              </div>
            )}
          </Dropdown.Item>
        </DropdownButton>
      ),
    },

    {
      button: true,
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-4 text-center w-100">
          <StatusLabel status={row.status} />
        </div>
      ),
      sortable: false,
      maxWidth: '90px',
      hide: 'sm',
    },

    {
      name: 'Nama Lengkap',
      sortField: 'name',
      sortable: true,
      selector: (row) => row.fullname,
      cell: (row) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-none d-md-inline-block">
            <div className="widget-icon widget-icon-md user rounded float-start me-5px mb-5px bg-gray-500 text-white">
              <i className="fa fa-user"></i>
            </div>
          </div>
          <div>
            <strong>{row.fullname}</strong>
            <br />
            {row.employee_gen && 'NIP: ' + row.employee_gen}{' '}
            <div className="d-sm-none d-inline-block">
              <StatusLabel status={row.status} />
            </div>
          </div>
        </div>
      ),
    },

    {
      name: 'Direkrut',
      selector: (row) => row.hire_date,
      cell: (row) => formatDate(row.hire_date, 'YYYY-MM-DD '),
      sortable: false,
      maxWidth: '100px',
      hide: 'md',
    },
    {
      name: 'Tipe Pegawai',
      selector: (row) => row.employee_type,
      cell: (row) => row.employee_type,
      sortable: false,
      maxWidth: '100px',
      hide: 'md',
    },

    {
      name: 'Golongan',
      selector: (row) => row.job_name,
      cell: (row) => row.job_name,
      sortable: false,
      maxWidth: '200px',
      hide: 'md',
    },

    {
      name: 'Jabatan',
      selector: (row) => row.section_name,
      cell: (row) => row.section_name,
      sortable: false,
      maxWidth: '240px',
      hide: 'md',
    },
  ];

  return (
    <>
      <div className="mb-3 d-sm-flex justify-content-between align-items-center gap-3">
        <div className="d-flex gap-3 mb-3 mb-sm-0">
          <button
            className="btn btn-default "
            onClick={() => setShowFilter(true)}
          >
            <i className="fas fa-filter me-2"></i> Filter
          </button>

          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fas fa-plus me-2"></i> Tambah
          </button>

          <button
            className="btn btn-success"
            onClick={() => setShowImportForm(true)}
          >
            <i className="fas fa-file-import me-2"></i> Impor
          </button>
        </div>
        <div className="text-right">
          <input
            type="text"
            style={{ minWidth: '280px' }}
            className="form-control"
            placeholder="Cari Nama Pegawai..."
            value={querySearch}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Filter show={showFilter} />
      <Form data={selectedRow} show={showForm} type={formType} />
      <ImportForm data={selectedRow} show={showImportForm} />
      <UpdateStatus data={selectedRow} show={showUpdateStatus} />
      <ResetPasswordDialog data={selectedRow} show={showResetPassword} />
      <ViewTypePerformance data={selectedRow} show={showTPDetail} />

      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        paginationServer
        paginationTotalRows={totalItems}
        paginationPerPage={pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onSort={handleSort}
        // paginationComponentOptions={{
        //   noRowsPerPage: true,
        // }}
        progressPending={loading}
        expandableRows={isSmallScreen}
        expandableRowsComponent={(row) => {
          return (
            <>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Nama Lengkap
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.fullname}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  NIP
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.employee_gen}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Direkrut
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatDate(row.data.hire_date, 'YYYY-MM-DD')}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Tipe Pegawai
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.employee_type}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Golongan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.job_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Gaji
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.salary}
                  </p>
                </div>
              </div>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Grade
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.grade}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Atasan Langsung
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.manager_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Satuan Organisasi
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.division_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Departemen
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.department_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Jabatan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.section_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Status
                </label>
                <div className="col-auto col-md-8">
                  <div className="text-start  lh-1 pt-2">
                    <StatusLabel status={row.data.status} />
                  </div>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Pembuatan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.created_by || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Dibuat
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatDate(row.data.created_at) || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Perubahan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.updated_by || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Diubah
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatDate(row.data.updated_at) || '-'}
                  </p>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

export default DataList;
