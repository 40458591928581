import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PeriodContext } from './ContractAssessmentPage';

function ViewDetail(props) {
  const value = props.data;
  const { selectedPeriod } = useContext(PeriodContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Rincian Kontrak Kerja {selectedPeriod?.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {value.cascading && (
          <div className="row">
            <label className="col-sm-3 col-form-label">Cascading</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.cascading}</p>
            </div>
          </div>
        )}

        <div className="row">
          <label className="col-sm-3 col-form-label">Proram</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.program}</p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Indikator</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.indicator}</p>
          </div>
        </div>

        {props?.showCategory && (
          <div className="row">
            <label className="col-sm-3 col-form-label">Kategori</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.category}</p>
            </div>
          </div>
        )}

        <div className="row">
          <label className="col-sm-3 col-form-label">Jabatan</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.unit} </p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Target Tahun (T.T)</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.target_year} </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">
            Target Semester (T.S)
          </label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.target_semester} </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Target Bulan (T.B)</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.target_month} </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewDetail;
