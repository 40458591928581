import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL } from '@config/api-config';

export const BASE_API = 'apis/api/client';
export const ADMIN_API = 'apis-admin/api/admin';
export const BASE_STORAGE_API = 'api/storage';

export function setToken(token) {
  Cookies.set('token', token, {
    expires: 3,
    secure: process.env.REACT_SECURE_TOKEN_COOKIES || false,
  });

  return token;
}

export function getToken() {
  return Cookies.get('token');
}

export function removeToken() {
  Cookies.remove('token');
}

export const authorizationHeader = {
  Authorization: 'Bearer ' + getToken(),
};

export const http = axios.create({
  baseURL: BASE_URL,
});

export const httpPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

export const httpStorage = axios.create({
  baseURL: BASE_URL + '/storage',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
