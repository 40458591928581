import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { assessmentComponentApi } from '@services/assessmentComponentApi';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { DataContext, PeriodContext } from './AssessmentComponentPage';

function GenerateButton(props) {
  const [showModal, setShow] = useState(false);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [abortController, setAbortController] = useState(null);

  const apiRequest = useHttpPrivateRequest();
  const { selectedMonthPeriod, periodid } = useContext(PeriodContext);
  const { list, reloadData } = useContext(DataContext);

  const handleShow = () => {
    setProgress(0);
    setIsLoading(false);
    setErrMsg(null);

    list?.length > 0
      ? setErrMsg(
          'Data Beban Kerja ' +
            selectedMonthPeriod?.label +
            ' sudah ada, jika melakukan generate ulang data tersebut akan dihapus'
        )
      : setErrMsg(null);

    setShow(true);
  };
  const handleClose = (e) => {
    setShow(false);
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleGenerate = async () => {
    setIsLoading(true);
    setProgress(0);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    setErrMsg(null);

    let start = Date.now();
    let generating = true;

    while (generating) {
      try {
        const request = await apiRequest(
          assessmentComponentApi.generate(periodid, signal)
        );
        const response = request?.data;
        if (response.status === 'success') {
          setProgress(100);
          setErrMsg(null);
          generating = false;
          setTimeout(() => {
            sendSuccessMessage(
              'Berhasil generate data Beban Kerja ' + selectedMonthPeriod?.label
            );

            reloadData(periodid, signal);

            setShow(false);
          }, 1000);
        }

        // Wait for a certain amount of time before making the next API call
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (Date.now() - start > 10000) {
          generating = false;
          setIsLoading(false);
          setErrMsg('Generate data terlalu lama, silakan generate ulang!');
          sendErrorMessage(
            'Gagal generate data Beban Kerja ' + selectedMonthPeriod?.label
          );
          throw new Error('Timeout');
        }

        // Update the progress bar
        setProgress((prevProgress) => prevProgress + 10);
      } catch (error) {
        sendErrorMessage(error?.response?.data?.message || error.message);
        setIsLoading(false);
        generating = false;
      }
    }

    //setIsLoading(false);
    //setShow(false);
  };

  return (
    <Fragment>
      <Button
        variant="primary"
        onClick={handleShow}
        title="Generate data Beban Kerja"
      >
        <i className="fas fa-refresh"></i>{' '}
        <span className="d-none d-md-inline-block">Generate Data</span>
      </Button>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            Generate Beban Kerja {selectedMonthPeriod?.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && (
            <div className="alert alert-danger" role="alert">
              {errMsg}
            </div>
          )}
          <ProgressBar
            now={progress}
            striped
            variant={errMsg ? 'danger' : 'success'}
            animated
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={handleGenerate}
            disabled={isLoading}
          >
            {isLoading
              ? 'Generating...'
              : errMsg
              ? 'Generate Ulang'
              : 'Generate'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default GenerateButton;
