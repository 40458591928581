import { useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { DataContext, PeriodContext } from './ContractAssessmentEvaluationPage';
import StatusLabel from '@components/label/StatusLabel';
import Approval from './Approval';
import PrintDocumentModal from '@pages/JobAssessment/ContractAssessment/PrintDocument';
import DocumentContent from '@pages/JobAssessment/ContractAssessment/DocumentContent';

function EmployeeDataList(props) {
  const columns = [
    {
      button: true,
      name: '#',
      minWidth: '60px',
      maxWidth: '160px',
      cell: (row) => (
        <div className="d-flex flex-column justify-content-start align-items-start gap-2">
          <Approval data={row} />
          <PrintDocumentModal content={<DocumentContent />} />
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-4 text-center w-100">
          <StatusLabel status={row.status} />
        </div>
      ),
      sortable: true,
      width: 'auto',
      maxWidth: '120px',
      hide: 'sm',
    },

    {
      name: 'Pegawai',
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-none d-md-inline-block">
            <div className="widget-icon widget-icon-md user rounded float-start me-5px mb-5px bg-gray-500 text-white">
              <i className="fa fa-user"></i>
            </div>
          </div>
          <div>
            <strong>{row.name}</strong>
            <br />
            {row.nip && 'NIP: ' + row.nip}
            <br />
            <div className="d-sm-none d-inline-block mt-1">
              Status: <StatusLabel status={row.status} />
            </div>
          </div>
        </div>
      ),
      sortable: true,
      minWidth: '120px',
    },
    {
      name: 'Periode',
      selector: (row) => row.period_id,
      cell: (row) => <div title={row.period_name}>{row.period_name}</div>,
      sortable: true,
      width: '200px',
      hide: 'md',
    },
  ];

  const { periodid } = useContext(PeriodContext);
  const { employeeData, reloadEmployeeData } = useContext(DataContext);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    periodid && reloadEmployeeData(periodid, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  return (
    <DataTable
      responsive={false}
      columns={columns}
      data={employeeData}
      dense
      pagination
      paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
    />
  );
}

export default EmployeeDataList;
