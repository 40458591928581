import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  useHttpPrivateRequest,
  useHttpStorageRequest,
} from '@hooks/useApiRequest';
import { logBookApi } from '@services/logBookApi';
import { storageApi } from '@services/storageApi';
import { DataContext } from './LogBookPage';
import { validateUrl } from '@utils/common';

function DeleteDialog(props) {
  const value = props.data;

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { data, setData, setSelectedRows, toggleCleared, setToggleCleared } =
    useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();
  const apiStorageRequest = useHttpStorageRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    let isDeleted = false;

    setAbortController(controller);

    try {
      const ids = value.map((row) => {
        return row.id;
      });

      await apiRequest(logBookApi.deleteActivity(ids, signal));

      setIsLoading(false);
      setData(data.filter((row) => !ids.includes(row.id)));

      sendSuccessMessage('Berhasil hapus data');

      props.onDeleted && props.onDeleted(true);

      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
      isDeleted = true;
      setShow(false);
    } catch (error) {
      isDeleted = false;
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }

    const attachments = value.filename || [];
    if (attachments && isDeleted) {
      setIsLoading(true);
      try {
        for (var i = 0; i < attachments.length; i++) {
          (function (i) {
            if (!validateUrl(attachments[i])) {
              setTimeout(function () {
                apiStorageRequest(storageApi.delete(attachments[i], signal));
              }, i * 3000);
            }
          })(i);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      size="sm"
      contentClassName="shadow"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Hapus Data Aktivitas</Modal.Title>
      </Modal.Header>
      <Modal.Body>Anda yakin akan menghapus aktivitas ini?</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button onClick={handleDelete} variant="danger" disabled={isLoading}>
          {isLoading ? 'Menghapus...' : 'Hapus'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteDialog;
