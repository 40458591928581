import { authApi } from '@services/authApi';
import { getToken } from '@services/api';
import useAuth from './useAuth';
import { useHttpRequest } from './useApiRequest';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const apiRequest = useHttpRequest(false);

  const refresh = async () => {
    //for next refresh token
    // const request = await http(authApi.refresh());
    // const response = request?.data;
    // const newToken = response?.data?.token;
    // setAuth((prev) => {
    //   return {
    //     ...prev,
    //     loggedInUser: profileRes?.data,
    //     roles: response?.data.roles,
    //     token: newToken,
    //   };
    // });
    // setToken(newToken);
    // return newToken;

    const newToken = getToken();
    if (newToken) {
      const profileReq = await apiRequest(authApi.profile());
      const profileRes = profileReq?.data;
      const check = await apiRequest(authApi.check(newToken));
      const roles = check?.data?.data?.roles || [];

      setAuth((prev) => {
        return {
          ...prev,
          loggedInUser: profileRes?.data,
          roles: roles,
          token: newToken,
        };
      });

      return getToken();
    }

    setAuth({});
  };
  return refresh;
};

export default useRefreshToken;
