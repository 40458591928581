import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { userRoleApi } from '@services/userRoleApi';
import { DataContext } from './UserRolePage';

function DeleteDialog(props) {
  const value = props.data;

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { data, setData, totalItems, setTotalItems } = useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      await apiRequest(
        userRoleApi.deleteByAdmin(
          { userId: value.user_id, roleId: value.role_id },
          signal
        )
      );
      setIsLoading(false);

      setData(
        data.filter((row) => {
          const deletedRow =
            row.role_id === value.role_id && row.user_id === value.user_id;
          return !deletedRow;
        })
      );
      setTotalItems(totalItems - 1);

      sendSuccessMessage('Berhasil hapus peran pengguna');

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Hapus Peran</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Apakah anda yakin akan menghapus peran <b>{value?.role_name}</b> pada{' '}
        <b>{value?.user_name}</b> ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button onClick={handleDelete} variant="danger" disabled={isLoading}>
          {isLoading ? 'Hapus...' : 'Hapus'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteDialog;
