import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import Select from 'react-select';
import { typePerformanceApi } from '@services/typePerformanceApi';
import { DataContext } from './ViewTypePerformance';

function TypePerformanceForm(props) {
  const employee = props.employeeData;

  const { setData } = useContext(DataContext);

  const [typePerformanceOption, setTypePerformanceOption] = useState([]);
  const [selectedPerformance, setSelectedPerformance] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);
    getTypePerformanceOptions();
    setSelectedPerformance('');
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      employee_id: employee?.id,
      performance_type: selectedPerformance?.value,
    };

    try {
      await apiRequest(typePerformanceApi.createByAdmin(payload, signal));

      setIsLoading(false);

      setData((prevList) => {
        return [...prevList, payload];
      });

      sendSuccessMessage('Berhasil menambahkan peran baru');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const getTypePerformanceOptions = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        typePerformanceApi.getListOptionsByAdmin(signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.performance_type, label: item.performance_type };
      });
      setTypePerformanceOption(options);
    } catch (error) {
      setTypePerformanceOption([]);
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      contentClassName="shadow"
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Tambah Kewenangan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <div className="row">
            <label className="col-sm-3 col-form-label">Nama Pegawai</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{employee?.fullname}</p>
            </div>
          </div>
          <div className="row">
            <label className="col-sm-3 col-form-label">NIP</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">
                {employee?.employee_gen}
              </p>
            </div>
          </div>
        </div>

        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row">
            <label className="col-sm-3 col-form-label">
              Kewenangan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-sm-9">
              <Select
                placeholder="Pilih kewenangan"
                options={typePerformanceOption}
                value={selectedPerformance || ''}
                onChange={setSelectedPerformance}
                required
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? 'Menyimpan... ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TypePerformanceForm;
