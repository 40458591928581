import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Function to load Day.js plugins and create the convertToLocalTime function
const datetimeFormatter = (timezoneString = '') => {
  // Load the UTC and timezone plugins
  dayjs.extend(utcPlugin);
  dayjs.extend(timezone);

  // Function to convert a date string to local time
  const formatDate = (dateString, format = 'YYYY-MM-DD HH:mm') => {
    if (!dateString) return '';

    if (timezoneString) {
      dayjs.tz.setDefault(timezoneString);
      return dayjs(dateString).tz(timezoneString).format(format);
    }

    return dayjs(dateString).format(format);
  };

  return formatDate;
};

export default datetimeFormatter;
