import { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';

function ComponentsTable(props) {
  const [data, setData] = useState([]);
  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        //const request = await httpPrivate(userApi.get(controller.signal));
        //const response = request?.data;
        const response = {
          data: [
            {
              id: 1,
              periodName: 'Januari 2023',
              target: '1',
              unit: '1',
              quality: '1',
              time: '1',
            },
            {
              id: 2,
              periodName: 'Januari 2023',
              target: '1',
              unit: '1',
              quality: '1',
              time: '1',
            },
            {
              id: 3,
              periodName: 'Januari 2023',
              target: '1',
              unit: '1',
              quality: '1',
              time: '1',
            },
            {
              id: 4,
              periodName: 'Januari 2023',
              target: '1',
              unit: '1',
              quality: '1',
              time: '1',
            },
            {
              id: 5,
              periodName: 'Januari 2023',
              target: '1',
              unit: '1',
              quality: '1',
              time: '1',
            },
          ],
        };

        isMounted && setData(response?.data);
      } catch (error) {}
    };

    getData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [apiRequest]);

  return (
    <Fragment>
      <Table striped size="sm">
        <thead>
          <tr>
            <td>#</td>
            <td>Nama Periode</td>
            <td>NIP</td>
            <td>Nama</td>
            <td>P1</td>
            <td>P2</td>
            <td>PPN</td>
            <td>Gaji</td>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.periodName}</td>
                  <td>0001</td>
                  <td>Lorem Ipsum</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </>
            ))}
        </tbody>
      </Table>
    </Fragment>
  );
}

export default ComponentsTable;
