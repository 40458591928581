import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DataContext } from './LogBookPage';
import ActivityForm from './ActivityForm';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import ViewDetail from './ViewDetail';
import DeleteDialog from './DeleteDialog';
import DownloadAttachement from './DownloadAttachement';
import dayjs from 'dayjs';

function DataList(props) {
  const { data, getData, selectedRows, setSelectedRows } =
    useContext(DataContext);
  // const { startPeriod, endPeriod } = useContext(PeriodContext);

  const [showForm, setShowForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const currentDate = dayjs();
  const defaultFirstDate = currentDate.startOf('month');
  const defaultLastDate = currentDate.endOf('month');

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    let period = {
      startDate: defaultFirstDate.format('YYYY-MM-DD'),
      endDate: defaultLastDate.format('YYYY-MM-DD'),
    };

    getData(period, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => {
        return (
          <DropdownButton
            title={<i className="fas fa-ellipsis-v"></i>}
            variant="default"
          >
            <Dropdown.Item
              onClick={() => {
                setSelectedRow(row);
                setShowDetail(true);
              }}
            >
              <i className="fas fa-eye"></i> Lihat Rincian
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              className="text-danger"
              onClick={() => {
                setSelectedRows([row]);
                setShowDelete(true);
              }}
            >
              <i className="fas fa-trash"></i> Hapus Data
            </Dropdown.Item>
          </DropdownButton>
        );
      },
    },

    {
      button: true,
      name: '#',
      width: '48px',
      cell: (row) => {
        let attachements = row.filename ? row.filename.split(',') : [];
        return (
          attachements.length > 0 && (
            <DropdownButton
              title={<i className="fas fa-download"></i>}
              variant="default"
            >
              <h5 className="text-center text-muted">Download Lampiran</h5>
              <Dropdown.Divider />
              {attachements.map((attachement, index) => {
                return (
                  <DownloadAttachement
                    key={index}
                    label={'Lampiran ...' + attachement.slice(-12)}
                    filename={attachement}
                    type={'dropdown'}
                  />
                );
              })}
            </DropdownButton>
          )
        );
      },
    },

    {
      name: 'Tanggal',
      selector: (row) => row.start_date,
      cell: (row) => (
        <>
          {row.start_date.slice(0, 16)} - {row.end_date.slice(10, 16)}
        </>
      ),
      sortable: true,
      width: '100px',
      hide: 'sm',
    },

    {
      name: 'Judul',
      selector: (row) => row.title,
      cell: (row) => (
        <p
          className="cursor-pointer btn-link text-decoration-none m-0 p-0"
          onClick={() => {
            setSelectedRow(row);
            setShowDetail(true);
          }}
        >
          {row.title}
        </p>
      ),
      sortable: true,
    },

    {
      name: 'Keterangan',
      selector: (row) => row.detail,
      cell: (row) => row.detail,
      sortable: true,
      hide: 'md',
      minWidth: '45%',
    },
    {
      name: 'Kuantitas',
      selector: (row) => row.qty,
      cell: (row) => row.qty,
      sortable: true,
      width: '90px',
      hide: 'lg',
    },
    {
      name: 'Waktu',
      selector: (row) => row.timeMinutes,
      cell: (row) => row.timeMinutes,
      sortable: true,
      width: '90px',
      hide: 'lg',
    },
  ];

  return (
    <>
      <DeleteDialog data={selectedRows} show={showDelete} />
      <ViewDetail selectedId={selectedRow.id} show={showDetail} />
      <ActivityForm show={showForm} />
      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
      />
    </>
  );
}

export default DataList;
