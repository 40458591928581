export function stringToDate(dateString) {
  try {
    const dateObject = new Date(Date.parse(dateString));

    if (isNaN(dateObject)) {
      //throw new Error("Invalid date string. Please provide a valid date.");
      return '';
    }

    return dateObject;
  } catch (e) {
    //throw new Error("Error converting Date object to string.");
    return '';
  }
}

export function dateToString(dateObject, formatDate = 'YYYY-MM-DD') {
  try {
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    let formattedDate = formatDate.toLowerCase().replace('yyyy', year);
    formattedDate = formattedDate.replace('mm', month);
    formattedDate = formattedDate.replace('dd', day);

    return formattedDate;
  } catch (e) {
    //throw new Error("Error converting Date object to string.");
    return '';
  }
}

export function validateUrl(url) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  if (url.match(urlPattern)) {
    return true;
  }
  return false;
}

export function formatRupiah(number) {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Rp. 0';
  }

  // Convert the number to a string and add commas as thousands separator
  let formattedNumber = number.toLocaleString('id-ID');

  // Add 'Rp' and return the formatted string
  return `Rp. ${formattedNumber}`;
}
