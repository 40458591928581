import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext, PeriodContext } from './ContractAssessmentPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { contractAssessmentApi } from '@services/contractAssessmentApi';

function EditForm(props) {
  const value = props.data;
  const { selectedPeriod } = useContext(PeriodContext);
  const [unit, setUnit] = useState(value.unit);

  const targetCount =
    parseFloat(value?.target_year) +
    parseFloat(value?.target_semester) +
    parseFloat(value?.target_month);

  const [target, setTarget] = useState(targetCount);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setUnit(value.unit);
    setTarget(targetCount);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { setList } = useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      unit,
      target: parseFloat(target),
    };

    try {
      await apiRequest(contractAssessmentApi.update(payload, signal));

      setIsLoading(false);

      //update datatable
      setList((prevList) => {
        return prevList.map((prev) => {
          if (prev.id === value.id) {
            if (value.target_year > 0) {
              return { ...prev, unit, target_year: target };
            } else if (value.target_semester > 0) {
              return { ...prev, unit, target_semester: target };
            } else if (value.target_month > 0) {
              return { ...prev, unit, target_month: target };
            }

            return { ...prev, unit };
          }
          return prev;
        });
      });

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Edit Kontrak Kerja {selectedPeriod?.label} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="editForm" onSubmit={handleSubmit}>
          {value.cascading && (
            <div className="row">
              <label className="col-sm-3 col-form-label">Cascading</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.cascading}</p>
              </div>
            </div>
          )}

          <div className="row">
            <label className="col-sm-3 col-form-label">Program</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.program}</p>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Indikator</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.indicator}</p>
            </div>
          </div>

          {props?.showCategory && (
            <div className="row">
              <label className="col-sm-3 col-form-label">Kategori</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.category}</p>
              </div>
            </div>
          )}

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Jabatan</label>
            <div className="col-sm-9">
              <select
                className="form-control"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
              >
                <option value="">--[ Pilih Jabatan ]--</option>

                <option key="Dokumen" value="Dokumen">
                  Dokumen
                </option>
                <option key="Kegiatan" value="Kegiatan">
                  Kegiatan
                </option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Target</label>
            <div className="col-sm-9">
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="editForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          Perbarui
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditForm;
