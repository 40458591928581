import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './UserRolePage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import Select from 'react-select';
import { userRoleApi } from '@services/userRoleApi';

function Form(props) {
  const { setData, totalItems, setTotalItems } = useContext(DataContext);

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);
    getRoles();
    getUsers();
    setSelectedUser('');
    setSelectedRole('');
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      userId: selectedUser?.value,
      roleId: selectedRole?.value,
    };

    try {
      await apiRequest(userRoleApi.createByAdmin(payload, signal));

      setIsLoading(false);

      setData((prevList) => {
        return [
          ...prevList,
          {
            user_id: payload?.userId,
            user_name: selectedUser?.user_name,
            employee_gen: selectedUser?.nip,
            role_id: payload?.roleId,
            role_name: selectedRole?.label,
          },
        ];
      });

      setTotalItems(totalItems + 1);

      sendSuccessMessage('Berhasil menambahkan peran baru');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const getRoles = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(userRoleApi.getListRoleByAdmin(signal));

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.role_id, label: item.role_name };
      });
      setRoleOptions(options);
    } catch (error) {
      setRoleOptions([]);
    }
  };

  const getUsers = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        userRoleApi.getListActiveUserByAdmin(signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return {
          value: item.user_id,
          label: `${item.user_name} (NIP: ${item.employee_gen})`,
          user_name: item.user_name,
          nip: item.employee_gen,
        };
      });

      setUserOptions(options);
    } catch (error) {
      setUserOptions([]);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Tambah Peran Pengguna</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Nama Pengguna <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih nama pengguna"
                options={userOptions}
                value={selectedUser || ''}
                onChange={(selected) => {
                  setSelectedUser(selected);
                }}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Peran <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih peran"
                options={roleOptions}
                value={selectedRole || ''}
                onChange={setSelectedRole}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? 'Menyimpan... ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
