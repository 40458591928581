function InfoTotal(props) {
  const total = props.total;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <hr className="m-0" />
          <div className="row pt-3">
            <div className="col-sm-4 mb-3">
              <div className="row">
                <div className="col-auto fs-5 text-end">Total Target:</div>
                <div className="col fw-bold fs-5">{total.target}</div>
              </div>
            </div>

            <div className="col-sm-4 mb-3">
              <div className="row">
                <div className="col-auto fs-5 text-end">Total Kualitas:</div>
                <div className="col fw-bold fs-5">{total.quality}</div>
              </div>
            </div>

            <div className="col-sm-4 mb-3">
              <div className="row">
                <div className="col-auto fs-5 text-end">Total Waktu:</div>
                <div className="col fw-bold fs-5">{total.time}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoTotal;
