import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './EmployeePage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { stringToDate } from '@utils/common';
import Select from 'react-select';
import { employeeApi } from '@services/employeeApi';
import { divisionApi } from '@services/divisionApi';
import { sectionApi } from '@services/sectionApi';
import { jobsApi } from '@services/jobsApi';
import { departmentApi } from '@services/departmentApi';
import datetimeFormatter from '@utils/datetimeFormatter';

function Form(props) {
  const value = props.data;
  const isEdit = props.type === 'UPDATE';

  const { setData } = useContext(DataContext);

  const employeeTypeOptions = [
    { value: 'PNS', label: 'PNS' },
    { value: 'PPPK', label: 'PPPK' },
    { value: 'BLU', label: 'BLU' },
    { value: 'PPNPN', label: 'PPNPN' },
  ];

  const [employeeGen, setEmployeeGen] = useState();
  const [firstName, setFirstName] = useState();
  const [midlename, setMidlename] = useState();
  const [lastName, setLastName] = useState();
  const [filename, setFilename] = useState();
  const [firstDegree, setFirstDegree] = useState();
  const [lastDegree, setLastDegree] = useState();
  const [hireDate, setHireDate] = useState();
  const [employeeType, setEmployeeType] = useState();
  const [salary, setSalary] = useState();
  const [grade, setGrade] = useState();

  const [jobOptons, setJobOptons] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');

  const [managerOptons, setManagerOptons] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');

  const [divisionOptons, setDivisionOptons] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');

  const [departmentOptons, setDepartmentOptons] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [sectionOptons, setSectionOptons] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const initialForm = () => {
    setEmployeeGen('');
    setFirstName('');
    setMidlename('');
    setLastName('');
    setFilename('');
    setFirstDegree('');
    setLastDegree('');
    setHireDate('');
    setEmployeeType('');
    setSalary('');
    setGrade('');
  };

  const handleShow = () => {
    setIsLoading(false);
    initialForm();

    if (isEdit) {
      setEmployeeGen(value?.employee_gen);
      setFirstName(value?.first_name);
      setMidlename(value?.middle_name);
      setLastName(value?.last_name);
      setFilename(value?.filename);
      setFirstDegree(value?.first_degree);
      setLastDegree(value?.last_degree);
      setHireDate(stringToDate(value?.hire_date));
      setSalary(value?.salary);
      setGrade(value?.grade);

      const optionIndex = employeeTypeOptions.findIndex(
        (option) => option.value === value?.employee_type
      );

      setEmployeeType(employeeTypeOptions[optionIndex]);
    }

    getJob(value?.job_id);
    getManager(value?.manager_id);
    getDivision(value?.division_id);
    getDepartment(value?.department_id);
    getSection(value?.section_id);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const getJob = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(jobsApi.getListByAdmin(params, signal));

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.title };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setJobOptons(options);
      setSelectedJob(options[indexOption]);
    } catch (error) {
      setJobOptons([]);
      setSelectedJob('');
    }
  };

  const getDivision = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(
        divisionApi.getListByAdmin(params, signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.division_name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setDivisionOptons(options);

      setSelectedDivision(options[indexOption]);
    } catch (error) {
      setDivisionOptons([]);
      setSelectedDivision('');
    }
  };

  const getSection = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(
        sectionApi.getListByAdmin(params, signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.section_name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setSectionOptons(options);
      setSelectedSection(options[indexOption]);
    } catch (error) {
      setSectionOptons([]);
      setSelectedSection('');
    }
  };

  const getManager = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(employeeApi.getListOptions(signal));

      const response = request?.data?.data;
      const options = response.map((item) => {
        return {
          value: item.id,
          label: `${item.fullname} (NIP: ${item.employee_gen})`,
          fullname: item.fullname,
          employee_gen: item.employee_gen,
        };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setManagerOptons(options);

      setSelectedManager(options[indexOption]);
    } catch (error) {
      setManagerOptons([]);
      setSelectedManager('');
    }
  };

  const getDepartment = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(
        departmentApi.getListByAdmin(params, signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.department_name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setDepartmentOptons(options);
      setSelectedDepartment(options[indexOption]);
    } catch (error) {
      setDepartmentOptons([]);
      setSelectedDepartment('');
    }
  };

  const formatDate = datetimeFormatter();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      employee_gen: employeeGen,
      first_name: firstName,
      middle_name: midlename,
      last_name: lastName,
      filename: filename,
      first_degree: firstDegree,
      last_degree: lastDegree,
      hire_date: hireDate,
      employee_type: employeeType?.value,
      salary: parseFloat(salary),
      grade: grade,
      job_id: selectedJob?.value,
      manager_id: selectedManager?.value,
      division_id: selectedDivision?.value,
      department_id: selectedDepartment?.value,
      section_id: selectedSection?.value,
    };

    const fullName = `${firstDegree || ''} ${firstName || ''} ${
      midlename || ''
    } ${lastName || ''} ${lastDegree || ''}`;

    const refreshedColumn = {
      ...payload,
      fullname: fullName,
      job_name: selectedJob?.label,
      manager_name: selectedManager?.fullname,
      department_name: selectedDepartment?.label,
      division_name: selectedDivision?.label,
      section_name: selectedSection?.label,
      status: value?.status || 'ENABLE',
      created_at: value?.created_at || formatDate(new Date()),
      created_by: value?.created_by,
      updated_at: value?.updated_at,
      updated_by: value?.updated_by,
    };

    const apiConfig = isEdit
      ? employeeApi.updateByAdmin(payload, signal)
      : employeeApi.createByAdmin(payload, signal);

    try {
      await apiRequest(apiConfig);

      setIsLoading(false);

      if (isEdit) {
        //update datatable
        setData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === value.id) {
              return {
                ...prev,
                ...refreshedColumn,
              };
            }
            return prev;
          });
        });
      } else {
        setData((prevList) => {
          return [...prevList, refreshedColumn];
        });
      }

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleEmployeeTypeChange = async (e) => {
    setEmployeeType(e);
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Pegawai</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              NIP <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan Nomor Induk Pegawai"
                value={employeeGen}
                onChange={(e) => setEmployeeGen(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Nama Pegawai <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama depan"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama tengah"
                    value={midlename}
                    onChange={(e) => setMidlename(e.target.value)}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nama belakang"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Gelar</label>
            <div className="col-12 col-sm-8">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Gelar depan"
                    value={firstDegree}
                    onChange={(e) => setFirstDegree(e.target.value)}
                  />
                </div>

                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Gelar belakang"
                    value={lastDegree}
                    onChange={(e) => setLastDegree(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Tanggal Mulai Kerja <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                selected={hireDate}
                onChange={setHireDate}
                placeholderText="Pilih tanggal mulai kerja"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Tipe Pegawai <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih tipe pegawai"
                options={employeeTypeOptions}
                value={employeeType || ''}
                onChange={handleEmployeeTypeChange}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Golongan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih golongan"
                options={jobOptons}
                value={selectedJob || ''}
                onChange={(selected) => {
                  setSelectedJob(selected);
                }}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Gaji</label>
            <div className="col-12 col-sm-8">
              <input
                type="number"
                step={2}
                className="form-control"
                placeholder="Masukkan Gaji"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Grade</label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan Grade"
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Atasan Langsung <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih atasan langsung"
                options={managerOptons}
                value={selectedManager || ''}
                onChange={(selected) => {
                  setSelectedManager(selected);
                }}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Satuan Organisasi <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih Satuan Organisasi"
                options={divisionOptons}
                value={selectedDivision || ''}
                onChange={(selected) => {
                  setSelectedDivision(selected);
                }}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Unit Organisasi <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih Unit Organisasi"
                options={departmentOptons}
                value={selectedDepartment || ''}
                onChange={(selected) => {
                  setSelectedDepartment(selected);
                }}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Jabatan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih Jabatan"
                options={sectionOptons}
                value={selectedSection || ''}
                onChange={(selected) => {
                  setSelectedSection(selected);
                }}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
