import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PeriodContext } from './ResultAchievementPage';
import DataTable from 'react-data-table-component';

function ViewDetail(props) {
  const value = props.data;
  const { selectedPeriod } = useContext(PeriodContext);

  const histories = value.histories || [];

  const columns = [
    {
      name: 'Nilai Sebelumnya',
      selector: (row) => row.before_result,
      cell: (row) => row.before_result,
      width: '130px;',
      sortable: true,
    },
    {
      name: 'Nilai',
      selector: (row) => row.after_result,
      cell: (row) => row.after_result,
      sortable: true,
      width: '130px;',
    },
    {
      name: 'Verifikator',
      selector: (row) => row.verificator_name,
      cell: (row) => row.verificator_name,
      sortable: true,
      width: '220px;',
      hide: 'md',
    },
    {
      name: 'Komentar',
      selector: (row) => row.comment,
      cell: (row) => row.comment,
      sortable: true,
      hide: 'md',
    },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Hasil Penilaian {selectedPeriod?.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label className="col-sm-3 col-form-label">Nama Pegawai</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.name}</p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Periode</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.periode_name}</p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Nama Atasan Langsung</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.leader_name} </p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Nilai</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">
              {value.apraisal_result} %
            </p>
          </div>
        </div>

        <h5 className="mt-5">Riwayat Verifikasi</h5>
        <DataTable
          expandableRows
          expandableRowsComponent={(row) => {
            return (
              <>
                <div className="row">
                  <label className="col-sm-2 col-form-label">
                    Nilai Sebelumnya
                  </label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal">
                      {row.data.before_result} %
                    </p>
                  </div>
                </div>

                <div className="row">
                  <label className="col-sm-2 col-form-label">Nilai</label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal">
                      {row.data.after_result} %
                    </p>
                  </div>
                </div>

                <div className="row">
                  <label className="col-sm-2 col-form-label">Verifikator</label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal">
                      {row.data.verificator_name}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <label className="col-sm-2 col-form-label">Komentar</label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal">
                      {row.data.comment}
                    </p>
                  </div>
                </div>
              </>
            );
          }}
          columns={columns}
          data={histories}
          pagination
          paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewDetail;
