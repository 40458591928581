import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

const PrintDocumentModal = ({ content }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 2cm 3cm;
        fontFamily: 'times new roman',
      }

      @media print {
        body {
          margin: 0;
          padding: 0;
          font-size: 16px;
        }
      }

      .page-break {
        page-break-before: always;
      }
    `,
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleShow}
        title="Cetak data Beban Kerja"
      >
        <i className="fas fa-print"></i>{' '}
        <span className="d-none d-md-inline-block">Cetak</span>
      </Button>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Print Beban Kerja</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center"
          style={{
            height: '75vh',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              fontSize: '12pt',
              backgroundColor: 'white',
              width: '210mm',
              margin: '30mm 20mm',
            }}
          >
            <div ref={componentRef}>{content}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Tutup
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            <i className="fa fa-print"></i> Print
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrintDocumentModal;
