import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { contractAssessmentApi } from '@services/contractAssessmentApi';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DataTable from 'react-data-table-component';
import { DataContext, PeriodContext } from './ContractAssessmentPage';
import Select from 'react-select';

function DuplicateButton(props) {
  const [showModal, setShow] = useState(false);

  const { periodid, periodOptions, selectedPeriod } = useContext(PeriodContext);
  const { reloadData } = useContext(DataContext);

  const [selectedCopyPeriod, setSelectedCopyPeriod] = useState(null);

  const [dataCopy, setDataCopy] = useState([]);
  const [selectedCopyRows, setSelectedCopyRows] = useState([]);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [abortController, setAbortController] = useState(null);

  const apiRequest = useHttpPrivateRequest();

  const intialState = () => {
    setProgress(0);
    setIsLoading(false);
    setErrMsg(null);
    setDataCopy([]);
    setSelectedCopyRows([]);
  };

  const handleShow = () => {
    intialState();
    setSelectedCopyPeriod(null);
    setShow(true);
  };
  const handleClose = (e) => {
    intialState();
    setShow(false);
  };

  const handleRowSelectedCopy = (rows) => {
    !isLoading && setSelectedCopyRows(rows.selectedRows);
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDuplicate = async () => {
    setIsLoading(true);
    setProgress(0);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    setErrMsg(null);

    let start = Date.now();
    let duplicating = true;

    while (duplicating) {
      try {
        const payload = selectedCopyRows.map((row) => {
          return { id: row.id };
        });
        const request = await apiRequest(
          contractAssessmentApi.duplicate(periodid, payload, signal)
        );
        const response = request?.data;
        if (response.status === 'success') {
          setProgress(100);
          setErrMsg(null);
          duplicating = false;
          setTimeout(() => {
            sendSuccessMessage(
              'Berhasil duplikasi data kontrak kerja  ' +
                selectedPeriod?.label +
                ' dari ' +
                selectedCopyPeriod?.label
            );

            reloadData(periodid, signal);
            intialState();

            setShow(false);
          }, 1000);
        }

        // Wait for a certain amount of time before making the next API call
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (Date.now() - start > 10000) {
          duplicating = false;
          setIsLoading(false);
          setErrMsg('Duplicate data terlalu lama, silakan duplicate ulang!');
          sendErrorMessage(
            'Gagal duplicate data kontrak kerja ' + selectedCopyPeriod?.label
          );
          throw new Error('Timeout');
        }

        // Update the progress bar
        setProgress((prevProgress) => prevProgress + 10);
      } catch (error) {
        sendErrorMessage(error?.response?.data?.message || error.message);
        setIsLoading(false);
        duplicating = false;
      }
    }

    //setIsLoading(false);
    //setShow(false);
  };

  const periodidcopy = selectedCopyPeriod?.value;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const getDataCopy = async (periodid, signal) => {
      try {
        const request = await apiRequest(
          contractAssessmentApi.get(periodid, signal)
        );
        const response = request?.data;
        setDataCopy(response?.data);
      } catch (error) {}
    };

    periodidcopy && getDataCopy(periodidcopy, signal);
  }, [apiRequest, periodidcopy]);

  const columns = [
    {
      name: 'Program',
      selector: (row) => row.program,
      cell: (row) => row.program,
    },
  ];

  return (
    <Fragment>
      <Button
        variant="primary"
        onClick={handleShow}
        title="Duplikasi data kontrak kerja"
      >
        <i className="fas fa-copy"></i>{' '}
        <span className="d-none d-md-inline-block">Duplikasi Data</span>
      </Button>

      <Modal
        show={showModal}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            Duplikasi Kontrak Kerja {selectedPeriod?.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Select
              options={periodOptions}
              defaultValue={selectedCopyPeriod}
              onChange={setSelectedCopyPeriod}
              isDisabled={isLoading}
            />
          </div>

          {errMsg && (
            <div className="alert alert-danger" role="alert">
              {errMsg}
            </div>
          )}
          <div className="mt-3 mb-3">
            <ProgressBar
              now={progress}
              striped
              variant={errMsg ? 'danger' : 'success'}
              animated
            />
          </div>

          <DataTable
            responsive={false}
            keyField="id"
            selectableRows
            selectedRows={selectedCopyRows}
            onSelectedRowsChange={handleRowSelectedCopy}
            selectableRowDisabled={(row) => {
              return isLoading;
            }}
            columns={columns}
            data={dataCopy?.list ?? []}
            expandableRows
            expandableRowsComponent={(row) => {
              return (
                <>
                  {row?.data?.cascading && (
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Cascading
                      </label>
                      <div className="col-sm-9">
                        <p className="col-form-label fw-normal">
                          {row?.data?.cascading}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Program</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.program}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Indikator</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.indicator}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Satuan</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.unit}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Tahun (T.T)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_year}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Semester (T.S)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_semester}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Bulan (T.B)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_month}{' '}
                      </p>
                    </div>
                  </div>
                </>
              );
            }}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={handleDuplicate}
            disabled={isLoading || selectedCopyRows.length === 0}
          >
            {isLoading
              ? 'Menduplikasi...'
              : errMsg
              ? 'Duplikasi Ulang'
              : 'Duplikasi'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default DuplicateButton;
