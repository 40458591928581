/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { DataContext } from './PerformancePage';
import DataTable from 'react-data-table-component';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Form from './Form';
import Filter from './Filter';
import StatusLabel from '@components/label/StatusLabel';
import datetimeFormatter from '@utils/datetimeFormatter';
import ImportForm from './ImportForm';
import DeletePerformanceDialog from './DeletePerformanceDialog';

function DataList(props) {
  const {
    data,
    reloadData,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalItems,
    filterParams,
    mergeFilters,
    loading,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showTPDetail, setShowTPDetail] = useState(false);
  const [formType, setFormType] = useState('CREATE');

  const [querySearch, setQuerySearch] = useState('');
  const [sort, setSort] = useState('asc');

  useEffect(() => {
    showFilter && setShowFilter(false);
  }, [showFilter]);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showImportForm && setShowImportForm(false);
  }, [showImportForm]);

  useEffect(() => {
    showDeleteDialog && setShowDeleteDialog(false);
  }, [showDeleteDialog]);

  useEffect(() => {
    showResetPassword && setShowResetPassword(false);
  }, [showResetPassword]);

  useEffect(() => {
    showTPDetail && setShowTPDetail(false);
  }, [showTPDetail]);

  const handleEdit = (row) => {
    setFormType('UPDATE');
    setSelectedRow(row);
    setShowForm(true);
  };

  const handleCreate = () => {
    setFormType('CREATE');
    setSelectedRow({});
    setShowForm(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    mergeFilters({ page: page, pageSize: pageSize });
    reloadData(filterParams, signal);

    return () => {
      controller.abort();
    };
  }, [page, pageSize, querySearch, sort]);

  const handleSearch = (e) => {
    setQuerySearch(e.target.value);
    mergeFilters({ q: e.target.value });
  };

  const handleSort = (column, sortDirection) => {
    if (column.sortField) {
      mergeFilters({
        order: column.sortField,
        sort: sortDirection === 'asc' ? 'ASC' : 'DESC',
      });

      setSort(sortDirection);
    }

    //reloadData(params, signal);
  };

  const handlePageChange = (page) => {
    setPage(page);
    mergeFilters({ page: page });
  };

  const handleRowsPerPageChange = (newPageSize, page) => {
    setPageSize(newPageSize);
    setPage(page); // To reset to the first page when changing the page size
    mergeFilters({ page: page, pageSize: newPageSize });
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 2400);
    };

    // Initial check for small screen
    handleResize();

    // Listen for window resize events and update state accordingly
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Load the convertToLocalTime function
  const formatDate = datetimeFormatter();

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <DropdownButton
          title={<i className="fas fa-ellipsis-v"></i>}
          variant="default"
        >
          <Dropdown.Item
            onClick={() => {
              handleEdit(row);
            }}
          >
            <i className="fas fa-edit me-2"></i> Edit Uraian Tugas
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowDeleteDialog(true);
            }}
          >
            <div className="text-danger">
              <i className="fas fa-close me-2"></i> Hapus Uraian Tugas
            </div>

            {/* {row.status === 'ENABLE' ? (
              <div className="text-danger">
                <i className="fas fa-close me-2"></i> Hapus Kewenangan
              </div>
            ) : (
              <div className="text-success">
                <i className="fas fa-check me-2"></i> Aktifkan Kewenangan
              </div>
            )} */}
          </Dropdown.Item>
        </DropdownButton>
      ),
    },

    {
      name: 'Kategori',
      selector: (row) => row.performance_category,
      cell: (row) => row.performance_category,
      sortable: false,
      maxWidth: '100px',
    },
    {
      name: 'Kelompok Jabatan/Jabatan',
      selector: (row) => row.performance_type,
      cell: (row) => row.performance_type,
      sortable: false,
      maxWidth: '100px',
      hide: 'md',
    },

    {
      name: 'Rencana Hasil Kerja',
      selector: (row) => row.performance_element,
      cell: (row) => row.performance_element,
      sortable: false,
    },

    {
      name: 'Indikator Kinerja Individu',
      selector: (row) => row.performance_indicator,
      cell: (row) => row.performance_indicator,
      sortable: false,
      hide: 'md',
    },
    {
      name: 'Satuan',
      selector: (row) => row.performance_unit,
      cell: (row) => row.performance_unit,
      sortable: false,
      maxWidth: '100px',
      hide: 'lg',
    },
    {
      name: 'Target',
      selector: (row) => row.performance_target,
      cell: (row) => row.performance_target,
      sortable: false,
      width: '60px',
      hide: 'lg',
    },
    {
      name: 'Sub Uraian Tugas',
      selector: (row) => row.performance_cascading_value,
      cell: (row) => {
        const cascadingValues =
          row.performance_cascading_value?.split(',') || [];

        const listCascading = cascadingValues.map((cascadingValue, index) => {
          return <li key={index}>{cascadingValue}</li>;
        });
        return cascadingValues.length > 1 ? (
          <ol style={{ marginLeft: '-20px' }}>{listCascading}</ol>
        ) : (
          row.performance_cascading_value
        );
      },
      sortable: false,
      hide: 'lg',
    },
    {
      name: 'Uraian Tugas Terpakai',
      selector: (row) => row.performance_used,
      cell: (row) => {
        return (
          <StatusLabel
            status={row.performance_used}
            customLabel={row.performance_used === 'Y' ? 'Ya' : 'Tidak'}
          />
        );
      },
      sortable: false,
      width: '60px',
    },
  ];

  return (
    <>
      <div className="mb-3 d-sm-flex justify-content-between align-items-center gap-3">
        <div className="d-flex gap-3 mb-3 mb-sm-0">
          <button
            className="btn btn-default "
            onClick={() => setShowFilter(true)}
          >
            <i className="fas fa-filter me-2"></i> Filter
          </button>

          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fas fa-plus me-2"></i> Tambah
          </button>

          <button
            className="btn btn-success"
            onClick={() => setShowImportForm(true)}
          >
            <i className="fas fa-file-import me-2"></i> Impor
          </button>
        </div>
        <div className="text-right">
          <input
            type="text"
            style={{ minWidth: '280px' }}
            className="form-control"
            placeholder="Cari Nama Kewenangan..."
            value={querySearch}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Filter show={showFilter} />
      <Form data={selectedRow} show={showForm} type={formType} />
      <ImportForm data={selectedRow} show={showImportForm} />
      <DeletePerformanceDialog
        data={selectedRow}
        show={showDeleteDialog}
        onClose={() => {
          setShowForm(false);
        }}
      />

      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        paginationServer
        paginationTotalRows={totalItems}
        paginationPerPage={pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onSort={handleSort}
        // paginationComponentOptions={{
        //   noRowsPerPage: true,
        // }}
        progressPending={loading}
        expandableRows={isSmallScreen}
        expandableRowsComponent={(row) => {
          const cascadingValues =
            row.data.performance_cascading_value?.split(',') || [];
          const listCascading = cascadingValues.map((cascadingValue, index) => {
            return <li key={index}>{cascadingValue}</li>;
          });

          return (
            <>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Kategori
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_category}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                Kelompok Jabatan/jabatan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_type}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Rencana Hasil Kerja
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_element}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Indikator kinerja Individu
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_indicator}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Satuan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_unit}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Target
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.performance_target}
                  </p>
                </div>
              </div>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Sub Kewenangan
                </label>
                <div className="col-auto col-md-8">
                  {cascadingValues.length > 1 ? (
                    <ol style={{ marginLeft: '-20px' }}>{listCascading}</ol>
                  ) : (
                    row.data.performance_cascading_value
                  )}
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Kewenangan Terpakai
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    <StatusLabel status={row.data.performance_used} />
                  </p>
                </div>
              </div>

              {/* <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Pembuatan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.created_by || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Dibuat
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatDate(row.data.created_at) || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Perubahan
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.updated_by || '-'}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Diubah
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatDate(row.data.updated_at) || '-'}
                  </p>
                </div>
              </div> */}
            </>
          );
        }}
      />
    </>
  );
}

export default DataList;
