import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext, PeriodContext } from './AssessmentComponentPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { assessmentComponentApi } from '@services/assessmentComponentApi';

function EditForm(props) {
  const value = props.data;
  const { selectedMonthPeriod } = useContext(PeriodContext);
  const { total, setTotal } = useContext(DataContext);

  const [target, setTarget] = useState(value?.target);
  const [quality, setQuality] = useState(value?.quality);
  const [time, setTime] = useState(value?.time);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setTarget(value?.target);
    setQuality(value?.quality);
    setTime(value?.time);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { setList } = useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      target: parseFloat(target),
      quality: parseFloat(quality),
      time: parseFloat(time),
    };

    try {
      await apiRequest(assessmentComponentApi.update(payload, signal));

      setIsLoading(false);

      //update datatable
      setList((prevList) => {
        return prevList.map((prev) => {
          if (prev.id === value.id) {
            setTotal({
              target:
                parseFloat(total.target) -
                parseFloat(prev.target) +
                parseFloat(target),
              quality:
                parseFloat(total.quality) -
                parseFloat(prev.quality) +
                parseFloat(quality),
              time:
                parseFloat(total.time) -
                parseFloat(prev.time) +
                parseFloat(time),
            });

            return { ...prev, target, quality, time };
          }
          return prev;
        });
      });

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>
          Edit Beban Kerja {selectedMonthPeriod?.label}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="editForm" onSubmit={handleSubmit}>
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Indikator</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.indicator}</p>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Satuan</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.unit}</p>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Target</label>
            <div className="col-sm-9">
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Kualitas</label>
            <div className="col-sm-9">
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={quality}
                onChange={(e) => setQuality(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Waktu</label>
            <div className="col-sm-9">
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="editForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          Perbarui
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditForm;
