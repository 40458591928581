import { BASE_API } from './api';

export const employeeOverviewApi = {
  get: (periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-overview-employees/' + periodid,
      signal: signal,
    };
  },
};
