import { useEffect, useState } from 'react';

import Page from '@components/page/page';
import Form from './Form';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Loading from '@components/auth/loading';

const LoginPage = () => {
  const [title] = useState('Login');

  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.loggedInUser) {
      navigate('/home');
    }
  }, [auth?.loggedInUser, navigate]);

  return !auth?.loggedInUser ? (
    <Page title={title} hideTitle>
      <div className="login login-v2 fw-bold">
        <div className="login-cover">
          <div
            className="login-cover-img"
            style={{
              backgroundImage: 'url(/assets/img/login-bg/login-bg-18.jpg)',
            }}
          ></div>
          <div className="login-cover-bg"></div>
        </div>

        <div className="login-container">
          <div className="login-header">
            <img
              className="me-3"
              width="64px"
              src={'/assets/img/logo/logo.png'}
              alt="logo"
            />
            <div className="brand">
              <div className="d-flex align-items-center">{'Siremun'}</div>
              <small>Sistem Informasi Remunerasi</small>
            </div>

            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>
          <div className="login-content">
            <Form />
          </div>
        </div>
      </div>
    </Page>
  ) : (
    <Loading />
  );
};

export default LoginPage;
