import React, { useEffect, useState } from 'react';
import Page from '@components/page/page.jsx';
import { Panel, PanelHeader, PanelBody } from '@components/panel/panel.jsx';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { UserApi } from '@services/userApi';
import { Button } from 'react-bootstrap';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useNavigate } from 'react-router-dom';
import useLogout from '@hooks/useLogout';

function ChangePasswordPage() {
  const [title] = useState('Ganti Password');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Ganti Password' },
  ]);

  const apiRequest = useHttpPrivateRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();

  const logout = useLogout();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const initialValues = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword === '') {
      sendErrorMessage('Password baru tidak boleh kosong!');
      return;
    }
    if (newPassword.length < 8) {
      sendErrorMessage('Password baru minimal 8 karakter!');
      return;
    }
    if (newPassword !== confirmPassword) {
      sendErrorMessage('Password baru dan konfirmasi password tidak sama!');
      setConfirmPassword('');
      return;
    }

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = { oldPassword, newPassword };

    try {
      apiRequest(UserApi.changePassword(payload, signal));
      sendSuccessMessage('Password berhasil diganti');
      setIsLoading(false);
      initialValues();
      await logout();
      navigate('/login');
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
      //initialValues();
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-8 offset-md-2 mt-5 mb-5">
              <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label">
                    Password Lama <sup style={{ color: 'red' }}>*</sup>
                  </label>
                  <div className="col-12 col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Masukkan password lama"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label className="col-sm-4 col-form-label">
                    Password Baru <sup style={{ color: 'red' }}>*</sup>
                  </label>
                  <div className="col-12 col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Masukkan password baru"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label className="col-sm-4 col-form-label">
                    Konfirmasi Password <sup style={{ color: 'red' }}>*</sup>
                  </label>
                  <div className="col-12 col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Masukkan konfirmasi password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <hr />

                <Button
                  className="float-end"
                  form="inputForm"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </Page>
  );
}

export default ChangePasswordPage;
