import { Fragment, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  DataContext,
  PeriodContext,
} from './AchievementAssessmentEvaluationPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import '@assets/override-table.css';
import StatusLabel from '@components/label/StatusLabel';
import CommentLabel from '@components/label/CommentLabel';
import { periodApi } from '@services/periodApi';
import LogbookList from '@pages/JobAssessment/AchievementAssessment/LogBookList';
import InfoLecturerEvaluation from '@pages/JobAssessment/AchievementAssessment/InfoLecturerEvaluation';

function Approval(props) {
  const {
    periodOptions,
    selectedPeriod,
    selectedMonthPeriod,
    monthPeriodOptions,
  } = useContext(PeriodContext);

  const [periodMOptions, setPeriodMOptions] = useState();

  const [abortController, setAbortController] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [showLogBook, setShowLogBook] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  const value = props.data;
  const employeeid = value?.id;

  const [data, setData] = useState();
  const apiRequest = useHttpPrivateRequest();

  const lecturerEvaluation = data?.lecturerEvaluation ?? [];

  const [show, setShow] = useState(false);

  const [selectedY, setSelectedY] = useState(selectedPeriod);
  const [selectedM, setSelectedM] = useState(selectedMonthPeriod);

  const [comment, setComment] = useState(data?.comment);

  const [apraisalResultLeader, setApraisalResultLeader] = useState('');

  const [isApprovalStatus, setIsApprovalStatus] = useState();

  const { setEmployeeData } = useContext(DataContext);

  useEffect(() => {
    showLogBook && setShowLogBook(false);
  }, [showLogBook]);

  const getData = async (employeeid, periodid, signal) => {
    try {
      const request = await apiRequest(
        achievementAssessmentApi.getEmployeeAchievementAssessment(
          employeeid,
          periodid,
          signal
        )
      );
      const response = request?.data;
      setData(response?.data || []);
      setComment(response?.data?.comment);
      setApraisalResultLeader(response?.data?.result_apraisal_leader);
      setIsApprovalStatus(response?.data?.status === 'APPROVAL');
    } catch (error) {
      setData([]);
      setComment('');
      setApraisalResultLeader('');
      setIsApprovalStatus(false);
    }
  };

  const intitialState = (periodid) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    getData(employeeid, periodid, signal);
  };

  const handleClose = () => {
    setSelectedY({});
    setSelectedM({});
    setData([]);
    setComment('');
    setApraisalResultLeader('');
    setShow(false);
  };

  const handleShow = () => {
    setSelectedY(selectedPeriod);
    setSelectedM(selectedMonthPeriod);
    setPeriodMOptions(monthPeriodOptions);
    intitialState(selectedMonthPeriod?.value);
    setShow(true);
  };

  const getMonthPeriod = async (periodYId) => {
    try {
      const controller = new AbortController();

      const request = await apiRequest(
        periodApi.getMonths(periodYId, controller.signal)
      );

      const response = request?.data?.data || [];
      const monthOptions = response.map((month) => {
        return { value: month.id, label: month.period };
      });

      setPeriodMOptions(monthOptions);
      setSelectedM(monthOptions[0]);
      intitialState(monthOptions[0]?.value);
    } catch (error) {}
  };

  const updateStatus = async (status) => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const payload = {
      id: data.id,
      comment,
      apraisalResult: apraisalResultLeader,
      status,
    };

    try {
      await apiRequest(achievementAssessmentApi.updateStatus(payload, signal));

      setData((prevData) => {
        return {
          ...prevData,
          comment,
          result_apraisal_leader: apraisalResultLeader,
          status,
        };
      });

      //update status in datatable
      setEmployeeData((prevData) => {
        return prevData.map((prev) => {
          if (prev.id === value.id) {
            return { ...prev, status };
          }
          return prev;
        });
      });

      setIsApprovalStatus(false);
      sendSuccessMessage('Berhasil update status');

      setIsLoading(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleSelectedY = (period) => {
    setSelectedY(period);
    setSelectedM({});
    setData([]);
    getMonthPeriod(period?.value);
  };

  const handleSelectedM = (period) => {
    setSelectedM(period);
    intitialState(period?.value);
  };

  const handleApprove = () => {
    updateStatus('APPROVED');
  };

  const handleReject = () => {
    updateStatus('REJECTED');
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <Button
          title="Catatan Kerja"
          size="xs"
          variant="default"
          onClick={() => {
            setSelectedRow(row);
            setShowLogBook(true);
          }}
        >
          <i className="fas fa-book"></i>
        </Button>
      ),
    },
    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => <div title={row.indicator}>{row.indicator}</div>,
      sortable: true,
      grow: 2,
    },

    {
      name: 'T. Kuantitas',
      selector: (row) => row.target_value,
      cell: (row) => row.target_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_value,
      cell: (row) => row.achievement_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'T. Kualitas',
      selector: (row) => row.target_quality_value,
      cell: (row) => row.target_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_quality_value,
      cell: (row) => row.achievement_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'T. Waktu',
      selector: (row) => row.target_time_value,
      cell: (row) => row.target_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_time_value,
      cell: (row) => row.achievement_time_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
  ];

  if (data?.showCategory) {
    columns.splice(2, 0, {
      name: 'Kategori',
      selector: (row) => row.category,
      cell: (row) => row.category,
      sortable: true,
      width: 'auto',
      hide: 'sm',
    });
  }

  return (
    <Fragment>
      <Button title="Rincian" variant="default" onClick={handleShow}>
        <i className="fas fa-eye"></i>{' '}
        <span className="d-none d-md-inline-block">Detail</span>
      </Button>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rincian Capaian Kerja {value?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 row">
            <div className="col-4 col-md-2 col-lg-1 fs-5 d-flex align-items-center justify-content-center">
              Periode
            </div>
            {selectedY && (
              <>
                <Select
                  className="col"
                  options={periodOptions}
                  defaultValue={selectedY}
                  onChange={handleSelectedY}
                  isDisabled={isLoading}
                />
                <Select
                  className="col"
                  options={periodMOptions}
                  value={selectedM || ''}
                  onChange={handleSelectedM}
                  isDisabled={isLoading || !selectedM}
                  isClearable
                />
              </>
            )}
          </div>

          {data?.status && (
            <div className="col-12 fs-5 d-flex justify-content-end align-items-center mb-3 gap-3">
              Status <StatusLabel status={data?.status} />
            </div>
          )}

          {data?.comment && (
            <CommentLabel comment={data?.comment} status={data?.status} />
          )}
          <DataTable
            responsive={false}
            columns={columns}
            data={data?.list ?? []}
            expandableRows
            expandableRowsComponent={(row) => {
              return (
                <>
                  <div className="row">
                    <label className="col-sm-3 col-form-label">Indikator</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.indicator}
                      </p>
                    </div>
                  </div>

                  {data?.showCategory && (
                    <div className="row">
                      <label className="col-sm-3 col-form-label">
                        Kategori
                      </label>
                      <div className="col-sm-9">
                        <p className="col-form-label fw-normal">
                          {row?.data?.category}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      Target Kuantitas
                    </label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_value}{' '}
                      </p>
                    </div>
                    <label className="col-sm-3 col-form-label">Realisasi</label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.achievement_value}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      Target Kualitas
                    </label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_quality_value}{' '}
                      </p>
                    </div>
                    <label className="col-sm-3 col-form-label">Realisasi</label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.achievement_quality_value}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      Target Waktu
                    </label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_time_value}{' '}
                      </p>
                    </div>
                    <label className="col-sm-3 col-form-label">Realisasi</label>
                    <div className="col-sm-3">
                      <p className="col-form-label fw-normal">
                        {row?.data?.achievement_time_value}{' '}
                      </p>
                    </div>
                  </div>
                </>
              );
            }}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
          />

          <hr className="m-0" />
          <div className="row">
            {data?.list?.length > 0 && (
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md">
                    <div className="row ">
                      <div className="fw-bold pt-3 pb-1 col-md-10">Jumlah</div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Kuantitas:</div>
                              <div className="col-auto fw-bold">
                                {data.target_achievement}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_achievement}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Kualitas:</div>
                              <div className="col-auto fw-bold">
                                {data.target_quality}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_quality}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Waktu:</div>
                              <div className="col-auto fw-bold">
                                {data.target_time}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_time}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {lecturerEvaluation && (
                    <div className="col-md-5 mt-0">
                      <div className="d-flex justify-content-between align-items-center p-2 pb-0 border">
                        <InfoLecturerEvaluation data={lecturerEvaluation} />
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
                      <div className="fw-bold">Hasil {data.formula}</div>
                      <div className="text-end fw-bold fs-4">
                        {data.result_apraisal}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between align-items-center pt-3">
                      <div className="fw-bold">Hasil Nilai Atasan Langsung:</div>
                      <div className="text-end fw-bold fs-4">
                        {data.result_apraisal_leader}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isApprovalStatus && (
              <div className="col-md-12">
                <hr className="m-0 mt-3" />
                <div className="row mt-5">
                  <div className="col-md-10 offset-md-1 text-center mt-4 mb-4">
                    <div>
                      <h5>
                        Nilai Atasan Langsung <sup className="text-danger">*</sup>
                      </h5>
                      <p className="col-form-label fw-normal">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          placeholder="Nilai atasan langsung wajib diisi"
                          onChange={(e) => {
                            return setApraisalResultLeader(e.target.value);
                          }}
                          value={apraisalResultLeader}
                          required
                        />
                      </p>

                      <h5>
                        Komentar <sup className="text-danger">*</sup>
                      </h5>
                      <p className="col-form-label fw-normal">
                        <textarea
                          className="form-control"
                          placeholder="Komentar wajib diisi"
                          rows="3"
                          onChange={(e) => {
                            return setComment(e.target.value);
                          }}
                          value={comment}
                          required
                        />
                      </p>

                      <div className="d-flex mt-2 justify-content-center gap-3">
                        <Button
                          variant="danger"
                          size="lg"
                          onClick={handleReject}
                        >
                          <i className="fas fa-close"></i> Tolak
                        </Button>

                        <Button
                          variant="success"
                          size="lg"
                          onClick={handleApprove}
                        >
                          <i className="fas fa-check"></i> Terima
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      <LogbookList data={selectedRow} show={showLogBook} />
    </Fragment>
  );
}

export default Approval;
