import roles from './app-roles';

const Menu = [
  // {
  //   path: '/home',
  //   icon: 'fa fa-gauge',
  //   title: 'Beranda',
  // },

  { path: '/profile', icon: 'fa fa-user', title: 'Informasi Pribadi' },

  {
    path: '/master-data',
    icon: 'fa fa-table',
    title: 'Data Master',
    roles: [roles.ADMIN, roles.SUPERADMIN],
    children: [
      { path: '/master-data/period', title: 'Periode' },
      { path: '/master-data/employee', title: 'Pegawai' },
      { path: '/master-data/user-role', title: 'Peran Pengguna' },
      { path: '/master-data/department', title: 'Unit Organisasi' },
      { path: '/master-data/division', title: 'Satuan Organisasi' },
      { path: '/master-data/section', title: 'Jabatan' },
      { path: '/master-data/job', title: 'Golongan' },
      { path: '/master-data/performance', title: 'Kewenangan' },
      { path: '/master-data/attendance', title: 'Presensi' },
    ],
  },

  {
    path: '/job-assessment',
    icon: 'fa fa-laptop-file',
    title: 'Penilaian Kerja',
    roles: [roles.EMPLOYEE],
    children: [
      { path: '/job-assessment/contract-assessment', title: 'Kontrak Kerja' },
      { path: '/job-assessment/assessment-component', title: 'Beban Kerja' },
      {
        path: '/job-assessment/achievement-archievement',
        title: 'Capaian Kerja',
      },
      { path: '/job-assessment/result-achievement', title: 'Hasil Penilaian' },
      //{ path: '/job-assessment/salary', title: 'Daftar Gaji' },
    ],
  },
  {
    path: '/job-evaluation',
    icon: 'fa fa-chalkboard-user',
    title: 'Evaluasi Kinerja',
    roles: [roles.LEADER],
    children: [
      { path: '/job-evaluation/contract-assessment', title: 'Kontrak Kerja' },
      { path: '/job-evaluation/assessment-component', title: 'Beban Kerja' },
      {
        path: '/job-evaluation/achievement-assessment',
        title: 'Capaian Kerja',
      },
      // { path: '/job-evaluation/result-achievement', title: 'Hasil Penilaian' },
    ],
  },
  { path: '/log-book', icon: 'fa fa-calendar', title: 'Catatan Kerja' },
  // { path: '/auth/register', icon: 'fa fa-user', title: 'Status Pegawai' }, //test
  {
    path: '/verification',
    icon: 'fa fa-list-check',
    title: 'Verifikasi',
    roles: [roles.VERIFICATOR],
    children: [
      { path: '/verification/employee-overview', title: 'Status Pegawai' },
      {
        path: '/verification/achievement-assessment',
        title: 'Verifikasi Capaian Kinerja',
      },
      // { path: '/verification/menu-1-3', title: 'Daftar Hasil Penilaian' },
    ],
  },

  {
    path: '/report',
    icon: 'fa fa-newspaper',
    title: 'Laporan',
    roles: [roles.ADMIN, roles.SUPERADMIN],
    children: [
      {
        path: '/report/salary-report',
        title: 'Laporan Gaji',
      },
    ],
  },
];
export default Menu;
