import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { employeeApi } from '@services/employeeApi';

function ResetPasswordDialog(props) {
  const value = props.data;

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      await apiRequest(employeeApi.resetPasswordByAdmin(value?.id, signal));
      //const response = request.data.data;
      setIsLoading(false);

      sendSuccessMessage(
        `Berhasil mereset password pegawai atas nama ${value?.fullname}`
      );

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Reset Password Pegawai</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Apakah anda yakin akan mereset password pegawai atas nama{' '}
        {value?.fullname} ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button onClick={handleDelete} variant="primary" disabled={isLoading}>
          {isLoading ? 'Mereset...' : 'Reset'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResetPasswordDialog;
