import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import PeriodOptions from './PeriodOptions';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DataList from './DataList';
import { resultAchievementApi } from '@services/resultAchievementApi';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function ResultAchievementPage(props) {
  const [title] = useState('Hasil Penilaian');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Penilaian Kinerja' },
    { title },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const periodid = selectedPeriod?.value;

  const [data, setData] = useState([]);
  const apiRequest = useHttpPrivateRequest();

  const reloadData = async (periodid, signal) => {
    try {
      const request = await apiRequest(
        resultAchievementApi.get(periodid, signal)
      );
      const response = request?.data;
      setData(response?.data);
    } catch (error) {
      setData([]);
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          periodid,
        }}
      >
        <Panel>
          <PanelHeader>Filter Periode</PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            data,
            setData,
            reloadData,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <DataList />
            </PanelBody>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default ResultAchievementPage;
