import { Store } from 'react-notifications-component';

function addNotification(
  notificationType,
  notificationTitle,
  notificationMessage
) {
  Store.addNotification({
    title: notificationTitle,
    message: notificationMessage,
    type: notificationType,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { duration: 2000 },
    dismissable: { click: true },
  });
}

export function sendSuccessMessage(notificationMessage) {
  addNotification('success', 'Sukses', notificationMessage);
}

export function sendErrorMessage(notificationMessage) {
  addNotification('danger', 'Kesalahan', notificationMessage);
}

export function sendInfoMessage(notificationMessage) {
  addNotification('info', 'Pemberitahuan', notificationMessage);
}

export function sendWarningMessage(notificationMessage) {
  addNotification('warning', 'Peringatan', notificationMessage);
}

export default addNotification;
