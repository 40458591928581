import EditForm from './EditForm';
import { useContext, useEffect, useState } from 'react';
import { DataContext, PeriodContext } from './AchievementAssessmentPage';
import DataTable from 'react-data-table-component';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ViewDetail from './ViewDetail';
import LogbookList from './LogBookList';
import InfoTotal from './InfoTotal';
import InfoLecturerEvaluation from './InfoLecturerEvaluation';

function DataList(props) {
  const { parentid, periodid } = useContext(PeriodContext);
  const { data, list, reloadData } = useContext(DataContext);

  const lecturerEvaluation = data?.lecturerEvaluation ?? [];

  const [selectedRow, setSelectedRow] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [showLogBook, setShowLogBook] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const status = data?.status;

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    showLogBook && setShowLogBook(false);
  }, [showLogBook]);

  useEffect(() => {
    showEdit && setShowEdit(false);
  }, [showEdit]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (parentid || periodid) && reloadData(periodid, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <DropdownButton
          title={<i className="fas fa-ellipsis-v"></i>}
          variant="default"
        >
          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowLogBook(true);
            }}
          >
            <i className="fas fa-book"></i> Catatan Kerja
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={() => {
              setSelectedRow(row);
              setShowDetail(true);
            }}
          >
            <i className="fas fa-eye"></i> Lihat Rincian
          </Dropdown.Item>

          {status !== 'APPROVED' && status !== 'APPROVAL' && (
            <Dropdown.Item
              onClick={() => {
                setSelectedRow(row);
                setShowEdit(true);
              }}
            >
              <i className="fas fa-edit"></i> Edit Rincian
            </Dropdown.Item>
          )}
        </DropdownButton>
      ),
    },

    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => (
        <p
          className="cursor-pointer btn-link text-decoration-none m-0 p-0"
          onClick={() => {
            setSelectedRow(row);
            setShowDetail(true);
          }}
        >
          {row.indicator}
        </p>
      ),
      sortable: true,
    },

    {
      name: 'T. Kuantitas',
      selector: (row) => row.target_value,
      cell: (row) => row.target_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_value,
      cell: (row) => row.achievement_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
    {
      name: 'T. Kualitas',
      selector: (row) => row.target_quality_value,
      cell: (row) => row.target_quality_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_quality_value,
      cell: (row) => row.achievement_quality_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
    {
      name: 'T. Waktu',
      selector: (row) => row.target_time_value,
      cell: (row) => row.target_time_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_time_value,
      cell: (row) => row.achievement_time_value,
      sortable: true,
      width: '100px',
      hide: 'md',
    },
  ];

  if (data?.showCategory) {
    columns.splice(2, 0, {
      name: 'Kategori',
      selector: (row) => row.category,
      cell: (row) => row.category,
      sortable: true,
      width: 'auto',
      hide: 'md',
    });
  }

  return (
    <>
      <ViewDetail
        data={selectedRow}
        show={showDetail}
        showCategory={data?.showCategory}
      />
      <LogbookList data={selectedRow} show={showLogBook} />
      <EditForm
        data={selectedRow}
        show={showEdit}
        showCategory={data?.showCategory}
      />

      <DataTable
        responsive={false}
        columns={columns}
        data={list}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
      />

      {list && list.length > 0 && (
        <div>
          <div className="row">
            <div className="col-md">
              <div className="row">
                <div className="col-md-12">
                  <hr className="m-0" />
                  <InfoTotal />
                </div>
              </div>
            </div>

            {lecturerEvaluation && (
              <div className="col-md-5">
                <div className="d-flex justify-content-between align-items-center p-3 border">
                  <InfoLecturerEvaluation data={lecturerEvaluation} />
                </div>
              </div>
            )}

            <div className="col-md-12">
              <hr className="m-0" />
              <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
                <div className="fw-bold">Hasil {data.formula}</div>
                <div className="text-end fw-bold fs-4">
                  {data.result_apraisal}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <hr className="m-0" />
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="fw-bold">Hasil Nilai Atasan Langsung:</div>
                <div className="text-end fw-bold fs-4">
                  {data.result_apraisal_leader}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DataList;
