import { Fragment, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DataContext, PeriodContext } from './ResultAchievementEvaluationPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { contractAssessmentApi } from '@services/contractAssessmentApi';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import StatusLabel from '@components/label/StatusLabel';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import CommentLabel from '@components/label/CommentLabel';
import '@assets/override-table.css';

function Approval(props) {
  const { periodOptions, selectedPeriod } = useContext(PeriodContext);
  const [abortController, setAbortController] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const value = props.data;
  const employeeid = value?.id;

  const [data, setData] = useState();
  const apiRequest = useHttpPrivateRequest();

  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState(selectedPeriod);

  const [comment, setComment] = useState(data?.comment);
  const [isApprovalStatus, setIsApprovalStatus] = useState();

  const { setEmployeeData } = useContext(DataContext);

  const getData = async (employeeid, periodid, signal) => {
    try {
      const request = await apiRequest(
        contractAssessmentApi.getEmployeeContractAssessments(
          employeeid,
          periodid,
          signal
        )
      );
      const response = request?.data;
      setData(response?.data || []);
      setComment(response?.data?.comment);
      setIsApprovalStatus(response?.data?.status === 'APPROVAL');
    } catch (error) {
      setData([]);
      setComment('');
      setIsApprovalStatus(false);
    }
  };

  const intitialState = (periodid) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    getData(employeeid, periodid, signal);
  };

  const handleClose = () => {
    setSelected();
    setShow(false);
  };

  const handleShow = () => {
    setSelected(selectedPeriod);
    intitialState(selectedPeriod?.value);
    setShow(true);
  };

  const updateStatus = async (status) => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const payload = {
      employeeId: employeeid,
      periodId: selected?.value,
      comment,
      status,
    };

    try {
      await apiRequest(contractAssessmentApi.updateStatus(payload, signal));

      setData((prevData) => {
        return { ...prevData, status, comment };
      });

      //update status in datatable
      setEmployeeData((prevData) => {
        return prevData.map((prev) => {
          if (prev.id === value.id) {
            return { ...prev, status };
          }
          return prev;
        });
      });

      setIsApprovalStatus(false);
      sendSuccessMessage('Berhasil update status');
      setIsLoading(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleSelected = (period) => {
    setSelected(period);
    intitialState(period?.value);
  };

  const handleApprove = () => {
    updateStatus('APPROVED');
  };

  const handleReject = () => {
    updateStatus('REJECTED');
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const requireCasecading = data?.list?.some((item) => item.cascading !== '');

  const columns = [
    {
      name: 'Program',
      selector: (row) => row.program,
      cell: (row) => <div title={row.program}>{row.program}</div>,
      sortable: true,
      grow: 2,
    },

    {
      name: 'T.T',
      selector: (row) => row.target_year,
      cell: (row) => <div>{row.target_year}</div>,
      sortable: true,
      hide: 'sm',
      width: '60px',
    },
    {
      name: 'T.S',
      selector: (row) => row.target_semester,
      cell: (row) => <div>{row.target_semester}</div>,
      hide: 'sm',
      sortable: true,
      width: '60px',
    },
    {
      name: 'T.B',
      selector: (row) => row.target_month,
      cell: (row) => <div>{row.target_month}</div>,
      hide: 'sm',
      sortable: true,
      width: '60px',
    },
  ];

  if (requireCasecading) {
    columns.splice(0, 0, {
      name: 'Cascading',
      selector: (row) => row.cascading,
      cell: (row) => <div title={row.cascading}>{row.cascading}</div>,
      sortable: true,
      width: 'auto',
      hide: 'sm',
      right: true,
    });
  }
  return (
    <Fragment>
      <Button title="Rincian" variant="default" onClick={handleShow}>
        <i className="fas fa-eye"></i>{' '}
        <span className="d-none d-md-inline-block">Detail</span>
      </Button>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rincian Kontrak Kerja {value?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 row">
            <div className="col-4 col-md-2 col-lg-1 fs-5 d-flex align-items-center justify-content-center">
              Periode
            </div>
            <Select
              className="col"
              options={periodOptions}
              defaultValue={selected}
              onChange={handleSelected}
              isDisabled={isLoading}
            />
          </div>

          {data?.status && (
            <div className="col-12 fs-5 d-flex justify-content-end align-items-center mb-3 gap-3">
              Status <StatusLabel status={data?.status} />
            </div>
          )}

          {data?.comment && (
            <CommentLabel comment={data?.comment} status={data?.status} />
          )}
          <DataTable
            responsive={false}
            columns={columns}
            data={data?.list ?? []}
            expandableRows
            expandableRowsComponent={(row) => {
              return (
                <>
                  {row?.data?.cascading && (
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Cascading
                      </label>
                      <div className="col-sm-9">
                        <p className="col-form-label fw-normal">
                          {row?.data?.cascading}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Program</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.program}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Indikator</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.indicator}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Satuan</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.unit}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Tahun (T.T)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_year}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Semester (T.S)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_semester}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Bulan (T.B)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target_month}{' '}
                      </p>
                    </div>
                  </div>
                </>
              );
            }}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
          />
          {isApprovalStatus && (
            <div className="row mt-3">
              <div className="col-md-8 offset-md-2 text-center mt-4 mb-4">
                <div>
                  <h5>Komentar (*)</h5>
                  <p className="col-form-label fw-normal">
                    <textarea
                      className="form-control"
                      placeholder="Komentar wajib diisi"
                      rows="3"
                      onChange={(e) => {
                        return setComment(e.target.value);
                      }}
                      value={comment}
                    />
                  </p>

                  <div className="d-flex justify-content-center gap-3">
                    <Button variant="danger" size="lg" onClick={handleReject}>
                      <i className="fas fa-close"></i> Tolak
                    </Button>

                    <Button variant="success" size="lg" onClick={handleApprove}>
                      <i className="fas fa-check"></i> Terima
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default Approval;
