import { ADMIN_API } from './api';

export const departmentApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-departments',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'department_name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-department',
      data: {
        departmentName: payload.department_name,
        divisionId: payload.division_id,
        managerId: payload.manager_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-department',
      data: {
        id: payload.id,
        departmentName: payload.department_name,
        divisionId: payload.division_id,
        managerId: payload.manager_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateStatusByAdmin: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-department/' + periodid,
      signal,
    };
  },
};
