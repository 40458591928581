import { Fragment, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { PeriodContext } from './AchievementAssessmentVerificationPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';
import { Button, Modal } from 'react-bootstrap';
import '@assets/override-table.css';
import StatusLabel from '@components/label/StatusLabel';
import CommentLabel from '@components/label/CommentLabel';
import LogbookList from '@pages/JobAssessment/AchievementAssessment/LogBookList';
import UpdateApraisalResult from './UpdateApraisalResult';
import CancelApproval from './CancelApproval';
import InfoLecturerEvaluation from '@pages/JobAssessment/AchievementAssessment/InfoLecturerEvaluation';

function Verifiy(props) {
  const { selectedMonthPeriod } = useContext(PeriodContext);

  const [abortController, setAbortController] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [showLogBook, setShowLogBook] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  const value = props.data;
  const employeeid = value?.id;

  const [data, setData] = useState();
  const apiRequest = useHttpPrivateRequest();

  const lecturerEvaluation = data?.lecturerEvaluation ?? [];

  const [show, setShow] = useState(false);

  const [isApprovedStatus, setIsApprovedStatus] = useState();

  useEffect(() => {
    showLogBook && setShowLogBook(false);
  }, [showLogBook]);

  useEffect(() => {
    showUpdate && setShowUpdate(false);
  }, [showUpdate]);

  useEffect(() => {
    showCancel && setShowCancel(false);
  }, [showCancel]);

  const getData = async (employeeid, periodid, signal) => {
    setIsLoading(true);
    try {
      const request = await apiRequest(
        achievementAssessmentApi.getEmployeeAchievementAssessmentForVerify(
          employeeid,
          periodid,
          signal
        )
      );
      const response = request?.data;
      setData(response?.data || []);
      setIsApprovedStatus(response?.data?.status === 'APPROVED');
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsApprovedStatus(false);
      setIsLoading(false);
    }
  };

  const intitialState = (periodid) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    getData(employeeid, periodid, signal);
  };

  const handleClose = () => {
    setData([]);
    setShow(false);
  };

  const handleShow = () => {
    intitialState(selectedMonthPeriod?.value);
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <Button
          title="Catatan Kerja"
          size="xs"
          variant="default"
          onClick={() => {
            setSelectedRow(row);
            setShowLogBook(true);
          }}
        >
          <i className="fas fa-book"></i>
        </Button>
      ),
    },
    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => <div title={row.indicator}>{row.indicator}</div>,
      sortable: true,
      grow: 2,
    },

    {
      name: 'T. Kuantitas',
      selector: (row) => row.target_value,
      cell: (row) => row.target_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_value,
      cell: (row) => row.achievement_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'T. Kualitas',
      selector: (row) => row.target_quality_value,
      cell: (row) => row.target_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_quality_value,
      cell: (row) => row.achievement_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'T. Waktu',
      selector: (row) => row.target_time_value,
      cell: (row) => row.target_quality_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
    {
      name: 'Realisasi',
      selector: (row) => row.achievement_time_value,
      cell: (row) => row.achievement_time_value,
      sortable: true,
      width: '100px',
      hide: 'lg',
    },
  ];

  if (data?.showCategory) {
    columns.splice(2, 0, {
      name: 'Kategori',
      selector: (row) => row.category,
      cell: (row) => row.category,
      sortable: true,
      width: 'auto',
      hide: 'sm',
    });
  }

  return (
    <Fragment>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verifikasi Capaian Kerja {value?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {data?.status && (
                <div className="col-12 fs-5 d-flex justify-content-end align-items-center mb-3 gap-3">
                  Status <StatusLabel status={data?.status} />
                </div>
              )}

              {data?.comment && (
                <CommentLabel comment={data?.comment} status={data?.status} />
              )}
              <DataTable
                progressPending={isLoading}
                responsive={false}
                columns={columns}
                data={data?.list ?? []}
                expandableRows
                expandableRowsComponent={(row) => {
                  return (
                    <>
                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          Indikator
                        </label>
                        <div className="col-sm-9">
                          <p className="col-form-label fw-normal">
                            {row?.data?.indicator}
                          </p>
                        </div>
                      </div>

                      {data?.showCategory && (
                        <div className="row">
                          <label className="col-sm-3 col-form-label">
                            Kategori
                          </label>
                          <div className="col-sm-9">
                            <p className="col-form-label fw-normal">
                              {row?.data?.category}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          Target Kuantitas
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.target_value}{' '}
                          </p>
                        </div>
                        <label className="col-sm-3 col-form-label">
                          Realisasi
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.achievement_value}{' '}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          Target Kualitas
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.target_quality_value}{' '}
                          </p>
                        </div>
                        <label className="col-sm-3 col-form-label">
                          Realisasi
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.achievement_quality_value}{' '}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-3 col-form-label">
                          Target Waktu
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.target_time_value}{' '}
                          </p>
                        </div>
                        <label className="col-sm-3 col-form-label">
                          Realisasi
                        </label>
                        <div className="col-sm-3">
                          <p className="col-form-label fw-normal">
                            {row?.data?.achievement_time_value}{' '}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                }}
                pagination
                paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
              />

              {!isLoading && data?.list?.length > 0 && (
                <div className="row">
                  <div className="col-md-12">
                    <hr className="m-0" />
                    <div className="row ">
                      <div className="col-md ">
                        <div className="row">
                          <div className="fw-bold pt-4 pb-1 col-md-12">
                            Jumlah
                          </div>
                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Kuantitas:</div>
                              <div className="col-auto fw-bold">
                                {data.target_achievement}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_achievement}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Kualitas:</div>
                              <div className="col-auto fw-bold">
                                {data.target_quality}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_quality}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4 mb-3">
                            <div className="row">
                              <div className="col">Target Waktu:</div>
                              <div className="col-auto fw-bold">
                                {data.target_time}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">Realisasi:</div>
                              <div className="col-auto fw-bold">
                                {data.result_time}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {lecturerEvaluation && (
                        <div className="col-md-5 mt-0">
                          <div className="d-flex justify-content-between align-items-center p-2 pb-0 border">
                            <InfoLecturerEvaluation data={lecturerEvaluation} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
                      <div className="fw-bold">Hasil {data.formula}</div>
                      <div className="text-end fw-bold fs-4">
                        {data.result_apraisal}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between align-items-center pt-3">
                      <div className="fw-bold">Hasil Nilai Atasan Langsung:</div>
                      <div className="text-end fw-bold fs-4">
                        {data.result_apraisal_leader} %
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3 align-items-center justify-content-between w-100">
            <div className="d-flex gap-3 align-items-center justify-content-center w-100">
              {!isLoading && isApprovedStatus && (
                <>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setShow(false);
                      setShowCancel(true);
                    }}
                  >
                    <i className="fas fa-close me-2"></i> Pembatalan Persetujuan
                  </Button>

                  <Button
                    variant="success"
                    onClick={() => {
                      setShow(false);
                      setShowUpdate(true);
                    }}
                  >
                    <i className="fas fa-check me-2"></i> Verifikasi Penilaian
                  </Button>
                </>
              )}
            </div>

            <Button variant="default" onClick={handleClose}>
              Tutup
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <LogbookList data={selectedRow} show={showLogBook} />

      <CancelApproval
        data={value}
        show={showCancel}
        onClose={(showParent) => {
          intitialState(selectedMonthPeriod?.value);
          setShow(false);
        }}
      />

      <UpdateApraisalResult
        data={value}
        show={showUpdate}
        onClose={(showParent) => {
          intitialState(selectedMonthPeriod?.value);
          setShow(showParent);
        }}
      />
    </Fragment>
  );
}

export default Verifiy;
