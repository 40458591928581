import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PeriodContext } from './AssessmentComponentPage';

function ViewDetail(props) {
  const value = props.data;
  const { selectedMonthPeriod } = useContext(PeriodContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Rincian Beban Kerja {selectedMonthPeriod?.label}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label className="col-sm-3 col-form-label">Indikator</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.indicator}</p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Satuan</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.unit} </p>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Target</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.target} </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Kualitas</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.quality} </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Waktu</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.time} </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewDetail;
