import {
  sendErrorMessage,
  sendInfoMessage,
  sendWarningMessage,
} from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './EmployeePage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import 'react-datepicker/dist/react-datepicker.css';
import { employeeApi } from '@services/employeeApi';
import CustomProgressBar from './CustomProgressBar';

function ImportForm(props) {
  const { reloadData, filterParams } = useContext(DataContext);

  const [selectedFile, setSelectedFile] = useState('');

  const [result, setResult] = useState('');
  const [successPercentage, setSuccessPercentage] = useState(0);
  const [failedPercentage, setFailedPercentage] = useState(0);

  const initialState = () => {
    setResult('');
    setSuccessPercentage(0);
    setFailedPercentage(0);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    initialState();
    event.target.value = '';
  };

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    initialState();
    setShow(false);
  };

  const handleShow = () => {
    setIsLoading(false);
    setSelectedFile('');
    initialState();
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const file = selectedFile;
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 10485760; // 10MB (2 * 1024 * 1024)
    const allowedMimeTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (fileSizeInBytes > maxSizeInBytes) {
      sendWarningMessage(
        'Ukuran file melebihi batas maksimum yang diizinkan (10MB).'
      );
      setSelectedFile('');
    } else if (!allowedMimeTypes.includes(file.type)) {
      sendWarningMessage('Tipe file yang diunggah tidak diperbolehkan.');
      setSelectedFile('');
    } else {
      setIsLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      initialState();

      try {
        const request = await apiRequest(
          employeeApi.importByAdmin(file, signal)
        );

        const { success, failed } = request.data.data;

        const totalSuccess = (success / (success + failed)) * 100;

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        for (let i = 1; i <= 100; i++) {
          if (i <= totalSuccess) {
            setSuccessPercentage(
              (prevSuccessPercentage) => prevSuccessPercentage + 1
            );
          }

          if (i > totalSuccess) {
            setFailedPercentage(
              (prevFailedPercentage) => prevFailedPercentage + 1
            );
          }
          if (i === 100) {
            setResult({ success, failed });
            sendInfoMessage(
              `Data diimpor: ${success} berhasil , ${failed} gagal.`
            );
            setIsLoading(false);
            setSelectedFile('');
            if (success > 0) reloadData(filterParams, signal);
          }
          await delay(3);
        }
      } catch (error) {
        setSelectedFile('');
        initialState();
        sendErrorMessage(error?.response?.data?.message || error.message);
        setIsLoading(false);
      }
    }
  };

  const handleDownload = () => {
    const fileName = 'sample-employees.xlsx';

    const filePath = `/${fileName}`; // Ganti dengan path yang benar ke file Anda

    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        // Hapus elemen anchor setelah selesai
        link.parentNode.removeChild(link);
      })
      .catch((error) =>
        console.error('Terjadi kesalahan saat mengunduh file:', error)
      );
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Impor Data Pegawai</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ms-2 me-2">
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          {selectedFile ? (
            <div className="d-flex gap-2 align-items-center justify-content-between mb-3">
              <button
                className="btn btn-success text-truncate"
                disabled={isLoading}
              >
                <i className="fas fa-file-excel me-3"></i>
                {selectedFile?.name}
              </button>

              <button
                className="btn btn-danger"
                disabled={isLoading}
                onClick={() => {
                  setSelectedFile('');
                  initialState();
                }}
              >
                <i className="fas fa-times"></i> Batal
              </button>
            </div>
          ) : (
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                Upload File Excel <sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-12 col-sm-8">
                <input
                  disabled={isLoading}
                  placeholder="Upload file format excel"
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div>
            {result && (
              <p className="text-end m-0 mb-1">
                {result?.success || 0} sukses / {result?.failed || 0} gagal
              </p>
            )}
            <CustomProgressBar
              greenPercentage={successPercentage}
              redPercentage={failedPercentage}
            />
          </div>

          {/* <ProgressBar now={progress} striped animated /> */}
        </form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center gap-3">
        <Button
          variant="link float-left"
          onClick={handleDownload}
          disabled={isLoading}
        >
          <i className="fas fa-download me-3"> </i>Download Format
        </Button>

        <div className="d-flex justify-content-between align-items-center gap-3">
          <Button
            form="inputForm"
            type="submit"
            variant="success"
            disabled={isLoading || result || !selectedFile}
          >
            {isLoading ? 'Import Data...' : 'Impor Sekarang'}
          </Button>

          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Tutup
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportForm;
