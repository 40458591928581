import '@assets/bordered-table.css';

function DocumentContent() {
  return (
    <div style={{ fontFamily: 'Time New Roman' }}>
      <h4 className="text-center">
        PERNYATAAN PENETAPAN KINERJA
        <br />
        POLITEKNIK KESEHATAN TANJUNGKARANG KEMENKES RI
      </h4>

      <div style={{ textAlign: 'center', margin: '10px' }}>
        <img
          style={{ width: '100px' }}
          src={'/assets/img/logo/logo.png'}
          alt="logo"
        />
      </div>

      <h4 className="text-center">PENETAPAN KINERJA TAHUN 2023</h4>
      <p style={{ textAlign: 'justify' }}>
        Dalam rangka mewujudkan manajemen pemerintahan yang efektif, transparan,
        dan akuntabel serta berorientasi pada hasil, kami yang bertanda tangan
        di bawah ini :
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '30%' }}>Nama</td>
            <td>:</td>
            <td>Faid Mislia Zudesia, SKM.M.Kes</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Golongan</td>
            <td>:</td>
            <td>Pengelola Data </td>
          </tr>
        </tbody>
      </table>
      <p className="tex-justify">
        Selanjutnya disebut pihak <b>pertama</b>
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '30%' }}>Nama</td>
            <td>:</td>
            <td>El Rahmayati, S. Kp., M. Kes</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Golongan</td>
            <td>:</td>
            <td>Kepala Pusat Penjaminan Mutu</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: 'justify' }}>
        Selaku atasan langsung pihak pertama, selanjutnya disebut pihak{' '}
        <b>kedua</b>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Pihak pertama berjanji akan mewujudkan target kinerja yang seharusnya
        sesuai lampiran perjanjian ini, dalam rangka mencapai target kinerja
        jangka menengah seperti yang telah ditetapkan dalam dokumen perencanaan.
        Keberhasilan dan kegagalan pencapaian target kinerja tersebut menjadi
        tanggung jawab kami.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Pihak kedua akan memberikan supervisi yang diperlukan serta akan
        melakukan evaluasi terhadap capaian kinerja dari perjanjian ini dan
        mengambil tindakan yang diperlukan dalam rangka pemberian penghargaan
        dan sanksi.
      </p>

      <table>
        <thead>
          <tr>
            <td style={{ width: '35%' }}></td>
            <td style={{ width: '30%' }}></td>
            <td style={{ width: '35%' }}>Bandar Lampung, 14 Januari 2023</td>
          </tr>
          <tr style={{ height: '100px', verticalAlign: 'top' }}>
            <td>
              <b>Pihak kedua,</b>
            </td>
            <td></td>
            <td>
              <b>Pihak Pertama,</b>
            </td>
          </tr>

          <tr>
            <td>El Rahmayati, S. Kp., M. Kes </td>
            <td></td>
            <td>Faid Mislia Zudesia SKM.M.Kes </td>
          </tr>
          <tr>
            <td>NIP. 197003042002122002</td>
            <td></td>
            <td>NIP. 197312061999032002</td>
          </tr>
        </thead>
      </table>

      <div className="page-break" style={{ marginTop: '30mm' }}></div>

      <h4 style={{ textAlign: 'center' }}>
        PERJANJIAN KINERJA TAHUN 2023 <br />
        POLITEKNIK KESEHATAN TANJUNGKARANG KEMENKES RI
      </h4>

      <table className="bordered-table">
        <thead>
          <tr>
            <th rowSpan="2">No </th>
            <th rowSpan="2">Kinerja Atasan Langsung</th>
            <th rowSpan="2">Program/Kegiatan</th>
            <th rowSpan="2">Indikator Kinerja Program/ Kegiatan (IKP/IKK)</th>
            <th rowSpan="2"> ""</th>
            <th colSpan="2">Target</th>
          </tr>
          <tr>
            <th>Semester</th>
            <th>Tahunan</th>
          </tr>
          <tr>
            <th>(1)</th>
            <th>(2)</th>
            <th>(3)</th>
            <th>(4)</th>
            <th>(5)</th>
            <th>(6)</th>
            <th>(7)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I.</td>
            <td>Peningkatan Daya Saing Institusi</td>
            <td>1. Peningkatan Perencanaan Insitusi</td>
            <td>1. Jumlah Dokumen Perencanaan Institusi</td>
            <td>Dokumen</td>
            <td style={{ textAlign: 'center' }}>-</td>
            <td style={{ textAlign: 'center' }}>1</td>
          </tr>
          <tr>
            <td>II.</td>
            <td>Peningkatan Daya Saing Institusi</td>
            <td>1. Peningkatan Perencanaan Insitusi</td>
            <td>1. Jumlah Dokumen Perencanaan Institusi</td>
            <td>Dokumen</td>
            <td style={{ textAlign: 'center' }}>-</td>
            <td style={{ textAlign: 'center' }}>1</td>
          </tr>
          <tr>
            <td>III.</td>
            <td>Peningkatan Daya Saing Institusi</td>
            <td>1. Peningkatan Perencanaan Insitusi</td>
            <td>1. Jumlah Dokumen Perencanaan Institusi</td>
            <td>Dokumen</td>
            <td style={{ textAlign: 'center' }}>-</td>
            <td style={{ textAlign: 'center' }}>1</td>
          </tr>
        </tbody>
      </table>

      <br />
      <table>
        <thead>
          <tr>
            <td style={{ width: '35%' }}></td>
            <td style={{ width: '30%' }}></td>
            <td style={{ width: '35%' }}>Bandar Lampung, 14 Januari 2023</td>
          </tr>
          <tr style={{ height: '100px', verticalAlign: 'top' }}>
            <td>
              <b>Pihak kedua,</b>
            </td>
            <td></td>
            <td>
              <b>Pihak Pertama,</b>
            </td>
          </tr>

          <tr>
            <td>El Rahmayati, S. Kp., M. Kes </td>
            <td></td>
            <td>Faid Mislia Zudesia SKM.M.Kes </td>
          </tr>
          <tr>
            <td>NIP. 197003042002122002</td>
            <td></td>
            <td>NIP. 197312061999032002</td>
          </tr>
        </thead>
      </table>
    </div>
  );
}

export default DocumentContent;
