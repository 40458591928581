import { ADMIN_API } from './api';

export const sectionApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-sections',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'section_name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-section',
      data: {
        section_name: payload.section_name,
        department_id: payload.department_id,
        manager_id: payload.manager_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-section',
      data: {
        id: payload.id,
        section_name: payload.section_name,
        department_id: payload.department_id,
        manager_id: payload.manager_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateStatusByAdmin: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-section/' + periodid,
      signal,
    };
  },
};
