import { Fragment, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  DataContext,
  PeriodContext,
} from './AssessmentComponentEvaluationPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { assessmentComponentApi } from '@services/assessmentComponentApi';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import '@assets/override-table.css';
import StatusLabel from '@components/label/StatusLabel';
import CommentLabel from '@components/label/CommentLabel';
import { periodApi } from '@services/periodApi';
import InfoTotal from './InfoTotal';

function Approval(props) {
  const {
    periodOptions,
    selectedPeriod,
    selectedMonthPeriod,
    monthPeriodOptions,
  } = useContext(PeriodContext);

  const [periodMOptions, setPeriodMOptions] = useState();

  const [abortController, setAbortController] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const value = props.data;
  const employeeid = value?.id;

  const [data, setData] = useState();
  const [total, setTotal] = useState({ target: 0, quality: 0, time: 0 });
  const apiRequest = useHttpPrivateRequest();

  const [show, setShow] = useState(false);

  const [selectedY, setSelectedY] = useState(selectedPeriod);
  const [selectedM, setSelectedM] = useState(selectedMonthPeriod);

  const [comment, setComment] = useState(data?.comment);
  const [isApprovalStatus, setIsApprovalStatus] = useState();

  const { setEmployeeData } = useContext(DataContext);

  const getData = async (employeeid, periodid, signal) => {
    try {
      const request = await apiRequest(
        assessmentComponentApi.getEmployeeAssessmentComponent(
          employeeid,
          periodid,
          signal
        )
      );
      const resData = request?.data?.data;
      setData(resData || []);
      setTotal({
        target: resData?.targetTotal,
        quality: resData?.qualityTotal,
        time: resData?.timeTotal,
      });
      setComment(resData?.comment);
      setIsApprovalStatus(resData?.status === 'APPROVAL');
    } catch (error) {
      setData([]);
      setTotal({ target: 0, quality: 0, time: 0 });
      setComment('');
      setIsApprovalStatus(false);
    }
  };

  const intitialState = (periodid) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    getData(employeeid, periodid, signal);
  };

  const handleClose = () => {
    setSelectedY({});
    setSelectedM({});
    setData([]);
    setTotal({ target: 0, quality: 0, time: 0 });
    setComment('');
    setShow(false);
  };

  const handleShow = () => {
    setSelectedY(selectedPeriod);
    setSelectedM(selectedMonthPeriod);
    setPeriodMOptions(monthPeriodOptions);
    intitialState(selectedMonthPeriod?.value);
    setShow(true);
  };

  const getMonthPeriod = async (periodYId) => {
    try {
      const controller = new AbortController();

      const request = await apiRequest(
        periodApi.getMonths(periodYId, controller.signal)
      );

      const response = request?.data?.data || [];
      const monthOptions = response.map((month) => {
        return { value: month.id, label: month.period };
      });

      setPeriodMOptions(monthOptions);
      setSelectedM(monthOptions[0]);
      intitialState(monthOptions[0]?.value);
    } catch (error) {}
  };

  const updateStatus = async (status) => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const payload = {
      employeeId: employeeid,
      periodId: selectedM?.value,
      comment,
      status,
    };

    try {
      await apiRequest(assessmentComponentApi.updateStatus(payload, signal));

      setData((prevData) => {
        return { ...prevData, status, comment };
      });

      //update status in datatable
      setEmployeeData((prevData) => {
        return prevData.map((prev) => {
          if (prev.id === value.id) {
            return { ...prev, status };
          }
          return prev;
        });
      });

      setIsApprovalStatus(false);
      sendSuccessMessage('Berhasil update status');
      setIsLoading(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleSelectedY = (period) => {
    setSelectedY(period);
    setSelectedM({});
    setData([]);
    getMonthPeriod(period?.value);
  };

  const handleSelectedM = (period) => {
    setSelectedM(period);
    intitialState(period?.value);
  };

  const handleApprove = () => {
    updateStatus('APPROVED');
  };

  const handleReject = () => {
    updateStatus('REJECTED');
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const columns = [
    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => <div title={row.indicator}>{row.indicator}</div>,
      sortable: true,
      grow: 2,
    },

    {
      name: 'Satuan',
      selector: (row) => row.unit,
      cell: (row) => <div>{row.unit}</div>,
      sortable: true,
      hide: 'sm',
      width: '120px',
    },
    {
      name: 'Target',
      selector: (row) => row.target,
      cell: (row) => <div>{row.target}</div>,
      hide: 'sm',
      sortable: true,
      width: '100px',
    },
    {
      name: 'Kualitas',
      selector: (row) => row.quality,
      cell: (row) => <div>{row.quality}</div>,
      hide: 'sm',
      sortable: true,
      width: '100px',
    },
    {
      name: 'Waktu',
      selector: (row) => row.time,
      cell: (row) => <div>{row.time}</div>,
      hide: 'sm',
      sortable: true,
      width: '100px',
    },
  ];

  return (
    <Fragment>
      <Button title="Rincian" variant="default" onClick={handleShow}>
        <i className="fas fa-eye"></i>{' '}
        <span className="d-none d-md-inline-block">Detail</span>
      </Button>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Rincian Beban Kerja {value?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 row">
            <div className="col-4 col-md-2 col-lg-1 fs-5 d-flex align-items-center justify-content-center">
              Periode
            </div>
            {selectedY && (
              <>
                <Select
                  className="col"
                  options={periodOptions}
                  defaultValue={selectedY}
                  onChange={handleSelectedY}
                  isDisabled={isLoading}
                />
                <Select
                  className="col"
                  options={periodMOptions}
                  value={selectedM || ''}
                  onChange={handleSelectedM}
                  isDisabled={isLoading || !selectedM}
                  isClearable
                />
              </>
            )}
          </div>

          {data?.status && (
            <div className="col-12 fs-5 d-flex justify-content-end align-items-center mb-3 gap-3">
              Status <StatusLabel status={data?.status} />
            </div>
          )}

          {data?.comment && (
            <CommentLabel comment={data?.comment} status={data?.status} />
          )}
          <DataTable
            responsive={false}
            columns={columns}
            data={data?.list ?? []}
            expandableRows
            expandableRowsComponent={(row) => {
              return (
                <>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Indikator</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.indicator}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Satuan</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.unit}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Target</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.target}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Kualitas</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.quality}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Waktu</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.time}{' '}
                      </p>
                    </div>
                  </div>
                </>
              );
            }}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
          />

          {data?.list?.length > 0 && <InfoTotal total={total} />}

          {isApprovalStatus && (
            <div className="row mt-3">
              <div className="col-md-8 offset-md-2 text-center mt-4 mb-4">
                <div>
                  <h5>Komentar (*)</h5>
                  <p className="col-form-label fw-normal">
                    <textarea
                      className="form-control"
                      placeholder="Komentar wajib diisi"
                      rows="3"
                      onChange={(e) => {
                        return setComment(e.target.value);
                      }}
                      value={comment}
                    />
                  </p>

                  <div className="d-flex justify-content-center gap-3">
                    <Button variant="danger" size="lg" onClick={handleReject}>
                      <i className="fas fa-close"></i> Tolak
                    </Button>

                    <Button variant="success" size="lg" onClick={handleApprove}>
                      <i className="fas fa-check"></i> Terima
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default Approval;
