import datetimeFormatter from '@utils/datetimeFormatter';
import { ADMIN_API } from './api';

const formatDate = datetimeFormatter();
const now = formatDate(new Date(), 'YYYY-MM-DD');

export const attendanceApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-attendance',
      params: {
        start: params.start || now,
        end: params.end || now,
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  getDetailByAdmin: (id, params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-detail-attendance/' + id,
      params: {
        start: params.start || now,
        end: params.end || now,
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-attendance',
      data: {
        employee_id: payload.employee_id,
        attendance_date: payload.attendance_date,
        clock_in: payload.clock_in,
        clock_out: payload.clock_out,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/edit-attendance',
      data: {
        id: payload.id,
        clock_in: payload.clock_in,
        clock_out: payload.clock_out,
      },
      signal,
    };
  },

  deleteByAdmin: (id, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/delete-attendance/' + id,
      signal,
    };
  },

  importByAdmin: (file, attendanceDate, signal) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mime', file.type);
    formData.append('attendance_date', attendanceDate);

    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: ADMIN_API + '/v1/import-bulk-attendance',
      data: formData,
      signal,
    };
  },
};
