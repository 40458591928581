/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import DeleteTypePerformanceDialog from './DeleteTypePerformanceDialog';
import { DataContext } from './ViewTypePerformance';

function DataListTypePerformance(props) {
  const value = props.data;
  const { data, reloadData, loading } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    reloadData(value?.id, signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '60px',
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <Button
            size="sm"
            variant="danger"
            onClick={() => {
              setSelectedRow(row);
              setShowDelete(true);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      ),
    },
    {
      name: 'Kewenangan',
      selector: (row) => row.performance_type,
      cell: (row) => row.performance_type,
      sortable: true,
    },
  ];

  return (
    <>
      <DeleteTypePerformanceDialog
        employeeData={value}
        data={selectedRow}
        show={showDelete}
        onClose={() => {
          setShowForm(false);
        }}
      />

      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        progressPending={loading}
      />
    </>
  );
}

export default DataListTypePerformance;
