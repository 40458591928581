import { ADMIN_API } from './api';

export const salaryReportApi = {
  getListByAdmin: (periodid, params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-salary-report/' + periodid,
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        q: params.q || '',
      },
      signal,
    };
  },

  getDetail: (periodid, employeeid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/get-detail-salary-report',
      data: {
        period_id: periodid,
        employee_id: employeeid,
      },
      signal,
    };
  },

  generate: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/generate-salary-report/' + periodid,
      signal,
    };
  },

  submit: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/submit/' + periodid,
      signal,
    };
  },

  downloadExcelByPeriod: (periodid, signal) => {
    return {
      method: 'post',
      responseType: 'blob',
      url: ADMIN_API + '/v1/download-salary-report-excel/' + periodid,
      signal,
    };
  },

  downloadPDFByPeriod: (periodid, signal) => {
    return {
      method: 'post',
      responseType: 'blob',
      url: ADMIN_API + '/v1/download-salary-report-pdf/' + periodid,
      signal,
    };
  },

  downloadPDFByEmployee: (periodid, employeeid, signal) => {
    return {
      method: 'post',
      responseType: 'blob',
      url: ADMIN_API + '/v1/download-salary-report-pdf',
      signal,
      data: {
        period_id: periodid,
        employee_id: employeeid,
      },
    };
  },
};
