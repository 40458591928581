import React from 'react';
import App from './../app';

import PersistLogin from '@components/auth/persist-login';
import RequireAuth from '@components/auth/require-auth';
import Forbidden from '@components/auth/forbidden';
import Notfound from '@components/error/notfound';
import { Navigate } from 'react-router-dom';
import HomePage from '@pages/Home/HomePage';
import LoginPage from '@pages/Login/LoginPage';
import RegisterPage from '@pages/Register/RegisterPage';
import EmployeePage from '@pages/MasterData/Employee/EmployeePage';
import roles from './app-roles';
import SalaryPage from '@pages/JobAssessment/Salary/SalaryPage';
import ProfilePage from '@pages/user/ProfilePage';
import ContractAssessmentPage from '@pages/JobAssessment/ContractAssessment/ContractAssessmentPage';
import { getToken } from '@services/api';
import ContractAssessmentEvaluationPage from '@pages/JobEvaluation/ContractAssessment/ContractAssessmentEvaluationPage';
import LogBookPage from '@pages/LogBook/LogBookPage';
import AssessmentComponentEvaluationPage from '@pages/JobEvaluation/AssessmentComponent/AssessmentComponentEvaluationPage';
import ResultAchievementEvaluationPage from '@pages/JobEvaluation/ResultAchievement/ResultAchievementEvaluationPage';
import AssessmentComponentPage from '@pages/JobAssessment/AssessmentComponent/AssessmentComponentPage';
import AchievementAssessmentPage from '@pages/JobAssessment/AchievementAssessment/AchievementAssessmentPage';
import AchievementAssessmentEvaluationPage from '@pages/JobEvaluation/AchievementAssessment/AchievementAssessmentEvaluationPage';
import AchievementAssessmentVerificationPage from '@pages/Verification/AchievementAssessment/AchievementAssessmentVerificationPage';
import ResultAchievementPage from '@pages/JobAssessment/ResultAchievement/ResultAchievementPage';
import EmployeeStatusOverviewPage from '@pages/Verification/EmployeeStatusOverview/EmployeeStatusOverviewPage';
import PeriodPage from '@pages/MasterData/Period/PeriodPage';
import UserRolePage from '@pages/MasterData/UserRole/UserRolePage';
import DepartmentPage from '@pages/MasterData/Department/DepartmentPage';
import DivisionPage from '@pages/MasterData/Division/DivisionPage';
import SectionPage from '@pages/MasterData/Section/SectionPage';
import JobsPage from '@pages/MasterData/Jobs/JobsPage';
import ChangePasswordPage from '@pages/user/ChangePasswordPage';
import PerformancePage from '@pages/MasterData/Performance/PerformancePage';
import AttendancePage from '@pages/MasterData/Attendance/AttendancePage';
import SalaryReportPage from '@pages/Report/SalaryReport/SalaryReportPage';

const allRoles = Object.values(roles);
const redirectTo = getToken() ? '/profile' : '/login';

const AppRoute = [
  {
    element: <PersistLogin />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'Forbidden', element: <Forbidden /> },
      {
        path: '/',
        element: <Navigate to={redirectTo} />,
      },
      { path: '*', element: <Notfound /> },

      {
        element: <App />,
        children: [
          {
            element: <RequireAuth allowedRoles={allRoles} />,
            children: [
              // { path: '/home', element: <HomePage /> },
              { path: '/home', element: <Navigate to={'/profile'} /> },
              { path: '/profile', element: <ProfilePage /> },
              { path: '/change-password', element: <ChangePasswordPage /> },
            ],
          },

          {
            element: <RequireAuth allowedRoles={[roles.EMPLOYEE]} />,
            children: [
              {
                path: '/job-assessment',
                children: [
                  {
                    path: 'contract-assessment',
                    element: <ContractAssessmentPage />,
                  },
                  {
                    path: 'assessment-component',
                    element: <AssessmentComponentPage />,
                  },
                  {
                    path: 'achievement-archievement',
                    element: <AchievementAssessmentPage />,
                  },
                  {
                    path: 'result-achievement',
                    element: <ResultAchievementPage />,
                  },
                  {
                    path: 'salary',
                    element: <SalaryPage />,
                  },
                ],
              },
              {
                path: 'log-book',
                element: <LogBookPage />,
              },
            ],
          },

          {
            element: <RequireAuth allowedRoles={[roles.LEADER]} />,
            children: [
              {
                path: '/job-evaluation',
                children: [
                  {
                    path: 'contract-assessment',
                    element: <ContractAssessmentEvaluationPage />,
                  },
                  {
                    path: 'assessment-component',
                    element: <AssessmentComponentEvaluationPage />,
                  },
                  {
                    path: 'achievement-assessment',
                    element: <AchievementAssessmentEvaluationPage />,
                  },
                  {
                    path: 'result-achievement',
                    element: <ResultAchievementEvaluationPage />,
                  },
                ],
              },
            ],
          },

          {
            element: <RequireAuth allowedRoles={[roles.VERIFICATOR]} />,
            children: [
              {
                path: '/verification',
                children: [
                  {
                    path: 'achievement-assessment',
                    element: <AchievementAssessmentVerificationPage />,
                  },
                  {
                    path: 'employee-overview',
                    element: <EmployeeStatusOverviewPage />,
                  },
                ],
              },
            ],
          },

          {
            element: (
              <RequireAuth allowedRoles={[roles.ADMIN, roles.SUPERADMIN]} />
            ),
            children: [
              {
                path: '/master-data',
                children: [
                  { path: 'period', element: <PeriodPage /> },
                  { path: 'employee', element: <EmployeePage /> },
                  { path: 'user-role', element: <UserRolePage /> },
                  { path: 'department', element: <DepartmentPage /> },
                  { path: 'division', element: <DivisionPage /> },
                  { path: 'section', element: <SectionPage /> },
                  { path: 'job', element: <JobsPage /> },
                  { path: 'performance', element: <PerformancePage /> },
                  { path: 'attendance', element: <AttendancePage /> },
                ],
              },
              {
                path: '/report',
                children: [
                  { path: 'salary-report', element: <SalaryReportPage /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default AppRoute;
