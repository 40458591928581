import { ADMIN_API } from './api';

export const jobsApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-jobs',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'title',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-jobs',
      data: {
        title: payload.title,
        min_salary: parseFloat(payload.min_salary),
        max_salary: parseFloat(payload.max_salary),
        status: payload.status,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-jobs',
      data: {
        id: payload.id,
        title: payload.title,
        min_salary: parseFloat(payload.min_salary),
        max_salary: parseFloat(payload.max_salary),
        status: payload.status,
      },
      signal,
    };
  },

  updateStatusByAdmin: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-jobs/' + periodid,
      signal,
    };
  },
};
