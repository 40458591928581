import { useContext } from 'react';
import { DataContext } from './AchievementAssessmentPage';

function InfoTotal(props) {
  const { total } = useContext(DataContext);
  return (
    <div className="row pt-3">
      <p className="m-0 fw-bold">Jumlah</p>
      <div className="col-md-4 mb-3">
        <div className="row">
          <div className="col">Target Kuantitas:</div>
          <div className="col-auto fw-bold">{total.tAchievement}</div>
        </div>
        <div className="row">
          <div className="col">Realisasi:</div>
          <div className="col-auto fw-bold">{total.rAchievement}</div>
        </div>
      </div>

      <div className="col-md-4 mb-3">
        <div className="row">
          <div className="col">Target Kualitas:</div>
          <div className="col-auto fw-bold">{total.tQuality}</div>
        </div>
        <div className="row">
          <div className="col">Realisasi:</div>
          <div className="col-auto fw-bold">{total.rQuality}</div>
        </div>
      </div>

      <div className="col-md-4 mb-3">
        <div className="row">
          <div className="col">Target Waktu:</div>
          <div className="col-auto fw-bold">{total.tTime}</div>
        </div>
        <div className="row">
          <div className="col">Realisasi:</div>
          <div className="col-auto fw-bold">{total.rTime}</div>
        </div>
      </div>
    </div>
  );
}

export default InfoTotal;
