import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { typePerformanceApi } from '@services/typePerformanceApi';
import { createContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataListTypePerformance from './DataListTypePerformance';
import TypePerformanceForm from './TypePerformanceForm';

export const DataContext = createContext({});

function ViewTypePerformance(props) {
  const value = props.data;

  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const apiRequest = useHttpPrivateRequest();

  const reloadData = async (employeId, signal) => {
    setLoading(true);
    try {
      const request = await apiRequest(
        typePerformanceApi.getByAdmin(employeId, signal)
      );
      const response = request?.data;
      setData(response?.data);

      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        reloadData,
        loading,
        setLoading,
      }}
    >
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Data Kewenangan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="row">
              <label className="col-sm-3 col-form-label">Nama Pegawai</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value?.fullname}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">NIP</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">
                  {value?.employee_gen}
                </p>
              </div>
            </div>
          </div>

          <DataListTypePerformance data={value} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end gap-3">
            <Button variant="primary" onClick={() => setShowForm(true)}>
              <i className="fas fa-add me-2"></i>Tambah
            </Button>

            <Button variant="default" onClick={handleClose}>
              Tutup
            </Button>
            <TypePerformanceForm
              employeeData={value}
              show={showForm}
              type="CREATE"
            />
          </div>
        </Modal.Footer>
      </Modal>
    </DataContext.Provider>
  );
}

export default ViewTypePerformance;
