import { BASE_STORAGE_API } from './api';

export const storageApi = {
  get: (fileName, signal) => {
    return {
      method: 'get',
      responseType: 'blob',
      url: BASE_STORAGE_API + '/v1/get-file',
      params: { fileName },
      signal,
    };
  },

  upload: (file, signal) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mime', file.type);
    return {
      method: 'post',
      url: BASE_STORAGE_API + '/v1/upload-block-storage',
      data: formData,
      signal,
    };
  },
  delete: (fileName, signal) => {
    return {
      method: 'post',
      url: BASE_STORAGE_API + '/v1/delete-block-storage',
      params: { fileName },
      signal,
    };
  },
};
