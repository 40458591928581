import {
  sendErrorMessage,
  sendSuccessMessage,
  sendWarningMessage,
} from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  useHttpPrivateRequest,
  useHttpStorageRequest,
} from '@hooks/useApiRequest';
import { logBookApi } from '@services/logBookApi';
import { DataContext, PeriodContext } from './LogBookPage';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import { storageApi } from '@services/storageApi';
import DownloadAttachement from './DownloadAttachement';
import DeleteAttachement from './DeleteAttachement';
import dayjs from 'dayjs';
import 'assets/custom-upload.css';
import { validateUrl } from '@utils/common';

function ActivityForm(props) {
  const { getData } = useContext(DataContext);
  const { startPeriod, endPeriod } = useContext(PeriodContext);

  const [inputPeriodId, setInputPeriodId] = useState('');
  const [inputAssessmentId, setInputAssessmentId] = useState('');
  const [inputQty, setInputQty] = useState('');
  const [inputTitle, setInputTitle] = useState('');
  const [inputDetail, setInputDetail] = useState('');
  const [inputTimeMinutes, setInputTimeMinutes] = useState('');

  const attachementOptions = [
    { value: 'file', label: 'File' },
    { value: 'link', label: 'URL' },
  ];

  const [selectedAttachementType, setSelectedAttachementType] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [linkAttachement, setLinkAttachement] = useState('');

  // const [uploadFile, setUploadFile] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [target, setTarget] = useState('');
  const [time, setTime] = useState('');

  const [assessmentLists, setAssessmentLists] = useState([]);
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [maxQty, setMaxQty] = useState(0);
  const [maxTime, setMaxTime] = useState(0);

  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(props.show);

  const handleStartDateChange = (currentDate) => {
    let timeMinutes = 0;
    if (isDate(currentDate)) {
      getAssessmentOptions(currentDate.format('YYYY-MM-DD'));

      if (isDate(endDate)) {
        timeMinutes = calculateTimeMinutes(currentDate, endDate);
      }
    }
    setStartDate(currentDate);
    setInputTimeMinutes(timeMinutes);
  };

  const handleEndDateChange = (currentDate) => {
    let timeMinutes = 0;
    if (isDate(currentDate)) {
      if (isDate(startDate)) {
        timeMinutes = calculateTimeMinutes(startDate, currentDate);
      }
    }
    setEndDate(currentDate);
    setInputTimeMinutes(timeMinutes);
  };

  const isValidEndDate = (currentDate) => {
    if (isDate(currentDate) && isDate(startDate)) {
      const startDateWithoutTime = startDate.clone().startOf('day');
      const currentDateWithoutTime = currentDate.clone().startOf('day');
      return currentDateWithoutTime.isSame(startDateWithoutTime, 'day');
    }
    return false;
  };

  const isValidStartDate = (currentDate) => {
    return true;
  };

  function isDate(date) {
    try {
      return date && date.isValid();
    } catch (e) {
      return false;
    }
  }

  function calculateTimeMinutes(start, end) {
    if (isDate(start) && isDate(end)) {
      return end.diff(start, 'minutes');
    }
    return 0;
  }

  const handleSelectedAssessment = (selected) => {
    const assessement = assessmentLists[selected.value];
    setInputAssessmentId(assessement.id);
    setInputPeriodId(assessement.periodId);
    setSelectedAssessment(selected);
    setTarget(assessement.target);
    setTime(assessement.time);
    setMaxQty(assessement.maxQty);
    setMaxTime(assessement.maxTime);
  };

  const handleClose = async () => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    setAbortController(controller);

    if (attachments.length > 0) {
      try {
        for (var i = 0; i < attachments.length; i++) {
          (function (i) {
            if (!validateUrl(attachments[i])) {
              setTimeout(function () {
                apiStorageRequest(storageApi.delete(attachments[i], signal));
              }, i * 3000);
            }
          })(i);
        }

        setAttachments([]);
        // setUploadFile('');
        setIsLoading(false);
        sendSuccessMessage('lampiran berhasil dihapus');
        setShow(false);
      } catch (error) {
        sendErrorMessage(error?.response?.data?.message || error.message);
        setAttachments([]);
        // setUploadFile('');
        setIsLoading(false);
        setShow(false);
      }
    }

    setStartDate('');
    setEndDate('');

    setShow(false);
  };

  const handleShow = () => {
    setIsLoading(false);
    initialForm();
    setShow(true);
  };

  const initialForm = () => {
    const initialDate = props?.initialDate;
    if (initialDate) {
      const initialStartDate = dayjs(initialDate).hour(7);
      const initialEndDate = dayjs(initialDate).hour(16);

      setStartDate(initialStartDate.format('YYYY-MM-DD HH:mm'));
      setEndDate(initialEndDate.format('YYYY-MM-DD HH:mm'));

      getAssessmentOptions(initialDate);

      const timeMinutes = calculateTimeMinutes(
        initialStartDate,
        initialEndDate
      );
      setInputTimeMinutes(timeMinutes);
    } else {
      setStartDate('');
      setEndDate('');
      setInputTimeMinutes('');
    }

    setSelectedAttachementType(attachementOptions[0]);

    setSelectedAssessment({});
    setMaxQty(0);
    setMaxTime(0);
    setAssessmentOptions([]);
    setTarget('');
    setTime('');
    setInputAssessmentId('');
    setInputPeriodId('');
    setInputDetail('');
    setInputTitle('');
    setInputQty('');
    // setUploadFile('');

    setAttachments([]);
    setLinkAttachement('');
  };

  const isDeletedAttachement = (deleted) => {
    if (deleted) {
      // setUploadFile('');
      setAttachments((prev) => prev.filter((filename) => filename !== deleted));
    }
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  const apiRequest = useHttpPrivateRequest();
  const apiStorageRequest = useHttpStorageRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleLinkAttachement = (e) => {
    setLinkAttachement(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 2097152; // 2MB (2 * 1024 * 1024)
    const allowedMimeTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'image/png',
      'image/jpeg',
    ];

    if (fileSizeInBytes > maxSizeInBytes) {
      sendWarningMessage(
        'Ukuran file melebihi batas maksimum yang diizinkan (2MB).'
      );
      e.target.value = '';
    } else if (!allowedMimeTypes.includes(file.type)) {
      sendWarningMessage('Tipe file yang diunggah tidak diperbolehkan.');
      e.target.value = '';
    } else {
      const controller = new AbortController();
      const signal = controller.signal;

      setIsLoading(true);
      setIsUploading(true);
      // setUploadFile(file);
      setAbortController(controller);

      try {
        const uploadResponse = await apiStorageRequest(
          storageApi.upload(file, signal)
        );
        const filename = uploadResponse.data.data.filename;

        setAttachments((prev) => [...prev, filename]);

        sendSuccessMessage('Lampiran berhasil diupload');
        // setUploadFile('');
        e.target.value = '';
        setIsLoading(false);
        setIsUploading(false);
      } catch (error) {
        // setUploadFile('');
        e.target.value = '';
        setIsLoading(false);
        setIsUploading(false);

        sendErrorMessage(error?.response?.data?.message || error.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isDate(dayjs(startDate))) {
      sendWarningMessage(
        'Isian Mulai harus diisi sesuai format YYYY-MM-DD HH:mm (contoh: 2023-06-01 08:00) ' +
          startDate
      );
      return;
    }
    if (!isDate(dayjs(endDate))) {
      sendWarningMessage(
        'Isian Selesai harus diisi sesuai format YYYY-MM-DD HH:mm (contoh: 2023-06-01 17:00)'
      );
      return;
    }

    if (calculateTimeMinutes(dayjs(startDate), dayjs(endDate)) < 1) {
      sendWarningMessage(
        'Selisih waktu antara Isian Mulai dan Isian Selesai harus lebih dari atau sama dengan 1 menit'
      );
      return;
    }

    if (!inputPeriodId || !inputAssessmentId) {
      sendWarningMessage('Isian Indikator harus tidak boleh kosong');
      return;
    }

    if (inputTimeMinutes < 1) {
      sendWarningMessage('Isian Waktu tidak boleh kurang dari 1');
      return;
    }
    if (inputTimeMinutes > 450) {
      sendWarningMessage('Isian Waktu tidak boleh lebih dari 450');
      return;
    }

    if (selectedAttachementType?.value === 'link' && linkAttachement) {
      if (!validateUrl(linkAttachement)) {
        sendWarningMessage('Isian URL Lampiran tidak valid!');
        return;
      }
    }

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    let payload = {
      periodeId: inputPeriodId,
      monthlyAssessmentId: inputAssessmentId,
      startDate: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(endDate).format('YYYY-MM-DD HH:mm:ss'),
      title: inputTitle,
      detail: inputDetail,
      qty: parseFloat(inputQty),
      timeMinutes: parseFloat(inputTimeMinutes),
    };

    try {
      if (selectedAttachementType?.value === 'file' && attachments.length > 0) {
        const filenames = attachments.join(',');
        payload = { ...payload, filename: filenames };
      } else if (selectedAttachementType?.value === 'link' && linkAttachement) {
        payload = { ...payload, filename: linkAttachement };
      }
      await apiRequest(logBookApi.addActivity(payload, signal));
      setIsLoading(false);

      let period = {
        startDate: formatDate(startPeriod),
        endDate: formatDate(endPeriod),
      };

      getData(period, signal);
      setAttachments([]);
      sendSuccessMessage('Berhasil menyimpan data');
      setIsLoading(false);
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  function formatDate(date) {
    try {
      if (date && date?.isValid()) {
        return date.format('YYYY-MM-DD');
      }
    } catch (e) {
      return date;
    }
  }

  const getAssessmentOptions = async (date) => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const response = await apiRequest(
        logBookApi.getMonthlyAssessments(date, signal)
      );

      const data = response?.data?.data || [];
      const options = data.map((item, index) => {
        return { value: index, label: item.indicator };
      });

      setAssessmentLists(data);
      setAssessmentOptions(options);

      setIsLoading(false);
    } catch (error) {
      //sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Form Aktivitas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="activityForm" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Mulai<sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-9">
                <DateTime
                  inputProps={{
                    placeholder: 'Mulai',
                    required: true,
                    readOnly: false,
                  }}
                  value={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  isValidDate={isValidStartDate}
                  closeOnSelect={true}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Selesai<sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-9">
                <DateTime
                  inputProps={{
                    placeholder: 'Selesai',
                    required: true,
                    readOnly: false,
                  }}
                  value={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  isValidDate={isValidEndDate}
                  closeOnSelect={true}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Indikator<sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-9">
                <Select
                  placeholder="Indikator"
                  options={assessmentOptions}
                  value={selectedAssessment}
                  onChange={(selected) => {
                    handleSelectedAssessment(selected);
                  }}
                  closeOnSelect={true}
                  required
                />
              </div>
            </div>

            <div className="row">
              <label className="col-sm-3 col-form-label">
                Kuantitas <sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-3 mb-3">
                <input
                  placeholder="Kuantitas"
                  type="number"
                  step="0.01"
                  max={maxQty}
                  value={inputQty}
                  onChange={(e) => setInputQty(e.target.value)}
                  className="form-control"
                  required
                />
                {inputPeriodId && maxQty && (
                  <p className="mt-1 mb-0">Sisa kuantitas: {maxQty}</p>
                )}
              </div>

              <div className="col-sm-6 mb-3">
                <div className="row">
                  <label className="col-6 col-form-label fw-normal text-md-end">
                    Target Kuantitas
                  </label>
                  <div className="col-6">
                    <input
                      placeholder="Target Kuantitas"
                      type="number"
                      step="0.01"
                      value={target}
                      readOnly
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-3 col-form-label">
                {' '}
                Waktu <sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-3 mb-3">
                <input
                  placeholder="Waktu"
                  type="number"
                  step="0.01"
                  max={maxTime}
                  value={inputTimeMinutes}
                  onChange={(e) => setInputTimeMinutes(e.target.value)}
                  className="form-control"
                  required
                />
                {inputPeriodId && maxTime && (
                  <p className="mt-1 mb-0">Sisa Waktu: {maxTime}</p>
                )}
              </div>

              <div className="col-sm-6 mb-3">
                <div className="row">
                  <label className="col-6 col-form-label fw-normal text-md-end">
                    Target Waktu
                  </label>
                  <div className="col-6">
                    <input
                      placeholder="Target Waktu"
                      type="number"
                      step="0.01"
                      value={time}
                      readOnly
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Judul<sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-9">
                <input
                  placeholder="Judul"
                  type="text"
                  value={inputTitle}
                  onChange={(e) => setInputTitle(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
            </div>

            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Keterangan<sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-sm-9">
                <textarea
                  placeholder="Keterangan"
                  value={inputDetail}
                  onChange={(e) => setInputDetail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
            </div>
          </form>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Lampiran</label>
            <div className="col-sm-9">
              {isUploading ? (
                <p className="text-center fw-bold p-3">Uploading...</p>
              ) : (
                <div className="row">
                  <div className="col-auto">
                    <Select
                      isDisabled={attachments.length > 0 ? true : false}
                      options={attachementOptions}
                      value={selectedAttachementType}
                      onChange={setSelectedAttachementType}
                    />
                  </div>

                  <div className="col">
                    {selectedAttachementType.value === 'link' ? (
                      <input
                        value={linkAttachement}
                        className="form-control"
                        placeholder="URL harus dimulai dengan http:// atau https://"
                        onChange={(e) => handleLinkAttachement(e)}
                      />
                    ) : (
                      attachments &&
                      attachments.length < 10 && (
                        <div className="upload-btn-wrapper">
                          <button className="btn btn-primary btn-lg">
                            <i className="fas fa-upload"></i> Upload
                          </button>
                          <input
                            className="form-control"
                            placeholder="Upload File"
                            type="file"
                            accept=".doc, .docx, .pdf, image/png, image/jpeg"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              {attachments &&
                attachments.map((attachment, index) => {
                  return (
                    <div key={index} className="border p-3 mt-3">
                      <div className="d-flex align-items-center gap-3 justify-content-between">
                        <DownloadAttachement
                          label={'Lampiran ...' + attachment.slice(-12)}
                          filename={attachment}
                          type="button"
                        />
                        <Button
                          disabled={isUploading}
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setSelectedAttachment(attachment);
                            setShowDelete(true);
                          }}
                        >
                          <i className="fas fa-trash"></i> Hapus
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Batal
          </Button>
          <Button
            form="activityForm"
            type="submit"
            variant="primary"
            disabled={isLoading}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteAttachement
        filename={selectedAttachment}
        show={showDelete}
        onDeleted={isDeletedAttachement}
      />
    </>
  );
}

export default ActivityForm;
