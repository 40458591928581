/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { DataContext } from './UserRolePage';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import Form from './Form';
import Filter from './Filter';
import DeleteDialog from './DeleteDialog';

function DataList(props) {
  const {
    data,
    reloadData,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalItems,
    filterParams,
    mergeFilters,
    loading,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [formType, setFormType] = useState('CREATE');
  const [querySearch, setQuerySearch] = useState('');

  const [sort, setSort] = useState('asc');

  useEffect(() => {
    showFilter && setShowFilter(false);
  }, [showFilter]);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  const handleCreate = () => {
    setFormType('CREATE');
    setSelectedRow({});
    setShowForm(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    mergeFilters({ page: page, pageSize: pageSize });
    reloadData(filterParams, signal);

    return () => {
      controller.abort();
    };
  }, [page, pageSize, querySearch, sort]);

  const handleSearch = (e) => {
    setQuerySearch(e.target.value);
    mergeFilters({ q: e.target.value });
  };

  const handleSort = (column, sortDirection) => {
    if (column.sortField) {
      mergeFilters({
        order: column.sortField,
        sort: sortDirection === 'asc' ? 'ASC' : 'DESC',
      });

      setSort(sortDirection);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    mergeFilters({ page: page });
  };

  const handleRowsPerPageChange = (newPageSize, page) => {
    setPageSize(newPageSize);
    setPage(page); // To reset to the first page when changing the page size
    mergeFilters({ page: page, pageSize: newPageSize });
  };

  const columns = [
    {
      button: true,
      name: '#',
      width: '50px',
      cell: (row) => (
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            setSelectedRow(row);
            setShowDelete(true);
          }}
          title="Hapus Peran"
        >
          <i className="fas fa-trash"></i>
        </Button>
      ),
    },

    {
      sortField: 'user_name',
      name: 'Nama Pengguna',
      selector: (row) => row.user_name,
      cell: (row) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-none d-md-inline-block">
            <div className="widget-icon widget-icon-md user rounded float-start me-5px mb-5px bg-gray-500 text-white">
              <i className="fa fa-user"></i>
            </div>
          </div>
          <div>
            <strong>{row.user_name}</strong>
            <br />
            <p className="m-0">
              {row.employee_gen && 'NIP: ' + row.employee_gen}
            </p>

            <p className="d-sm-none d-inline-block m-0">
              Peran: <span className="badge bg-success">{row.role_name}</span>
            </p>
          </div>
        </div>
      ),
      sortable: true,
    },

    {
      name: 'Nama Peran',
      selector: (row) => row.role_name,
      cell: (row) => (
        <span className="badge bg-success fs-5">{row.role_name}</span>
      ),
      sortable: false,
      maxWidth: '300px',
      hide: 'sm',
    },
  ];

  return (
    <>
      <div className="mb-3 d-sm-flex justify-content-between align-items-center gap-3">
        <div className="d-flex gap-3 mb-3 mb-sm-0">
          <button
            className="btn btn-default"
            onClick={() => setShowFilter(true)}
          >
            <i className="fas fa-filter"></i> Filter
          </button>

          <button className="btn btn-primary" onClick={handleCreate}>
            <i className="fas fa-plus"></i> Tambah
          </button>
        </div>

        <div className="text-right">
          <input
            type="text"
            style={{ minWidth: '280px' }}
            className="form-control"
            placeholder="Cari Nama Pegawai..."
            value={querySearch}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Filter show={showFilter} />
      <Form data={selectedRow} show={showForm} type={formType} />
      <DeleteDialog data={selectedRow} show={showDelete} />

      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        paginationServer
        paginationTotalRows={totalItems}
        paginationPerPage={pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onSort={handleSort}
        // paginationComponentOptions={{
        //   noRowsPerPage: true,
        // }}
        progressPending={loading}
      />
    </>
  );
}

export default DataList;
