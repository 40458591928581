import { BASE_API } from './api';

export const authApi = {
  login: (data) => {
    return {
      method: 'post',
      url: 'apis/auth/login',
      data: {
        username: data.username,
        password: data.password,
      },
      headers: { 'Content-Type': 'application/json' },
    };
  },
  logout: () => {
    return {
      method: 'post',
      url: 'apis/auth/logout',
    };
  },
  refresh: () => {
    return {
      method: 'get',
      url: BASE_API + '/refresh',
    };
  },

  check: (token) => {
    //encode token to base64
    const encodedToken = window.btoa(token); // Buffer.from(token).toString('base64');
    return {
      method: 'get',
      url: 'apis/auth/check',
      params: { token: encodedToken },
    };
  },

  profile: () => {
    return {
      method: 'get',
      url: BASE_API + '/v1/profile',
    };
  },
};
