import React, { useState } from 'react';
import Page from '@components/page/page.jsx';
import { Panel, PanelHeader, PanelBody } from '@components/panel/panel.jsx';
import useAuth from '@hooks/useAuth';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Calendar from 'react-calendar';
import Sparkline from '@rowno/sparkline';
import { useAppSetting } from '@context/AppSetting';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'react-calendar/dist/Calendar.css';

function HomePage() {
  const [title] = useState('Beranda');
  const [breadcrumb] = useState([{ title: 'Beranda' }]);

  const [state] = useAppSetting();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const lineChartData = {
    labels: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ],
    datasets: [
      {
        label: 'Page Views',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(' + state.color.tealRgb + ', 0.25)',
        borderColor: state.color.teal,
        borderWidth: 2,
        pointBorderColor: state.color.teal,
        pointBackgroundColor: state.color.componentBg,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: state.color.componentBg,
        pointHoverBorderColor: state.color.teal,
        pointHoverBorderWidth: 3,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40, 59, 76, 94, 77, 82],
      },
      {
        label: 'Visitors',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(' + state.color.blueRgb + ', 0.25)',
        borderColor: state.color.blue,
        borderWidth: 2,
        pointBorderColor: state.color.blue,
        pointBackgroundColor: state.color.componentBg,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: state.color.componentBg,
        pointHoverBorderColor: state.color.blue,
        pointHoverBorderWidth: 3,
        pointRadius: 3,
        pointHitRadius: 10,
        data: [25, 29, 40, 45, 16, 15, 20, 39, 26, 44, 57, 32],
      },
    ],
  };

  const lineChartOptions = {
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: 'black',
      },
    },
  };

  const date = new Date();

  const sparklineData = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.red },
    },
  ];

  const sparklineData2 = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.orange },
    },
  ];

  const sparklineData3 = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.teal },
    },
  ];

  const sparklineData4 = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.blue },
    },
  ];

  const sparklineData5 = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.gray500 },
    },
  ];

  const sparklineData6 = [
    {
      values: [789, 880, 676, 200, 890, 677, 900],
      colors: { area: 'transparent', line: state.color.componentColor },
    },
  ];

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-blue">
            <div className="stats-icon">
              <i className="fa fa-desktop"></i>
            </div>
            <div className="stats-info">
              <h4>TOTAL VISITORS</h4>
              <p>3,291,922</p>
            </div>
            <div className="stats-link">
              <Link to="/dashboard/v1">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-info">
            <div className="stats-icon">
              <i className="fa fa-link"></i>
            </div>
            <div className="stats-info">
              <h4>BOUNCE RATE</h4>
              <p>20.44%</p>
            </div>
            <div className="stats-link">
              <Link to="/dashboard/v1">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-orange">
            <div className="stats-icon">
              <i className="fa fa-users"></i>
            </div>
            <div className="stats-info">
              <h4>UNIQUE VISITORS</h4>
              <p>1,291,922</p>
            </div>
            <div className="stats-link">
              <Link to="/dashboard/v1">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-red">
            <div className="stats-icon">
              <i className="fa fa-clock"></i>
            </div>
            <div className="stats-info">
              <h4>AVG TIME ON SITE</h4>
              <p>00:12:23</p>
            </div>
            <div className="stats-link">
              <Link to="/dashboard/v1">
                View Detail <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-8">
          <Panel>
            <PanelHeader>Website Analytics (Last 7 Days)</PanelHeader>
            <PanelBody>
              <Line
                data={lineChartData}
                height={540}
                options={lineChartOptions}
              />
            </PanelBody>
          </Panel>
        </div>
        <div className="col-xl-4">
          <Panel>
            <PanelHeader>Analytics Details</PanelHeader>
            <PanelBody className="p-0">
              <div className="table-responsive">
                <table className="table table-panel align-middle mb-0">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Total</th>
                      <th>Trend</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="badge bg-danger">
                          Unique Visitor
                        </label>
                      </td>
                      <td>
                        13,203{' '}
                        <span className="text-success">
                          <i className="fa fa-arrow-up"></i>
                        </span>
                      </td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="badge bg-warning">Bounce Rate</label>
                      </td>
                      <td>28.2%</td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="badge bg-success">
                          Total Page Views
                        </label>
                      </td>
                      <td>1,230,030</td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData3}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="badge bg-blue">
                          Avg Time On Site
                        </label>
                      </td>
                      <td>00:03:45</td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData4}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="badge bg-gray-500">
                          % New Visits
                        </label>
                      </td>
                      <td>40.5%</td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData5}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="badge bg-inverse">
                          Return Visitors
                        </label>
                      </td>
                      <td>73.4%</td>
                      <td>
                        <Sparkline
                          width={100}
                          height={20}
                          lines={sparklineData6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader>Calendar</PanelHeader>
            <Calendar value={date} />
          </Panel>
        </div>
      </div>
    </Page>
  );
}

export default HomePage;
