import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './AttendancePage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { attendanceApi } from '@services/attendanceApi';
import datetimeFormatter from '@utils/datetimeFormatter';
import TypeAttendanceForm from './TypeAttendanceForm';
import { employeeApi } from '@services/employeeApi';
import { stringToDate } from '@utils/common';
import { ClockDetailDataContext } from './ViewClockDetail';

function Form(props) {
  const value = props.data;
  const employeData = props.employeData;

  const isEdit = props.type === 'UPDATE';

  const formatDate = datetimeFormatter();

  const [showTypeForm, setShowTypeForm] = useState(false);

  const { setData, filterParams } = useContext(DataContext);
  const { setData: setDetailData } = useContext(ClockDetailDataContext);

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const [attendanceDate, setAttendanceDate] = useState();
  const [clockIn, setClockIn] = useState();
  const [clockOut, setClockOut] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const now = new Date();
  const defaultStartTime = now.setHours(7, 0, 0, 0);
  const defaultEndTime = now.setHours(16, 0, 0, 0);

  const initialForm = () => {
    setAttendanceDate(defaultStartTime);
    setClockIn(defaultStartTime);
    setClockOut(defaultEndTime);
  };

  const handleShow = () => {
    setIsLoading(false);
    initialForm();

    getEmployees(value?.emplyee_id);

    if (isEdit) {
      setAttendanceDate(stringToDate(value?.date));

      if (value?.date && value?.clock_in) {
        setClockIn(
          stringToDate(
            formatDate(value?.date, 'YYYY-MM-DD') + ' ' + value?.clock_in
          )
        );
      }
      if (value?.date && value?.clock_out) {
        setClockOut(
          stringToDate(
            formatDate(value?.date, 'YYYY-MM-DD') + ' ' + value?.clock_out
          )
        );
      }

      const optionIndex = employeeOptions.findIndex(
        (option) => option.value === value?.employee_id
      );

      setSelectedEmployee(employeeOptions[optionIndex]);
    }

    setShow(true);
  };

  const getEmployees = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(employeeApi.getListOptions(signal));

      const response = request?.data?.data;
      const options = response.map((item) => {
        return {
          value: item.id,
          label: `${item.fullname} (NIP: ${item.employee_gen})`,
          fullname: item.fullname,
          employee_gen: item.employee_gen,
        };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setEmployeeOptions(options);

      setSelectedEmployee(options[indexOption]);
    } catch (error) {
      setEmployeeOptions([]);
      setSelectedEmployee('');
    }
  };

  const getDataByEmployeeGen = async (employeeGen) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const startDate = filterParams.start || formatDate(now, 'YYYY-MM-DD');
      const endDate = filterParams.end || formatDate(now, 'YYYY-MM-DD');

      const response = await apiRequest(
        attendanceApi.getListByAdmin(
          { q: employeeGen, start: startDate, end: endDate },
          signal
        )
      );

      const data = response?.data?.data;

      //update datatable
      setData((prevList) => {
        return prevList.map((prev) => {
          if (prev.employee_gen === employeeGen) {
            return {
              ...prev,
              ...data[0],
            };
          }
          return prev;
        });
      });
    } catch (error) {}
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    showTypeForm && setShowTypeForm(false);
  }, [showTypeForm]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value?.id,
      employee_id: selectedEmployee?.value,
      attendance_date: formatDate(attendanceDate, 'YYYY-MM-DD'),
      clock_in: formatDate(clockIn, 'HH:mm'),
      clock_out: formatDate(clockOut, 'HH:mm'),
    };

    const apiConfig = isEdit
      ? attendanceApi.updateByAdmin(payload, signal)
      : attendanceApi.createByAdmin(payload, signal);

    try {
      const response = await apiRequest(apiConfig);
      const data = response.data.data;

      setIsLoading(false);

      if (isEdit) {
        const refreshedColumn = {
          date: attendanceDate,
          clock_in: formatDate(clockIn, 'HH:mm:ss'),
          clock_out: formatDate(clockOut, 'HH:mm:ss'),
        };

        //update datatable
        setDetailData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === data.id) {
              return {
                ...prev,
                ...refreshedColumn,
              };
            }
            return prev;
          });
        });

        getDataByEmployeeGen(employeData?.employee_gen);
      } else {
        getDataByEmployeeGen(selectedEmployee?.employee_gen);
      }

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      contentClassName="bg-light shadow"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Presensi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          {isEdit ? (
            <>
              <div className="mb-3">
                <div className="row">
                  <label className="col-sm-3 col-form-label pb-0 pt-0">
                    Nama Pegawai
                  </label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal pb-0 pt-0">
                      {employeData?.employee_name}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-form-label pb-0 pt-0">
                    NIP
                  </label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal pb-0 pt-0">
                      {employeData?.employee_gen}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-form-label pb-0 pt-0">
                    Departemen
                  </label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal pb-0 pt-0">
                      {employeData?.department_name}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <label className="col-sm-3 col-form-label pb-0 pt-0">
                    Tanggal Presensi
                  </label>
                  <div className="col-sm-9">
                    <p className="col-form-label fw-normal pb-0 pt-0">
                      {formatDate(value?.date, 'YYYY-MM-DD')}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <br />
            </>
          ) : (
            <>
              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Nama Pegawai <sup style={{ color: 'red' }}>*</sup>
                </label>
                <div className="col-12 col-sm-8">
                  <Select
                    placeholder="Pilih pegawai"
                    options={employeeOptions}
                    value={selectedEmployee || ''}
                    onChange={(selected) => {
                      setSelectedEmployee(selected);
                    }}
                    required
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-sm-4 col-form-label">
                  Tanggal <sup style={{ color: 'red' }}>*</sup>
                </label>
                <div className="col-12 col-sm-8">
                  <DatePicker
                    className="form-control"
                    selected={attendanceDate}
                    onChange={setAttendanceDate}
                    placeholderText="Pilih tanggal"
                    dateFormat="yyyy-MM-dd"
                    required
                  />
                </div>
              </div>
            </>
          )}

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Jam Masuk <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                selected={clockIn}
                onChange={setClockIn}
                placeholderText="Pilih Jam Masuk"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Jam Keluar <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                selected={clockOut}
                onChange={setClockOut}
                placeholderText="Pilih Jam Masuk"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
        <TypeAttendanceForm show={showTypeForm} />
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
