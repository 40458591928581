import { storageApi } from '@services/storageApi';
import { validateUrl } from '@utils/common';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { Dropdown } from 'react-bootstrap';
const { useHttpStorageRequest } = require('@hooks/useApiRequest');
const { useState, useEffect } = require('react');

function DownloadAttachement(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const apiStorageRequest = useHttpStorageRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDownload = async (e) => {
    const filename = props.filename;

    if (filename) {
      setIsLoading(true);

      if (validateUrl(filename)) {
        setTimeout(() => {
          setIsLoading(false);
          window.open(filename, '_blank');
        }, 1000); // 3000 milliseconds (3 seconds) delay

        return;
      }

      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);
      try {
        const response = await apiStorageRequest(
          storageApi.get(filename, signal)
        );

        // Membuat URL objek untuk file yang di-download
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Membuat link sementara untuk mendownload file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(url);

        sendSuccessMessage('Berhasil download lampiran');
        setIsLoading(false);
      } catch (error) {
        sendErrorMessage(error?.response?.data?.message || error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {props.type === 'button' ? (
        <button className="btn btn-sm btn-dark" onClick={handleDownload}>
          <i className="fas fa-download"></i>{' '}
          {isLoading ? 'Loading...' : props.label ?? 'Download Lampiran'}
        </button>
      ) : props.type === 'icon' ? (
        <button className="btn btn-sm btn-dark" onClick={handleDownload}>
          <i className="fas fa-download"></i>{' '}
        </button>
      ) : (
        <Dropdown.Item onClick={handleDownload}>
          <i className="fas fa-download"></i>{' '}
          {isLoading ? 'Loading...' : props.label ?? 'Download Lampiran'}
        </Dropdown.Item>
      )}
    </>
  );
}

export default DownloadAttachement;
