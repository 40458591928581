import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './AttendancePage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString, stringToDate } from '@utils/common';

function Filter(props) {
  const { reloadData, filterParams, mergeFilters } = useContext(DataContext);

  const [abortController, setAbortController] = useState(null);
  const dateNow = new Date();
  const [startPeriod, setStartPeriod] = useState(dateNow);
  const [endPeriod, setEndPeriod] = useState(dateNow);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    mergeFilters({
      start: dateToString(startPeriod, 'yyyy-MM-dd'),
      end: dateToString(endPeriod, 'yyyy-MM-dd'),
    });
    setShow(true);
  };

  const handleStartChange = (e) => {
    let start = dateToString(e, 'yyyy-MM-dd');
    setStartPeriod(e);
    mergeFilters({ start: start });
  };
  const handleEndChange = (e) => {
    let end = dateToString(e, 'yyyy-MM-dd');
    setEndPeriod(e);
    mergeFilters({ end: end });
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    reloadData(filterParams, signal);
    handleClose();
  };

  return (
    <Modal
      size="xs"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Periode</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="filterForm"
          onSubmit={handleSubmit}
          className="mt-3 pe-5 ps-5"
        >
          <h5 className="mb-3">Urutkan Berdasarkan:</h5>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3">
              <p className="mb-0">Mulai</p>
              <DatePicker
                className="form-control"
                selected={startPeriod}
                onChange={handleStartChange}
                placeholderText="Pilih tanggal mulai"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <p className="mb-0">Akhir</p>
              <DatePicker
                className="form-control"
                selected={endPeriod}
                onChange={handleEndChange}
                placeholderText="Pilih tanggal berakhir"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Batal
        </Button>
        <Button form="filterForm" type="submit" variant="primary">
          Filter
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Filter;
