import { ADMIN_API } from './api';

export const divisionApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-divisions',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'division_name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-division',
      data: {
        divisionName: payload.division_name,
        companyId: payload.company_id,
        managerId: payload.manager_id,
        locationId: payload.location_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-division',
      data: {
        id: payload.id,
        divisionName: payload.division_name,
        companyId: payload.company_id,
        managerId: payload.manager_id,
        locationId: payload.location_id,
        status: payload.status,
      },
      signal,
    };
  },

  updateStatusByAdmin: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-division/' + periodid,
      signal,
    };
  },
};
