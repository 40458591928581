import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
  DataContext,
  PeriodContext,
} from './AchievementAssessmentVerificationPage';

import Verifiy from './Verifiy';
import StatusLabel from '@components/label/StatusLabel';
import { Button } from 'react-bootstrap';

function EmployeeDataList(props) {
  const [selectedRow, setSelectedRow] = useState('');

  const [showVerify, setShowVerify] = useState(false);
  useEffect(() => {
    showVerify && setShowVerify(false);
  }, [showVerify]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '80px',

      cell: (row) => (
        <div className="d-flex justify-content-start align-items-start gap-2">
          <Button
            title="Rincian"
            variant="default"
            onClick={() => {
              setSelectedRow(row);
              setShowVerify(true);
            }}
          >
            <i className="fas fa-eye"></i>{' '}
            <span className="d-none d-md-inline-block">Detail</span>
          </Button>
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-4 text-center w-100">
          <StatusLabel status={row.status} />
        </div>
      ),
      sortable: true,
      width: 'auto',
      maxWidth: '120px',
      hide: 'sm',
    },

    {
      name: 'Pegawai',
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-none d-md-inline-block">
            <div className="widget-icon widget-icon-md user rounded float-start me-5px mb-5px bg-gray-500 text-white">
              <i className="fa fa-user"></i>
            </div>
          </div>
          <div>
            <strong>{row.name}</strong>
            <br />
            {row.nip && 'NIP: ' + row.nip}
            <br />
            <div className="d-sm-none d-inline-block mt-1">
              Status: <StatusLabel status={row.status} />
            </div>
          </div>
        </div>
      ),
      sortable: true,
      minWidth: '120px',
    },
    {
      name: 'Periode',
      selector: (row) => row.periode_year,
      cell: (row) => row.periode_year,
      sortable: true,
      width: 'auto',
      hide: 'md',
      maxWidth: '120px',
    },
    {
      name: 'Atasan Langsung',
      selector: (row) => row.leader_name,
      cell: (row) => row.leader_name,
      sortable: true,
      width: 'auto',
      maxWidth: '230px',
      hide: 'md',
    },
    {
      name: 'Nilai',
      selector: (row) => row.apraisal_result,
      cell: (row) => row.apraisal_result,
      sortable: true,
      width: 'auto',
      maxWidth: '100px',
      hide: 'md',
    },
  ];

  const { parentid, periodid, selectedDepartment } = useContext(PeriodContext);
  const { employeeData, reloadEmployeeData } = useContext(DataContext);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const department = selectedDepartment?.value;

    (parentid || periodid || department) &&
      reloadEmployeeData(periodid, department, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  return (
    <>
      <Verifiy show={showVerify} data={selectedRow} />

      <DataTable
        responsive={false}
        columns={columns}
        data={employeeData}
        dense
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        expandableRows
        expandableRowsComponent={(row) => {
          return (
            <>
              <div className="row">
                <label className="col-sm-2 col-form-label">Nama Pegawai</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{row.data.name}</p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-2 col-form-label">Periode</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {row.data.periode_year}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-2 col-form-label">Nama Atasan Langsung</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {row.data.leader_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-2 col-form-label">Nilai</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {row.data.apraisal_result} %
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-2 col-form-label">Status</label>
                <div className="col-sm-9 pt-2">
                  <StatusLabel status={row.data.status} />
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

export default EmployeeDataList;
