import { BASE_API } from './api';

export const achievementAssessmentApi = {
  get: (periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-assessment-achievement/' + periodid,
      signal: signal,
    };
  },
  getLogBookList: (monthlyAssessmentId, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-list-my-activity/' + monthlyAssessmentId,
      signal: signal,
    };
  },

  update: (payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/update-assessment-achievement',
      data: {
        id: payload.id,
        qualityValue: payload.quality,
      },
      signal: signal,
    };
  },

  submit: (id, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/submit-assessment-achievement',
      data: { id: id },
      signal: signal,
    };
  },

  getDepartmentList: (periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API +
        '/v1/get-list-employee-achievement-assessment-by-manager-id/' +
        periodid,
      signal: signal,
    };
  },

  getEmployees: (periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API +
        '/v1/get-list-employee-achievement-assessment-by-manager-id/' +
        periodid,
      signal: signal,
    };
  },
  getEmployeeAchievementAssessment: (employeeid, periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-assessment-achievement-by-manager-id',
      params: { employeeId: employeeid, periodId: periodid },
      signal: signal,
    };
  },
  updateStatus: (data, signal) => {
    //status = APPROVED/REJECTED
    return {
      method: 'post',
      url: BASE_API + '/v1/update-status-achievement-assessment-by-manager',
      data: {
        id: data.id,
        comment: data.comment,
        apraisalResult: parseFloat(data.apraisalResult),
        status: data.status,
      },
      signal: signal,
    };
  },
  getEmployeesForVerify: (periodid, department = 'ALL', signal) => {
    return {
      method: 'get',
      url:
        BASE_API +
        '/v1/get-list-employee-achievement-assessment-verificator/' +
        periodid,
      params: { department },
      signal: signal,
    };
  },
  getEmployeeAchievementAssessmentForVerify: (employeeid, periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-assement-achievement-by-verificator',
      params: { employeeId: employeeid, periodId: periodid },
      signal: signal,
    };
  },
  verify: (data, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/update-status-achievement-assessment-by-verificator',
      data: {
        id: data.id,
        apraisalResult: parseFloat(data.apraisalResult),
        comment: data.comment,
      },
      signal: signal,
    };
  },
};
