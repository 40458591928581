import { authApi } from '@services/authApi';
import { http, removeToken } from '@services/api';
import useAuth from './useAuth';

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    try {
      await http(authApi.logout());
    } catch (error) {}

    setAuth({});
    removeToken();
  };

  return logout;
};

export default useLogout;
