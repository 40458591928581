import { ADMIN_API } from './api';

export const performanceApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-performance',
      params: {
        category: params.category || '',
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'performance_type',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  getDetailByAdmin: (id, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-detail-performance/' + id,
      signal,
    };
  },

  getListTypePerformance: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-tipe-performance',
      signal,
    };
  },

  getListSubPerformance: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-sub-performance',
      signal,
    };
  },

  addTypePerformance: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/add-type-performance',
      data: {
        type_name: payload.type_name,
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-performance',
      data: {
        performance_type: payload.performance_type,
        performance_element: payload.performance_element,
        performance_indicator: payload.performance_indicator,
        performance_unit: payload.performance_unit,
        performance_target: parseFloat(payload.performance_target),
        performance_cascading: payload.performance_cascading,
        performance_category: payload.performance_category,
        performance_used: payload.performance_used,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-performance',
      data: {
        id: payload?.id,
        performance_type: payload.performance_type,
        performance_element: payload.performance_element,
        performance_indicator: payload.performance_indicator,
        performance_unit: payload.performance_unit,
        performance_target: parseFloat(payload.performance_target),
        performance_cascading: payload.performance_cascading,
        performance_category: payload.performance_category,
        performance_used: payload.performance_used,
      },
      signal,
    };
  },

  deleteByAdmin: (id, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/delete-performance/' + id,
      signal,
    };
  },

  importByAdmin: (file, signal) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mime', file.type);

    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: ADMIN_API + '/v1/import-performance',
      data: formData,
      signal,
    };
  },
};
