import '@assets/loading.css';

function Loading() {
  return (
    <div className="loading-page">
      <div className="spinner-grow text-white" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
