import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import { createContext, useEffect, useState } from 'react';
import PeriodOptions from './PeriodOptions';
import { Tabs, Tab, Button } from 'react-bootstrap';
import CalendarView from './CalendarView';
import DataList from './DataList';
import ActivityForm from './ActivityForm';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { logBookApi } from '@services/logBookApi';

export const DataContext = createContext({});
export const PeriodContext = createContext({});

function LogBookPage() {
  const [title] = useState('Catatan Kerja');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title },
  ]);

  const [activeTab, setActiveTab] = useState('calendarTab');
  const [showForm, setShowForm] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  const [data, setData] = useState([]);

  const [startPeriod, setStartPeriod] = useState([]);
  const [endPeriod, setEndPeriod] = useState([]);

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const apiRequest = useHttpPrivateRequest();
  const getData = async (period, signal) => {
    try {
      const request = await apiRequest(
        logBookApi.listActivities(period, signal)
      );
      const response = request?.data;
      setData(response?.data);
    } catch (error) {
      setData([]);
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <Panel>
        <PanelHeader>Panel Data</PanelHeader>
        <PanelBody>
          <DataContext.Provider
            value={{
              data,
              setData,
              getData,
              selectedRows,
              setSelectedRows,
              toggleCleared,
              setToggleCleared,
            }}
          >
            <PeriodContext.Provider
              value={{ startPeriod, setStartPeriod, endPeriod, setEndPeriod }}
            >
              <div className="float-start">
                <Button
                  title="Tambah Aktivitas"
                  variant="success"
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i className="fas fa-plus"></i>{' '}
                  <span className="d-none d-md-inline-block">
                    Tambah Aktivitas
                  </span>
                </Button>
                <ActivityForm show={showForm} />
              </div>
              <Tabs
                activeKey={activeTab}
                onSelect={handleSelect}
                variant="pills"
                className="justify-content-end"
              >
                <Tab
                  eventKey="calendarTab"
                  title={
                    <>
                      <i
                        className="fas fa-calendar"
                        title="Tampilan Kalender"
                      ></i>{' '}
                      <span className="d-none d-md-inline-block">
                        Tampilan Kalender
                      </span>
                    </>
                  }
                  className="pt-3"
                >
                  {activeTab === 'calendarTab' && (
                    <>
                      <hr className="mt-1" />
                      <CalendarView />
                    </>
                  )}
                </Tab>

                <Tab
                  eventKey="listTab"
                  title={
                    <>
                      <i className="fas fa-list" title="Tampilan List"></i>{' '}
                      <span className="d-none d-md-inline-block">
                        Tampilan List
                      </span>
                    </>
                  }
                  className="pt-3"
                >
                  {activeTab === 'listTab' && (
                    <>
                      <hr className="mt-3" />
                      <div className="py-3">
                        <PeriodOptions />
                      </div>

                      <DataList />
                    </>
                  )}
                </Tab>
              </Tabs>
            </PeriodContext.Provider>
          </DataContext.Provider>
        </PanelBody>
      </Panel>
    </Page>
  );
}

export default LogBookPage;
