/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { ClockDetailDataContext } from './ViewClockDetail';
import Form from './Form';
import DeleteTypeAttendanceDialog from './DeleteTypeAttendanceDialog';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import datetimeFormatter from '@utils/datetimeFormatter';

function DataListClockDetail(props) {
  const value = props.data;
  const { data, reloadData, loading } = useContext(ClockDetailDataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    showDeleteDialog && setShowDeleteDialog(false);
  }, [showDeleteDialog]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    reloadData(value?.employee_id, signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setShowForm(true);
  };

  const formatDate = datetimeFormatter();

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <Button
          variant="default"
          size="sm"
          onClick={() => handleEdit(row)}
          title="Edit"
        >
          <i className="fas fa-edit"></i>
        </Button>
        // <DropdownButton
        //   title={<i className="fas fa-ellipsis-v"></i>}
        //   variant="default"
        // >
        //   <DropdownItem
        //     onClick={() => {
        //       handleEdit(row);
        //     }}
        //   >
        //     <i className="fas fa-edit me-2"></i> Edit
        //   </DropdownItem>

        //   <Dropdown.Divider />

        //   <Dropdown.Item
        //     onClick={() => {
        //       setSelectedRow(row);
        //       setShowDeleteDialog(true);
        //     }}
        //   >
        //     <div className="text-danger">
        //       <i className="fas fa-close me-2"></i> Hapus
        //     </div>
        //   </Dropdown.Item>
        // </DropdownButton>
      ),
    },

    {
      name: 'Tanggal',
      selector: (row) => formatDate(row.date, 'YYYY-MM-DD'),
      cell: (row) => formatDate(row.date, 'YYYY-MM-DD'),
      sortable: true,
    },
    {
      name: 'Jam Masuk',
      selector: (row) => (row.clock_in ? row.clock_in.substr(0, 5) : '-'),
      cell: (row) => (row.clock_in ? row.clock_in.substr(0, 5) : '-'),
      sortable: true,
    },
    {
      name: 'Jam Keluar',
      selector: (row) => (row.clock_out ? row.clock_out.substr(0, 5) : '-'),
      cell: (row) => (row.clock_out ? row.clock_out.substr(0, 5) : '-'),
      sortable: true,
    },
  ];

  return (
    <>
      <Form
        data={selectedRow}
        employeData={value}
        show={showForm}
        type="UPDATE"
      />
      <DeleteTypeAttendanceDialog
        data={selectedRow}
        show={showDeleteDialog}
        onClose={() => {
          setShowForm(false);
        }}
      />
      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        progressPending={loading}
      />
    </>
  );
}

export default DataListClockDetail;
