import { useAppSetting } from './context/AppSetting';

import Header from '@components/header/header.jsx';
import Sidebar from '@components/sidebar/sidebar.jsx';
import SidebarRight from '@components/sidebar-right/sidebar-right.jsx';
import TopMenu from '@components/top-menu/top-menu.jsx';
import Content from '@components/content/content.jsx';
// import CheckUpdateApp from '@utils/checkUpdateApp';

function App() {
  const [state] = useAppSetting();

  return (
    <div
      className={
        'app ' +
        (state.appGradientEnabled ? 'app-gradient-enabled ' : '') +
        (state.appHeaderNone ? 'app-without-header ' : '') +
        (state.appHeaderFixed && !state.appHeaderNone
          ? 'app-header-fixed '
          : '') +
        (state.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
        (state.appSidebarNone ? 'app-without-sidebar ' : '') +
        (state.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
        (state.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
        (state.appSidebarLight ? 'app-with-light-sidebar ' : '') +
        (state.appSidebarMinify ? 'app-sidebar-minified ' : '') +
        (state.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
        (state.appTopMenu ? 'app-with-top-menu ' : '') +
        (state.appContentFullHeight ? 'app-content-full-height ' : '') +
        (state.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
        (state.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
        (state.appSidebarEndMobileToggled
          ? 'app-sidebar-end-mobile-toggled '
          : '') +
        (state.hasScroll ? 'has-scroll ' : '')
      }
    >
      {!state.appHeaderNone && <Header />}
      {!state.appSidebarNone && <Sidebar />}
      {state.appSidebarTwo && <SidebarRight />}
      {state.appTopMenu && <TopMenu />}
      {!state.appContentNone && <Content />}

      {/* <CheckUpdateApp /> */}
    </div>
  );
}

export default App;
