import Page from '@components/page/page';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function NotFound() {
  const [title] = useState('Halaman tidak ditemukan');
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Page title={title} hideTitle>
      <div className="error">
        <div className="error-code">404</div>
        <div className="error-content">
          <div className="error-message">Kami tidak dapat menemukannya...</div>
          <div className="error-desc mb-4">
            Halaman yang Anda cari tidak ada. <br />
            Mungkin, ada halaman yang akan membantu menemukan apa yang Anda
            cari.
          </div>
          <div>
            <div className="d-flex justify-content-center gap-3">
              <Link onClick={goBack} className="btn btn-default px-3">
                <i className="fas fa-arrow-left"></i> Kembali
              </Link>

              <Link to="/home" className="btn btn-primary px-3">
                <i className="fas fa-home"></i> Beranda
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default NotFound;
