import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import PeriodOptions from './PeriodOptions';
import GenerateButton from './GenerateButton';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { assessmentComponentApi } from '@services/assessmentComponentApi';
import DataList from './DataList';
import StatusLabel from '@components/label/StatusLabel';
import SubmitContractButton from './SubmitContractButton';
import PrintDocumentModal from './PrintDocument';
import DocumentContent from './DocumentContent';
import CommentLabel from '@components/label/CommentLabel';
import AddAdditionalTaskButton from './AddAdditionalTaskButton';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function AssessmentComponentPage(props) {
  const [title] = useState('Beban Kerja');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Penilaian Kinerja' },
    { title },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [monthPeriodOptions, setMonthPeriodOptions] = useState([]);
  const [selectedMonthPeriod, setSelectedMonthPeriod] = useState(null);

  const parentid = selectedPeriod?.value;
  const periodid = selectedMonthPeriod?.value;

  const [data, setData] = useState();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState({ target: 0, quality: 0, time: 0 });
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const apiRequest = useHttpPrivateRequest();

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const reloadData = async (periodid, signal) => {
    try {
      const request = await apiRequest(
        assessmentComponentApi.get(periodid, signal)
      );
      const resData = request?.data?.data;
      setData(resData);
      setList(resData?.list);
      setTotal({
        target: resData?.targetTotal,
        quality: resData?.qualityTotal,
        time: resData?.timeTotal,
      });
      setStatus(resData?.status);
      setComment(resData?.comment);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    } catch (error) {
      setData([]);
      setList([]);
      setTotal({ target: 0, quality: 0, time: 0 });
      setStatus('');
      setComment(null);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
  };

  const listCount = list?.length || 0;

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          monthPeriodOptions,
          setMonthPeriodOptions,
          selectedMonthPeriod,
          setSelectedMonthPeriod,
          parentid,
          periodid,
        }}
      >
        <Panel>
          <PanelHeader>Filter Periode</PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            data,
            setData,
            reloadData,
            list,
            total,
            setTotal,
            setList,
            selectedRows,
            setSelectedRows,
            toggleCleared,
            setToggleCleared,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-start mb-3 gap-3 ">
                  {periodid &&
                  status !== 'APPROVED' &&
                  status !== 'APPROVAL' ? (
                    <>
                      <GenerateButton />
                      <AddAdditionalTaskButton />
                    </>
                  ) : null}

                  {periodid &&
                  (status === 'DRAFT' || status === 'REJECTED') &&
                  listCount > 0 ? (
                    <SubmitContractButton />
                  ) : null}
                  {status === 'APPROVED' ? (
                    <PrintDocumentModal content={<DocumentContent />} />
                  ) : null}
                </div>
                {status && (
                  <div className="col-4 fs-4 d-flex justify-content-end align-items-center mb-3 gap-3">
                    Status <StatusLabel status={status} />
                  </div>
                )}
              </div>

              {comment && <CommentLabel comment={comment} status={status} />}

              <DataList />
            </PanelBody>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default AssessmentComponentPage;
