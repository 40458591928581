import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const CustomProgressBar = ({ greenPercentage, redPercentage }) => {
  return (
    <ProgressBar>
      {greenPercentage > 0 && (
        <ProgressBar
          striped
          animated
          variant="success"
          now={greenPercentage}
          key={1}
        />
      )}
      {redPercentage > 0 && (
        <ProgressBar
          striped
          animated
          variant="danger"
          now={redPercentage}
          key={2}
        />
      )}
    </ProgressBar>
  );
};

export default CustomProgressBar;
