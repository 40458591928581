import { BASE_API } from './api';

export const contractAssessmentApi = {
  get: (periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-list-contract-assessment/' + periodid,
      signal: signal,
    };
  },
  getListPerformance: (signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-list-performance-component',
      signal: signal,
    };
  },
  generate: (periodid, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/generate-contract-assessment/' + periodid,
      signal: signal,
    };
  },
  duplicate: (periodid, payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/duplicate-contract-assessment/' + periodid,
      data: { data: payload },
      signal: signal,
    };
  },
  addAdditionalContract: (periodId, contractIds, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/add-contract-assessment-by-performance-component-id',
      data: { periodid: periodId, contractIds: contractIds },
      signal: signal,
    };
  },
  update: (payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/update-contract-assessment',
      data: payload,
      signal: signal,
    };
  },
  delete: (ids, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/delete-contract-assessment',
      data: { id: ids },
      signal: signal,
    };
  },

  submit: (periodid, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/submit-contract-assessment/' + periodid,
      signal: signal,
    };
  },

  getEmployees: (periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API +
        '/v1/get-list-employee-contract-assessment-by-manager-id/' +
        periodid,
      signal: signal,
    };
  },
  getEmployeeContractAssessments: (employeeid, periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API + '/v1/get-list-contract-assessment-by-period-id-employee-id',
      params: { employeeId: employeeid, periodId: periodid },
      signal: signal,
    };
  },
  updateStatus: (data, signal) => {
    //status = APPROVED/REJECTED
    return {
      method: 'post',
      url: BASE_API + '/v1/update-status-contract-assessment',
      data: {
        employeeId: data.employeeId,
        periodId: data.periodId,
        comment: data.comment,
        status: data.status,
      },
      signal: signal,
    };
  },
};
