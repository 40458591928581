import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Form as BSForm } from 'react-bootstrap';
import { DataContext } from './PerformancePage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { performanceApi } from '@services/performanceApi';
import datetimeFormatter from '@utils/datetimeFormatter';
import TypePerformanceForm from './TypePerformanceForm';

function Form(props) {
  const value = props.data;
  const isEdit = props.type === 'UPDATE';

  const [showTypeForm, setShowTypeForm] = useState(false);

  const { setData } = useContext(DataContext);

  const [category, setCategory] = useState();
  //const [type, setType] = useState();
  const [element, setElement] = useState();
  const [indicator, setIndicator] = useState();
  const [unit, setUnit] = useState();
  const [target, setTarget] = useState();
  //const [cascading, setCascading] = useState();
  const [used, setUsed] = useState(true);

  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState('');

  const [cascadingOptions, setCascadingOptions] = useState([]);
  const [selectedCascading, setSelectedCascading] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const initialForm = () => {
    setCategory('');
    setSelectedType('');
    setElement('');
    setIndicator('');
    setUnit('');
    setTarget('');
    setSelectedCascading([]);
  };

  const handleShow = () => {
    setIsLoading(false);
    initialForm();

    getListTypePerformance(value?.performance_type);
    getListSubPerformance(value?.performance_cascading);

    if (isEdit) {
      setCategory(value?.performance_category);
      setElement(value?.performance_element);
      setIndicator(value?.performance_indicator);
      setUnit(value?.performance_unit);
      setTarget(value?.performance_target);
      setUsed(
        value?.performance_used === 'ENABLE' || value?.performance_used === 'Y'
          ? true
          : false
      );

      const typeOptionIndex = typeOptions.findIndex(
        (option) => option.value === value?.performance_type
      );

      setSelectedType(typeOptions[typeOptionIndex]);
    }

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    showTypeForm && setShowTypeForm(false);
  }, [showTypeForm]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const getListTypePerformance = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        performanceApi.getListTypePerformance(signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.tipe_name, label: item.tipe_name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setTypeOptions(options);
      setSelectedType(options[indexOption]);
    } catch (error) {
      setTypeOptions([]);
      setSelectedType('');
    }
  };

  const getListSubPerformance = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        performanceApi.getListSubPerformance(signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return {
          value: item.id,
          label: item.performance_type + '|' + item.performance_element,
        };
      });

      const subPerformances = selectedValue?.split(',') || [];

      const selectedOptions = subPerformances.map((value) => {
        const matchingObject = options.find((obj) => obj.value === value);
        return matchingObject ? matchingObject : { value, label: value };
      });

      setCascadingOptions(options);
      setSelectedCascading(selectedOptions);
    } catch (error) {
      setCascadingOptions([]);
      setSelectedCascading([]);
    }
  };

  const formatDate = datetimeFormatter();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      performance_type: selectedType?.value,
      performance_element: element,
      performance_indicator: indicator,
      performance_unit: unit,
      performance_target: target,
      performance_cascading: selectedCascading
        .map((option) => option.value)
        .join(','),
      performance_cascading_value: selectedCascading
        .map((option) => option.label)
        .join(','),
      performance_category: category,
      performance_used: used ? 'Y' : 'N',
    };

    const refreshedColumn = {
      ...payload,
      created_at: value?.created_at || formatDate(new Date()),
      created_by: value?.created_by,
      updated_at: value?.updated_at,
      updated_by: value?.updated_by,
    };

    const apiConfig = isEdit
      ? performanceApi.updateByAdmin(payload, signal)
      : performanceApi.createByAdmin(payload, signal);

    try {
      const response = await apiRequest(apiConfig);

      setIsLoading(false);

      if (isEdit) {
        //update datatable
        setData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === value.id) {
              return {
                ...prev,
                ...refreshedColumn,
              };
            }
            return prev;
          });
        });
      } else {
        payload.id = response.data.data.id;
        setData((prevList) => {
          return [...prevList, refreshedColumn];
        });
      }

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Kewenangan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Kategori <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Kategori"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
            Kelompok Jabatan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8 d-flex align-items-center gap-3">
              <Select
                className="flex-fill"
                placeholder="Pilih Tipe"
                options={typeOptions}
                value={selectedType || ''}
                onChange={(selected) => {
                  setSelectedType(selected);
                }}
                required
              />
              <Button variant="dark" onClick={() => setShowTypeForm(true)}>
                <i className="fas fa-plus"></i>
              </Button>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
            Rencana Hasil Kerja <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Elemen"
                value={element}
                onChange={(e) => setElement(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Indikator kinerja Individu <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Indikator"
                value={indicator}
                onChange={(e) => setIndicator(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Satuan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Satuan"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Target <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="number"
                className="form-control"
                placeholder="Target"
                value={target}
                onChange={(e) => setTarget(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Sub Kewenangan</label>
            <div className="col-12 col-sm-8">
              <Select
                isMulti={true}
                placeholder="Pilih Sub Kewenangan"
                options={cascadingOptions}
                value={selectedCascading || ''}
                onChange={(selected) => {
                  setSelectedCascading(selected);
                }}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Kewenangan terpakai
            </label>
            <div className="col-12 col-sm-8 d-flex align-items-center">
              <BSForm.Check
                type="switch"
                id="is-used"
                checked={used}
                onChange={(event) => {
                  setUsed(!used);
                }}
                label={used ? 'Ya' : 'Tidak'}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
        <TypePerformanceForm
          show={showTypeForm}
          reloadData={() => {
            getListTypePerformance(value?.performance_type);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
