import { useContext, useEffect } from 'react';
import Select from 'react-select';
import { periodApi } from '@services/periodApi';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { PeriodContext } from './ResultAchievementEvaluationPage';

function PeriodOptions(props) {
  const apiRequest = useHttpPrivateRequest();

  const { periodOptions, setPeriodOptions, selectedPeriod, setSelectedPeriod } =
    useContext(PeriodContext);

  useEffect(() => {
    const controller = new AbortController();

    const getPeriod = async () => {
      try {
        const request = await apiRequest(periodApi.getYears(controller.signal));

        const response = request?.data;
        const years = response?.data || [];
        const yearOptions = years?.map((year) => {
          return { value: year.id, label: year.period_name };
        });

        setPeriodOptions(yearOptions);
        setSelectedPeriod(yearOptions[0]);
      } catch (error) {}
    };

    getPeriod();

    return () => {
      controller.abort();
    };
  }, [apiRequest, setPeriodOptions, setSelectedPeriod]);

  return (
    selectedPeriod && (
      <Select
        options={periodOptions}
        defaultValue={selectedPeriod}
        onChange={setSelectedPeriod}
      />
    )
  );
}

export default PeriodOptions;
