import { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';
import DownloadAttachement from '@pages/LogBook/DownloadAttachement';

function LogbookList(props) {
  const apiRequest = useHttpPrivateRequest();
  const [abortController, setAbortController] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const value = props.data;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    const monthlyAssessmentId = value?.monthly_assessment_id;

    //for testing
    //const monthlyAssessmentId = '01453a5e-a045-4f25-834e-bf3f585f8925';

    setAbortController(controller);
    getData(monthlyAssessmentId, signal);
    setShow(true);
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '48px',
      cell: (row) => {
        let attachements = row.filename ? row.filename.split(',') : [];
        return (
          attachements.length > 0 && (
            <DropdownButton
              title={<i className="fas fa-download"></i>}
              variant="default"
            >
              <h5 className="text-center text-muted">Download Lampiran</h5>
              <Dropdown.Divider />
              {attachements.map((attachement, index) => {
                return (
                  <DownloadAttachement
                    key={index}
                    label={'Lampiran ...' + attachement.slice(-12)}
                    filename={attachement}
                    type={'dropdown'}
                  />
                );
              })}
            </DropdownButton>
          )
        );
      },
    },
    {
      name: 'Tanggal',
      selector: (row) => row.start_date,
      cell: (row) => (
        <>
          {row.start_date.slice(0, 16)} - {row.end_date.slice(10, 16)}
        </>
      ),
      sortable: true,
      width: '100px',
      hide: 'sm',
    },

    {
      name: 'Judul',
      selector: (row) => row.title,
      cell: (row) => row.title,
      sortable: true,
      maxWidth: '230px',
    },

    {
      name: 'Keterangan',
      selector: (row) => row.detail,
      cell: (row) => row.detail,
      sortable: true,
      hide: 'sm',
    },
    {
      name: 'Kuantitas',
      selector: (row) => row.qty,
      cell: (row) => row.qty,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
    {
      name: 'Waktu',
      selector: (row) => row.timeMinutes,
      cell: (row) => row.timeMinutes,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
  ];

  const getData = async (monthyAssessmentId, signal) => {
    setIsLoading(true);
    try {
      const request = await apiRequest(
        achievementAssessmentApi.getLogBookList(monthyAssessmentId, signal)
      );
      const response = request?.data;
      setData(response?.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      //sementara
      setData([]);
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      contentClassName="shadow"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Catatan Kerja</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" size="sm" variant="dark" />{' '}
            <span className="ms-2">Loading...</span>
          </div>
        ) : (
          <>
            <p>
              Indikator: <br />
              {value?.indicator}
            </p>
            <DataTable
              responsive={false}
              columns={columns}
              data={data}
              pagination
              paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogbookList;
