import { getToken, http, httpStorage } from '@services/api';
import useHttpPrivate from './useHttpPrivate';

const useHttpPrivateRequest = () => {
  const token = getToken();
  const httpPrivate = useHttpPrivate();
  if (token) {
    httpPrivate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return httpPrivate;
};

const useHttpRequest = () => {
  const token = getToken();
  if (token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return http;
};

const useHttpStorageRequest = () => {
  const token = getToken();
  if (token) {
    httpStorage.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return httpStorage;
};

const setHeaderAuthorizaton = (http, token) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export {
  useHttpPrivateRequest,
  useHttpRequest,
  useHttpStorageRequest,
  setHeaderAuthorizaton,
};
