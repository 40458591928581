import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext, PeriodContext } from './AchievementAssessmentPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';

function EditForm(props) {
  const value = props.data;
  const { selectedMonthPeriod } = useContext(PeriodContext);

  const [quality, setQuality] = useState(value?.achievement_quality_value);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);
    setQuality(value?.achievement_quality_value);
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { setList, total, setTotal } = useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      quality: parseFloat(quality),
    };

    try {
      await apiRequest(achievementAssessmentApi.update(payload, signal));

      setIsLoading(false);

      //update datatable
      setList((prevList) => {
        return prevList.map((prev) => {
          if (prev.id === value.id) {
            setTotal((prevTotal) => {
              let resultQuality =
                parseFloat(total.rQuality) -
                parseFloat(prev.achievement_quality_value) +
                parseFloat(quality);
              return { ...prevTotal, rQuality: resultQuality };
            });
            return { ...prev, achievement_quality_value: quality };
          }
          return prev;
        });
      });

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>
          Edit Capaian Kerja {selectedMonthPeriod?.label}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label className="col-sm-3 col-form-label">Indikator</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.indicator}</p>
          </div>
        </div>

        {props?.showCategory && (
          <div className="row">
            <label className="col-sm-3 col-form-label">Kategori</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.category}</p>
            </div>
          </div>
        )}

        <div className="row">
          <label className="col-sm-3 col-form-label">Target Kuantitas</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">{value.target_value} </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_value}{' '}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Target Kualitas</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.target_quality_value}{' '}
            </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_quality_value}{' '}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Target Waktu</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.target_time_value}{' '}
            </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_time_value}{' '}
            </p>
          </div>
        </div>
        <form id="editForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Edit Realisasi Kualitas
            </label>
            <div className="col-12 col-sm-6 col-md-3">
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={quality}
                onChange={(e) => setQuality(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="editForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          Perbarui
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditForm;
