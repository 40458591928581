import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { DataContext } from './SectionPage';
import { sectionApi } from '@services/sectionApi';

function UpdateStatus(props) {
  const value = props.data;

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const isEnableStatus = value?.status === 'ENABLE';

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const { setData } = useContext(DataContext);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        sectionApi.updateStatusByAdmin(value?.id, signal)
      );
      const response = request.data.data;
      setIsLoading(false);

      setData((prevList) => {
        return prevList.map((prev) => {
          if (prev.id === value?.id) {
            return {
              ...prev,
              status: response.status,
            };
          }
          return prev;
        });
      });

      sendSuccessMessage('Berhasil update status');

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>
          {isEnableStatus ? 'Nonaktifkan' : 'Aktifkan'} Jabatan{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEnableStatus ? 'Nonaktifkan' : 'Aktifkan'} Jabatan {value?.section_name}
        . Apakah anda yakin untuk melanjutkan aksi?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          onClick={handleDelete}
          variant={isEnableStatus ? 'danger' : 'success'}
          disabled={isLoading}
        >
          {isLoading
            ? 'Perbarui Status...'
            : isEnableStatus
            ? 'Nonaktifkan'
            : 'Aktifkan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateStatus;
