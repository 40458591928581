import { Link } from 'react-router-dom';
import { useAppSetting } from './../../context/AppSetting';

function SidebarMinifyBtn() {
  const [, dispatch] = useAppSetting();
  return (
    <div className="menu">
      <div className="menu-item d-flex">
        <Link
          to="#"
          className="app-sidebar-minify-btn ms-auto"
          onClick={() => {
            dispatch({ type: 'TOGGLE_APP_SIDEBAR_MINIFY' });
          }}
        >
          <i className="fa fa-angle-double-left"></i>
        </Link>
      </div>
    </div>
  );
}

export default SidebarMinifyBtn;
