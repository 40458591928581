import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { logBookApi } from '@services/logBookApi';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DeleteDialog from './DeleteDialog';
import DownloadAttachement from './DownloadAttachement';
import { sendErrorMessage } from '@utils/notification';

function ViewDetail(props) {
  const id = props?.selectedId;
  const [value, setValue] = useState({});
  const [abortController, setAbortController] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const apiRequest = useHttpPrivateRequest();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const attachments = value.filename ? value.filename.split(',') : [];

  const handleClose = () => {
    setValue({});
    setShow(false);
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleShow = async (e) => {
    setShow(true);
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(logBookApi.viewActivity(id, signal));
      const response = request.data;
      setValue(response.data);
      setIsLoading(false);
    } catch (error) {
      setValue({});
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const deletedIds = [{ id: id }];

  const handleOnDeleted = (deleted) => {
    if (deleted) {
      handleClose();
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Aktivitas</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="row">
              <label className="col-sm-3 col-form-label">Tanggal Mulai</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.start_date}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">Tanggal Selesai</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.end_date}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">Kuantitas</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.qty}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">Waktu</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.timeMinutes}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">Judul</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.title}</p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">Keterangan</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{value.detail}</p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-3 col-form-label">Lampiran</label>
              <div className="col-sm-9">
                {attachments.length > 0 ? (
                  attachments.map((attachment, index) => {
                    return (
                      <p key={index} className="col-form-label fw-normal">
                        <DownloadAttachement
                          label={'Lampiran ...' + attachment.slice(-12)}
                          filename={attachment}
                          type={'button'}
                        />
                      </p>
                    );
                  })
                ) : (
                  <p className="col-form-label fw-normal">-</p>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="me-auto"
          variant="danger"
          onClick={() => {
            setShowDelete(true);
          }}
        >
          Hapus Aktivitas
        </Button>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
      <DeleteDialog
        data={deletedIds}
        show={showDelete}
        onDeleted={handleOnDeleted}
      />
    </Modal>
  );
}

export default ViewDetail;
