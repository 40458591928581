import { BASE_API } from './api';

export const resultAchievementApi = {
  get: (periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-list-result-achievement-by-period-id',
      params: { periodId: periodid },
      signal: signal,
    };
  },
};
