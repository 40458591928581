function InfoLecturerEvaluation(props) {
  const data = props.data;
  return (
    <table className="table">
      <tr>
        <th className="small text-center" scope="col"></th>
        <th className="small text-center" scope="col">
          Realisasi
        </th>
        <th className="small text-center" scope="col">
          Target wajib
        </th>
        <th className="small text-center" scope="col">
          Hasil
        </th>
      </tr>
      {data &&
        data.map((row, index) => {
          return (
            <tr key={index}>
              <td className="small">{row.category}</td>
              <td className="text-center">{row.achievement}</td>
              <td className="text-center">{row.target}</td>
              <td className="text-center">{row.result}</td>
            </tr>
          );
        })}
    </table>
  );
}

export default InfoLecturerEvaluation;
