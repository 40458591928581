/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Select from 'react-select';
import { periodApi } from '@services/periodApi';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { PeriodContext } from './AchievementAssessmentVerificationPage';
// import { achievementAssessmentApi } from '@services/achievementAssessmentApi';

function PeriodOptions(props) {
  const apiRequest = useHttpPrivateRequest();

  const {
    periodOptions,
    setPeriodOptions,
    selectedPeriod,
    setSelectedPeriod,
    monthPeriodOptions,
    setMonthPeriodOptions,
    selectedMonthPeriod,
    setSelectedMonthPeriod,
    departmentOptions,
    setDepartmentOptions,
    selectedDepartment,
    setSelectedDepartment,
  } = useContext(PeriodContext);

  useEffect(() => {
    const controller = new AbortController();

    const getPeriod = async () => {
      try {
        const request = await apiRequest(periodApi.getYears(controller.signal));

        const response = request?.data;
        const years = response?.data || [];
        const yearOptions = years.map((year) => {
          return { value: year.id, label: year.period_name };
        });

        setPeriodOptions(yearOptions);
        setSelectedPeriod(yearOptions[0]);

        setMonthPeriodOptions([]);
        setSelectedMonthPeriod({});

        setDepartmentOptions([]);
        setSelectedDepartment({});
      } catch (error) {}
    };

    getPeriod();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    const getMonthPeriod = async (parentId) => {
      try {
        const request = await apiRequest(
          periodApi.getMonths(parentId, controller.signal)
        );

        const response = request?.data;
        const data = response?.data || [];
        const monthOptions = data.map((month) => {
          return { value: month.id, label: month.period };
        });

        setMonthPeriodOptions(monthOptions);
        setSelectedMonthPeriod(monthOptions[0]);

        setDepartmentOptions([]);
        setSelectedDepartment({});
      } catch (error) {}
    };

    selectedPeriod && getMonthPeriod(selectedPeriod?.value);

    return () => {
      controller.abort();
    };
  }, [selectedPeriod]);

  useEffect(() => {
    const controller = new AbortController();

    const getDepartmentList = async (periodId) => {
      try {
        // const request = await apiRequest(
        //   achievementAssessmentApi.getDepartmentList(
        //     periodId,
        //     controller.signal
        //   )
        // );
        // const response = request?.data;
        // const data = response?.data || [];
        // const options = data.map((month) => {
        //   return { value: month.id, label: month.period };
        // });

        const options = [{ value: 'ALL', label: 'Semua Departemen' }];

        setDepartmentOptions(options);
        setSelectedDepartment(options[0]);
      } catch (error) {}
    };

    selectedMonthPeriod && getDepartmentList(selectedMonthPeriod?.value);

    return () => {
      controller.abort();
    };
  }, [selectedMonthPeriod]);

  return (
    selectedPeriod && (
      <>
        <Select
          options={periodOptions}
          defaultValue={selectedPeriod}
          onChange={setSelectedPeriod}
        />
        <Select
          className="mt-3"
          options={monthPeriodOptions}
          value={selectedMonthPeriod || ''}
          onChange={setSelectedMonthPeriod}
          isDisabled={!selectedMonthPeriod}
        />
        {/* <Select
          className="mt-3"
          options={departmentOptions}
          value={selectedDepartment || ''}
          onChange={setSelectedDepartment}
          isDisabled={!selectedMonthPeriod}
        /> */}
      </>
    )
  );
}

export default PeriodOptions;
