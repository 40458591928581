import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext, PeriodContext } from './AssessmentComponentPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { assessmentComponentApi } from '@services/assessmentComponentApi';

function SubmitContractButton(props) {
  const { periodid, selectedMonthPeriod } = useContext(PeriodContext);
  const { data, reloadData } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);
    setShow(true);
  };

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      await apiRequest(assessmentComponentApi.submit(data.id, signal));

      setIsLoading(false);

      sendSuccessMessage('Berhasil submit Beban Kerja');
      reloadData(periodid, signal);
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Button title="Submit" variant="success" onClick={handleShow}>
        <i className="fas fa-paper-plane"></i>{' '}
        <span className="d-none d-md-inline-block">Submit Data</span>
      </Button>

      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Submit Data Beban Kerja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Anda yakin akan submit data Beban Kerja {selectedMonthPeriod?.label}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Batal
          </Button>
          <Button onClick={handleSubmit} variant="success" disabled={isLoading}>
            {isLoading ? 'Submit...' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default SubmitContractButton;
