/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { DataContext, PeriodContext } from './SalaryReportPage';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import SubmitDialog from './SubmitDialog';

import '@assets/override-table.css';
import ViewDetail from './ViewDetail';
import GenerateDialog from './GenerateDialog';
import { formatRupiah } from '@utils/common';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { salaryReportApi } from '@services/salaryReportApi';

function DataList(props) {
  const { parentid, periodid, periodName } = useContext(PeriodContext);

  const {
    data,
    reloadData,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalItems,
    filterParams,
    mergeFilters,
    loading,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showGenerate, setShowGenerate] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [querySearch, setQuerySearch] = useState('');
  const [sort, setSort] = useState('asc');

  const apiRequest = useHttpPrivateRequest();
  const [abortController, setAbortController] = useState(null);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

  // Load the convertToLocalTime function

  useEffect(() => {
    showGenerate && setShowGenerate(false);
  }, [showGenerate]);

  useEffect(() => {
    showSubmit && setShowSubmit(false);
  }, [showSubmit]);

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    mergeFilters({
      page: page,
      pageSize: pageSize,
      q: querySearch,
    });
    (parentid || periodid) && reloadData(periodid, filterParams, signal);

    return () => {
      controller.abort();
    };
  }, [periodid, page, pageSize, querySearch, sort]);

  const handleSearch = (e) => {
    setQuerySearch(e.target.value);
    mergeFilters({ q: e.target.value });
  };

  const handleSort = (column, sortDirection) => {
    if (column.sortField) {
      mergeFilters({
        order: column.sortField,
        sort: sortDirection === 'asc' ? 'ASC' : 'DESC',
      });

      setSort(sortDirection);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    mergeFilters({ page: page });
  };

  const handleRowsPerPageChange = (newPageSize, page) => {
    setPageSize(newPageSize);
    setPage(page); // To reset to the first page when changing the page size
    mergeFilters({ page: page, pageSize: newPageSize });
  };

  const downloadPDF = async (e) => {
    setIsDownloadingPDF(true);

    try {
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      const response = await apiRequest(
        salaryReportApi.downloadPDFByPeriod(periodid, signal)
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;

      const fileName = 'laporan Gaji Periode ' + periodName + '.pdf';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);

      setIsDownloadingPDF(false);
    } catch (error) {
      setIsDownloadingPDF(false);
    }
  };

  const downloadExcel = async (e) => {
    setIsDownloadingExcel(true);

    try {
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      const response = await apiRequest(
        salaryReportApi.downloadExcelByPeriod(periodid, signal)
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;

      const fileName = 'laporan Gaji Periode ' + periodName + '.xlsx';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);

      setIsDownloadingExcel(false);
    } catch (error) {
      setIsDownloadingExcel(false);
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 2400);
    };

    // Initial check for small screen
    handleResize();

    // Listen for window resize events and update state accordingly
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <Button
          size="sm"
          variant="default"
          onClick={() => {
            setSelectedRow(row);
            setShowDetail(true);
          }}
        >
          <i className="fas fa-circle-info"></i>
        </Button>
      ),
    },
    {
      name: 'Periode',
      selector: (row) => row.periode_name,
      cell: (row) => row.periode_name,
      sortable: false,
    },

    {
      name: 'NIP',
      selector: (row) => row.employee_gen,
      cell: (row) => row.employee_gen,
      sortable: false,
      maxWidth: '100px',
      hide: 'md',
    },
    {
      name: 'Nama',
      selector: (row) => row.employee_name,
      cell: (row) => row.employee_name,
      sortable: false,
    },

    {
      name: 'Departemen',
      selector: (row) => row.department_name,
      cell: (row) => row.department_name,
      sortable: false,
      hide: 'sm',
    },

    {
      name: 'Gaji',
      selector: (row) => row.salary,
      cell: (row) => formatRupiah(row.salary),
      sortable: false,
      width: '150px',
    },

    {
      name: 'Potongan Presensi',
      selector: (row) => row.attendance_deduction,
      cell: (row) => formatRupiah(row.attendance_deduction),
      sortable: false,
      width: '100px',
      hide: 'lg',
    },

    {
      name: 'Potongan Capaian',
      selector: (row) => row.achievement_deduction,
      cell: (row) => formatRupiah(row.achievement_deduction),
      sortable: false,
      width: '100px',
      hide: 'lg',
    },

    {
      name: 'PPN',
      selector: (row) => row.tax,
      cell: (row) => formatRupiah(row.tax),
      sortable: false,
      width: '90px',
      hide: 'lg',
    },
  ];

  return (
    <>
      {periodid && (
        <div className="mb-3 d-sm-flex justify-content-between align-items-center gap-3">
          <div className="d-flex gap-3 mb-3 mb-sm-0 justify-content-between gap-3 flex-fill">
            <div className="d-flex gap-3 ">
              <button
                className="btn btn-primary "
                disabled={Array.isArray(data) && data.length ? true : false}
                onClick={() => setShowGenerate(true)}
              >
                <i className="fas fa-refresh me-2"></i>{' '}
                <span className="d-none d-md-inline-block">Generate</span>
              </button>

              <button
                className="btn btn-success "
                disabled={data && data[0]?.status !== 'PENDING' ? true : false}
                onClick={() => setShowSubmit(true)}
              >
                <i className="fas fa-paper-plane me-2"></i>{' '}
                <span className="d-none d-md-inline-block">Submit</span>
              </button>
            </div>
            <div className="d-flex gap-3 flex-row-reverse">
              <Button
                onClick={() => {
                  downloadPDF();
                }}
                variant="danger"
                disabled={
                  (Array.isArray(data) && data.length === 0) || isDownloadingPDF
                }
              >
                <i className="fa fa-file-pdf"></i>{' '}
                <span className="d-none d-md-inline-block">
                  {isDownloadingPDF ? 'Downloading...' : 'Download PDF'}
                </span>
              </Button>
              <Button
                onClick={() => {
                  downloadExcel();
                }}
                variant="success"
                disabled={
                  (Array.isArray(data) && data.length === 0) ||
                  isDownloadingExcel
                }
              >
                <i className="fa fa-file-excel"></i>{' '}
                <span className="d-none d-md-inline-block">
                  {isDownloadingExcel ? 'Downloading...' : 'Download Excel'}
                </span>
              </Button>
            </div>
          </div>

          <div className="d-flex text-right">
            <input
              type="text"
              style={{ minWidth: '280px' }}
              className="form-control"
              placeholder="Cari NIP/Nama Pegawai..."
              value={querySearch}
              onChange={handleSearch}
            />
          </div>
        </div>
      )}

      <GenerateDialog show={showGenerate} />

      <SubmitDialog show={showSubmit} />

      <ViewDetail data={selectedRow} show={showDetail} />

      <DataTable
        responsive={true}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
        paginationServer
        paginationTotalRows={totalItems}
        paginationPerPage={pageSize}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onSort={handleSort}
        // paginationComponentOptions={{
        //   noRowsPerPage: true,
        // }}
        progressPending={loading}
        expandableRows={isSmallScreen}
        expandableRowsComponent={(row) => {
          return (
            <>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Periode
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.periode_name}
                  </p>
                </div>
              </div>
              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  NIP
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.employee_gen}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Nama Pegawai
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.employee_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Departemen
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {row.data.department_name}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Gaji
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatRupiah(row.data.salary)}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Potongan Presensi
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatRupiah(row.data.attendance_deduction)}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  Potongan Capaian
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatRupiah(row.data.achievement_deduction)}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-6 col-md-4 col-form-label lh-1">
                  PPN
                </label>
                <div className="col-auto col-md-8">
                  <p className="col-form-label fw-normal lh-1">
                    {formatRupiah(row.data.tax)}
                  </p>
                </div>
              </div>
            </>
          );
        }}
      />
    </>
  );
}

export default DataList;
