function CommentLabel({ comment, status }) {
  let bgColor = 'secondary';

  if (status === 'APPROVED') {
    bgColor = 'success';
  } else if (status === 'REJECTED') {
    bgColor = 'danger';
  } else if (status === 'APPROVAL') {
    bgColor = 'warning';
  }

  return (
    <div
      className={'alert alert-dismissible fade show alert-' + bgColor}
      role="alert"
    >
      <h5 className="alert-heading">Komentar Atasan Langsung</h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
      <hr />
      <p>{comment}</p>
    </div>
  );
}

export default CommentLabel;
