function StatusLabel({ status, customLabel = '' }) {
  if (!status) return '';

  let bgColor = 'secondary';
  let label = status;

  status = status.toUpperCase();
  if (status === 'VERIFIED') {
    bgColor = 'info';
    label = 'Diverifikasi';
  } else if (status === 'APPROVED') {
    bgColor = 'success';
    label = 'Disetujui';
  } else if (status === 'REJECTED') {
    bgColor = 'danger';
    label = 'Ditolak';
  } else if (status === 'APPROVAL') {
    bgColor = 'warning';
    label = 'Diajukan';
  } else if (status === 'EMPTY') {
    bgColor = 'secondary';
    label = 'Belum Isi';
  } else if (status === 'DRAFT') {
    bgColor = 'secondary';
    label = 'Draf';
  } else if (status === 'ENABLE' || status === 'Y') {
    bgColor = 'success';
    label = 'Aktif';
  } else if (status === 'DISABLE' || status === 'N') {
    bgColor = 'danger';
    label = 'Nonaktif';
  }

  return (
    <span
      className={'badge bg-' + bgColor}
      title={customLabel ? customLabel : 'Status ' + label}
    >
      {customLabel ? customLabel : label}
    </span>
  );
}

export default StatusLabel;
