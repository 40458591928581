import { Link, useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import useLogout from '@hooks/useLogout';

function DropdownProfile() {
  const { auth } = useAuth();

  const loggedInUser = auth?.loggedInUser;

  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div className="navbar-item navbar-user dropdown">
      <a
        href="#/"
        className="navbar-link dropdown-toggle d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <div className="image image-icon bg-gray-800 text-gray-600">
          <i className="fa fa-user"></i>
        </div>
        <span>
          <span className="d-none d-md-inline">{loggedInUser?.fullname}</span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        {/* <a href="#/" className="dropdown-item">
          Edit Profile
        </a>
        <a href="#/" className="dropdown-item d-flex align-items-center">
          Inbox
          <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
        </a>
        <a href="#/" className="dropdown-item">
          Calendar
        </a> */}

        <Link to="/change-password" className="dropdown-item">
          <i className="fas fa-key me-2"></i> Ganti Password
        </Link>
        <div className="dropdown-divider"></div>
        <button onClick={handleLogout} className="dropdown-item">
          <i className="fas fa-door-open me-2"></i> Log Out
        </button>
      </div>
    </div>
  );
}

export default DropdownProfile;
