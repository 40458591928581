import { ADMIN_API } from './api';

export const userRoleApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-role-user',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'user_name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  getListRoleByAdmin: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-role-user',
      signal,
    };
  },

  getListActiveUserByAdmin: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-users',
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-role-user',
      data: { roleId: payload.roleId, userId: payload.userId },
      signal,
    };
  },

  deleteByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/delete-role-user',
      data: { roleId: payload.roleId, userId: payload.userId },
      signal,
    };
  },
};
