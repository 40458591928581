import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSetting } from './../../context/AppSetting';

const Content = () => {
  const [state] = useAppSetting();
  return (
    <div className={'app-content ' + state.appContentClass}>
      <Outlet />
    </div>
  );
};

export default Content;
