import { createContext, useState } from 'react';
import Page from '@components/page/page';
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from '@components/panel/panel';
import PeriodOptions from './PeriodOptions';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import EmployeeDataList from './EmployeeDataList';
import { employeeOverviewApi } from '@services/employeeOverviewApi ';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function EmployeeStatusOverviewPage(props) {
  const [title] = useState('Status Pegawai');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Verifikasi' },
    { title },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [monthPeriodOptions, setMonthPeriodOptions] = useState([]);
  const [selectedMonthPeriod, setSelectedMonthPeriod] = useState(null);

  const parentid = selectedPeriod?.value;
  const periodid = selectedMonthPeriod?.value;

  const [employeeData, setEmployeeData] = useState([]);

  const apiRequest = useHttpPrivateRequest();

  const reloadEmployeeData = async (periodid, signal) => {
    try {
      const request = await apiRequest(
        employeeOverviewApi.get(periodid, signal)
      );
      const response = request?.data;
      setEmployeeData(response?.data || []);
    } catch (error) {
      setEmployeeData([]);
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          monthPeriodOptions,
          setMonthPeriodOptions,
          selectedMonthPeriod,
          setSelectedMonthPeriod,
          parentid,
          periodid,
        }}
      >
        <Panel>
          <PanelHeader>Periode </PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            employeeData,
            setEmployeeData,
            reloadEmployeeData,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-4 d-flex justify-content-end align-items-start mb-3 gap-3"></div>
              </div>
              <EmployeeDataList />
            </PanelBody>
            <PanelFooter>
              <div className="row">
                <div className="col">
                  <div className="fs-3 m-3 text-center"></div>
                </div>

                <div className="col-md-10 "></div>
              </div>
            </PanelFooter>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default EmployeeStatusOverviewPage;
