import { useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';

import StatusLabel from '@components/label/StatusLabel';
import { DataContext, PeriodContext } from './EmployeeStatusOverviewPage';

function EmployeeDataList(props) {
  const columns = [
    {
      button: true,
      name: 'Status BK',
      selector: (row) => row.status,
      cell: (row) => (
        <div className="fs-4 text-center w-100">
          <StatusLabel status={row.status_bk} />
        </div>
      ),
      sortable: true,
      hide: 'md',
      width: '120px',
    },
    {
      button: true,
      name: 'Status CK',
      selector: (row) => row.status_ck,
      cell: (row) => (
        <div className="fs-4 text-center w-100">
          <StatusLabel status={row.status_ck} />
        </div>
      ),
      sortable: true,
      hide: 'md',
      width: '120px',
    },

    {
      name: 'Pegawai',
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <div className="d-none d-md-inline-block">
            <div className="widget-icon widget-icon-md user rounded float-start me-5px mb-5px bg-gray-500 text-white">
              <i className="fa fa-user"></i>
            </div>
          </div>
          <div>
            <strong>{row.name}</strong>
            <br />
            {row.nip && 'NIP: ' + row.nip}
          </div>
        </div>
      ),
      sortable: true,
      minWidth: '120px',
    },
    // {
    //   name: 'NIP',
    //   selector: (row) => row.nip,
    //   cell: (row) => row.nip,
    //   sortable: true,
    //   width: 'auto',
    //   maxWidth: '160px',
    //   hide: 'md',
    // },
    {
      name: 'Divisi',
      selector: (row) => row.division_name,
      cell: (row) => row.division_name,
      sortable: true,
      width: 'auto',
      maxWidth: '230px',
      hide: 'md',
    },
  ];

  const { parentid, periodid } = useContext(PeriodContext);
  const { employeeData, reloadEmployeeData } = useContext(DataContext);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (parentid || periodid) && reloadEmployeeData(periodid, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  return (
    <DataTable
      responsive={false}
      columns={columns}
      data={employeeData}
      dense
      pagination
      paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
      expandableRows
      expandableRowsComponent={(row) => {
        return (
          <>
            <div className="row">
              <label className="col-sm-2 col-form-label">Nama Pegawai</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{row.data.name}</p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2 col-form-label">NIP</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">{row.data.nip}</p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2 col-form-label">Divisi</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">
                  {row.data.division_name}
                </p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2 col-form-label">Status BK</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">
                  <StatusLabel status={row.data.status_bk} />
                </p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2 col-form-label">Status CK</label>
              <div className="col-sm-9">
                <p className="col-form-label fw-normal">
                  <StatusLabel status={row.data.status_ck} />
                </p>
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default EmployeeDataList;
