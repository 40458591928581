import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DataList from './DataList';
import { performanceApi } from '@services/performanceApi';

export const DataContext = createContext({});

function PerformancePage(props) {
  const [title] = useState('Data Uraian Tugas');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Data Master' },
    { title },
  ]);

  const [data, setData] = useState();
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items from the server
  const [filterParams, setFilterParams] = useState({}); // Total number of items from the server

  const mergeFilters = (newData) => {
    setFilterParams((prevData) => ({
      ...prevData, // Spread the previous state
      ...newData, // Spread the new data
    }));
  };

  const [loading, setLoading] = useState(true);

  const apiRequest = useHttpPrivateRequest();

  const reloadData = async (params, signal) => {
    try {
      setLoading(true);
      const request = await apiRequest(
        performanceApi.getListByAdmin(params, signal)
      );

      const response = request?.data;

      setData(response.data);
      setTotalItems(response.meta.count);

      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <DataContext.Provider
        value={{
          data,
          setData,
          reloadData,
          page,
          setPage,
          pageSize,
          setPageSize,
          totalItems,
          setTotalItems,
          filterParams,
          setFilterParams,
          mergeFilters,
          loading,
          setLoading,
        }}
      >
        <Panel>
          <PanelHeader>Panel Data</PanelHeader>
          <PanelBody>
            <DataList />
          </PanelBody>
        </Panel>
      </DataContext.Provider>
    </Page>
  );
}

export default PerformancePage;
