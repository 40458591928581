import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { contractAssessmentApi } from '@services/contractAssessmentApi';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DataTable from 'react-data-table-component';
import { DataContext, PeriodContext } from './ContractAssessmentPage';

function AddAdditionalTaskButton(props) {
  const [showModal, setShowModal] = useState(false);

  const { reloadData } = useContext(DataContext);
  const { periodid } = useContext(PeriodContext);

  const [filterText, setFilterText] = useState('');

  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const apiRequest = useHttpPrivateRequest();

  const intialState = () => {
    setIsLoading(false);
    setData([]);
    setSelectedRows([]);
    setFilterText('');
  };

  const handleShow = () => {
    intialState();
    getData();
    setShowModal(true);
  };
  const handleClose = (e) => {
    intialState();
    setShowModal(false);
  };

  const handleRowSelected = (rows) => {
    !isLoading && setSelectedRows(rows.selectedRows);
  };

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const request = await apiRequest(
        contractAssessmentApi.getListPerformance(signal)
      );
      const response = request?.data;
      setData(response?.data);
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleAddData = async () => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      const contactIds = selectedRows.map((row) => {
        return row.id;
      });

      const response = await apiRequest(
        contractAssessmentApi.addAdditionalContract(
          periodid,
          contactIds,
          signal
        )
      );

      reloadData(periodid, signal);

      sendSuccessMessage(
        response?.data?.message || 'Data berhasil ditambahkan'
      );
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const filteredData = data.filter((item) => {
    return (
      item.cascading.toLowerCase().includes(filterText.toLowerCase()) ||
      item.program.toLowerCase().includes(filterText.toLowerCase()) ||
      item.indicator.toLowerCase().includes(filterText.toLowerCase()) ||
      item.unit.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  useEffect(() => {
    if (filterText) {
      setData(data.filter((item) => {}));
    }
  }, []);

  const handleSearch = function (e) {
    setFilterText(e.target.value);
  };

  const columns = [
    {
      name: 'Cascading Indikator',
      selector: (row) => row.cascading,
      cell: (row) => row.cascading,
      hide: 'md',
    },
    {
      name: 'Program',
      selector: (row) => row.program,
      cell: (row) => row.program,
      sortable: true,
    },
    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => row.indicator,
      hide: 'md',
    },
    {
      name: 'Satuan',
      selector: (row) => row.unit,
      cell: (row) => row.unit,
      width: '90px',
      hide: 'md',
    },
    {
      name: 'T.T',
      selector: (row) => row.targetYear,
      cell: (row) => row.targetYear,
      width: '70px',
      hide: 'md',
    },
    {
      name: 'T.S',
      selector: (row) => row.targetSemester,
      cell: (row) => row.targetSemester,
      width: '70px',
      hide: 'md',
    },
    {
      name: 'T.B',
      selector: (row) => row.targetMonth,
      cell: (row) => row.targetMonth,
      width: '70px',
      hide: 'md',
    },
  ];

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow} title="Tugas Tambahan">
        <i className="fas fa-plus"></i>{' '}
        <span className="d-none d-md-inline-block">Tugas Tambahan</span>
      </Button>

      <Modal
        show={showModal}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Tambah Kontrak Kerja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Cari  Cascading / Program / Indikator"
              value={filterText}
              onChange={handleSearch}
            />
          </div>

          <DataTable
            responsive={false}
            keyField="id"
            progressPending={isLoading}
            selectableRows
            selectedRows={selectedRows}
            onSelectedRowsChange={handleRowSelected}
            selectableRowDisabled={(row) => {
              return isLoading;
            }}
            columns={columns}
            data={filteredData ?? []}
            expandableRows
            expandableRowsComponent={(row) => {
              return (
                <>
                  {row?.data?.cascading && (
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Cascading
                      </label>
                      <div className="col-sm-9">
                        <p className="col-form-label fw-normal">
                          {row?.data?.cascading}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Program</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.program}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Indikator</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.indicator}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">Satuan</label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.unit}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Tahun (T.T)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.targetYear}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Semester (T.S)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.targetSemester}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Target Bulan (T.B)
                    </label>
                    <div className="col-sm-9">
                      <p className="col-form-label fw-normal">
                        {row?.data?.targetMonth}{' '}
                      </p>
                    </div>
                  </div>
                </>
              );
            }}
            pagination
            paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="default" onClick={handleClose} disabled={isLoading}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={handleAddData}
            disabled={isLoading || selectedRows.length === 0}
          >
            {isLoading ? 'Menambahkan Data...' : 'Tambahkan Data'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default AddAdditionalTaskButton;
