import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Page = (props) => {
  document.title = props.title + ' - Siremun';

  function isLast(index) {
    return index === props.breadcrumb.length - 1;
  }

  if (props.blankPage) return <Fragment>{props.children}</Fragment>;

  return (
    <>
      {props.breadcrumb && (
        <ol className="breadcrumb float-xl-end">
          {props.breadcrumb.map((crumb, ci) => {
            const disabled = isLast(ci) ? 'active' : '';
            return (
              <li key={ci} className={`breadcrumb-item ${disabled}`}>
                {disabled ? (
                  crumb.title
                ) : (
                  <Link to={crumb.url}>{crumb.title}</Link>
                )}
              </li>
            );
          })}
        </ol>
      )}

      {!props.hideTitle && (
        <h1 className="page-header">
          {props.title} {props.subtitle && <small>{props.subtitle}</small>}
        </h1>
      )}

      {props.children}
    </>
  );
};

export default Page;
