import { useContext, useEffect } from 'react';
import Select from 'react-select';
import { periodApi } from '@services/periodApi';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { PeriodContext } from './AssessmentComponentEvaluationPage';

function PeriodOptions(props) {
  const apiRequest = useHttpPrivateRequest();

  const {
    periodOptions,
    setPeriodOptions,
    selectedPeriod,
    setSelectedPeriod,
    monthPeriodOptions,
    setMonthPeriodOptions,
    selectedMonthPeriod,
    setSelectedMonthPeriod,
  } = useContext(PeriodContext);

  useEffect(() => {
    const controller = new AbortController();

    const getPeriod = async () => {
      try {
        const request = await apiRequest(periodApi.getYears(controller.signal));

        const response = request?.data;
        const years = response?.data || [];
        const yearOptions = years.map((year) => {
          return { value: year.id, label: year.period_name };
        });

        setPeriodOptions(yearOptions);
        setSelectedPeriod(yearOptions[0]);

        setMonthPeriodOptions([]);
        setSelectedMonthPeriod({});
      } catch (error) {}
    };

    getPeriod();

    return () => {
      controller.abort();
    };
  }, [
    apiRequest,
    setPeriodOptions,
    setSelectedPeriod,
    setMonthPeriodOptions,
    setSelectedMonthPeriod,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    const getMonthPeriod = async (parentId) => {
      try {
        const request = await apiRequest(
          periodApi.getMonths(parentId, controller.signal)
        );

        const response = request?.data;
        const data = response?.data || [];
        const monthOptions = data.map((month) => {
          return { value: month.id, label: month.period };
        });

        setMonthPeriodOptions(monthOptions);
        setSelectedMonthPeriod(monthOptions[0]);
      } catch (error) {}
    };

    selectedPeriod && getMonthPeriod(selectedPeriod?.value);

    return () => {
      controller.abort();
    };
  }, [
    apiRequest,
    setMonthPeriodOptions,
    setSelectedMonthPeriod,
    selectedPeriod?.value,
    selectedPeriod,
  ]);

  return (
    selectedPeriod && (
      <>
        <Select
          options={periodOptions}
          defaultValue={selectedPeriod}
          onChange={setSelectedPeriod}
        />
        <Select
          className="mt-3"
          options={monthPeriodOptions}
          value={selectedMonthPeriod || ''}
          onChange={setSelectedMonthPeriod}
          isDisabled={!selectedMonthPeriod}
          isClearable
        />
      </>
    )
  );
}

export default PeriodOptions;
