import { BASE_API } from './api';

export const assessmentComponentApi = {
  get: (periodid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-monthly-assessment/' + periodid,
      signal: signal,
    };
  },
  generate: (periodid, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/generate-monthly-assessment/' + periodid,
      signal: signal,
    };
  },

  update: (payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/update-monthly-assessment',
      data: {
        id: payload.id,
        targetValue: payload.target,
        qualityValue: payload.quality,
        timeValue: payload.time,
      },
      signal: signal,
    };
  },
  delete: (ids, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/delete-monthly-assessment',
      data: { id: ids },
      signal: signal,
    };
  },

  addAssessment: (periodId, contractIds, signal) => {
    return {
      method: 'post',
      data: {
        periodid: periodId,
        contractIds: contractIds,
      },
      url: BASE_API + '/v1/add-monthly-assesment',
      signal: signal,
    };
  },

  submit: (id, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/submit-monthly-assessment',
      data: { id: id },
      signal: signal,
    };
  },

  getEmployees: (periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API +
        '/v1/get-list-employee-monthly-assessment-by-manager-id/' +
        periodid,
      signal: signal,
    };
  },
  getEmployeeAssessmentComponent: (employeeid, periodid, signal) => {
    return {
      method: 'get',
      url:
        BASE_API + '/v1/get-list-monthly-assessment-by-period-id-employee-id',
      params: { employeeId: employeeid, periodId: periodid },
      signal: signal,
    };
  },
  updateStatus: (data, signal) => {
    //status = APPROVED/REJECTED
    return {
      method: 'post',
      url: BASE_API + '/v1/update-status-contract-assessment-by-manager',
      data: {
        employeeId: data.employeeId,
        periodId: data.periodId,
        comment: data.comment,
        status: data.status,
      },
      signal: signal,
    };
  },
};
