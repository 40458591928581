import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DataContext,
  PeriodContext,
} from './AchievementAssessmentVerificationPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';
import { Button, Modal } from 'react-bootstrap';
import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import '@assets/override-table.css';

function CancelApproval(props) {
  const { selectedMonthPeriod } = useContext(PeriodContext);

  const [abortController, setAbortController] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const value = props.data;
  const employeeid = value?.id;

  const [data, setData] = useState();
  const apiRequest = useHttpPrivateRequest();

  const [show, setShow] = useState(false);

  const [comment, setComment] = useState();

  const [apraisalResultLeader, setApraisalResultLeader] = useState('');

  const { setEmployeeData } = useContext(DataContext);

  const getData = async (employeeid, periodid, signal) => {
    setIsLoading(true);
    try {
      const request = await apiRequest(
        achievementAssessmentApi.getEmployeeAchievementAssessmentForVerify(
          employeeid,
          periodid,
          signal
        )
      );
      const response = request?.data;

      setData(response?.data || []);
      setComment('');
      setApraisalResultLeader(response?.data?.result_apraisal_leader);
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setComment('');
      setApraisalResultLeader('');
      setIsLoading(false);
    }
  };

  const intitialState = (periodid) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    getData(employeeid, periodid, signal);
  };

  const handleClose = () => {
    setData([]);
    setComment('');
    setApraisalResultLeader('');
    setShow(false);
    props.onClose(true);
  };

  const handleShow = () => {
    intitialState(selectedMonthPeriod?.value);
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const verify = async () => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const payload = {
      id: data.id,
      apraisalResult: apraisalResultLeader,
      comment,
      status: 'REJECTED',
    };

    try {
      await apiRequest(achievementAssessmentApi.updateStatus(payload, signal));

      setData((prevData) => {
        return {
          ...prevData,
          comment,
          result_apraisal_leader: apraisalResultLeader,
        };
      });

      //update status in datatable
      setEmployeeData((prevData) => {
        return prevData.map((prev) => {
          if (prev.id === value.id) {
            return {
              ...prev,
              apraisal_result: apraisalResultLeader,
              status: 'REJECTED',
            };
          }
          return prev;
        });
      });

      sendSuccessMessage('Berhasil update status');
      handleClose();

      setIsLoading(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    verify();
  };

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pembatalan Persetujuan Capaian Kerja</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-6 mt-3 mb-3">
                      <div>
                        <h5>Nama Pegawai</h5>
                        <p className="col-form-label fw-normal">
                          {value?.name}
                        </p>
                      </div>
                    </div>

                    <div className="col-sm-6 mt-3 mb-3">
                      <div>
                        <h5>Periode</h5>
                        <p className="col-form-label fw-normal">
                          {selectedMonthPeriod?.label}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12  mt-4 mb-3">
                      <div>
                        <h5>
                          Alasan Pembatalan
                          <sup className="text-danger">*</sup>
                        </h5>
                        <p className="col-form-label fw-normal">
                          <textarea
                            className="form-control"
                            placeholder="Komentar alasan pembatalan wajib diisi"
                            rows="3"
                            onChange={(e) => {
                              return setComment(e.target.value);
                            }}
                            value={comment}
                            required
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center gap-3">
            <Button variant="default" onClick={handleClose}>
              <i className="fas fa-arrow-left me-2"></i> Kembali
            </Button>
            <Button variant="danger" onClick={handleCancel}>
              <i className="fas fa-close me-2"></i> Batalkan Persetujuan
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default CancelApproval;
