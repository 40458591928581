import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { salaryReportApi } from '@services/salaryReportApi';
import { PeriodContext } from './SalaryReportPage';
import { formatRupiah } from '@utils/common';

function ViewDetail(props) {
  const value = props.data;

  const { periodid, periodName } = useContext(PeriodContext);

  const [data, setData] = useState('');

  const [totalSalary, setTotalSalary] = useState(0);
  const [totalSalaryWithDeductions, setTotalSalaryWithDeductions] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setIsLoading(false);
    reloadDetailData(periodid, value.employee_id);
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const reloadDetailData = async (periodid, employeeid) => {
    try {
      setIsLoading(true);
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      const request = await apiRequest(
        salaryReportApi.getDetail(periodid, employeeid, signal)
      );

      const resData = request?.data?.data;
      setData(resData);
      setTotalSalary(parseFloat(resData.salary));

      setTotalSalaryWithDeductions(
        parseFloat(resData.salary) -
          parseFloat(resData.attendance_deduction) -
          parseFloat(resData.achievement_deduction) -
          parseFloat(resData.tax)
      );

      setIsLoading(false);
    } catch (error) {
      setData('');
      setIsLoading(false);
    }
  };

  const downloadPDF = async (e) => {
    setIsDownloadingPDF(true);

    try {
      const controller = new AbortController();
      const signal = controller.signal;
      setAbortController(controller);

      const response = await apiRequest(
        salaryReportApi.downloadPDFByEmployee(
          periodid,
          value.employee_id,
          signal
        )
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;

      const fileName = 'laporan Gaji Periode ' + periodName + '.pdf';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);

      setIsDownloadingPDF(false);
    } catch (error) {
      setIsDownloadingPDF(false);
    }
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      keyboard={false}
      contentClassName="shadow"
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Detail Informasi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">Periode</label>
          <div className="col-sm-9">
            <p className="col-form-label pb-0 pt-0 fw-normal">
              {value?.periode_name}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Nama Pegawai
          </label>
          <div className="col-sm-9">
            <p className="col-form-label pb-0 pt-0 fw-normal">
              {value?.employee_name}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">NIP</label>
          <div className="col-sm-9">
            <p className="col-form-label pb-0 pt-0 fw-normal">
              {value?.employee_gen}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Departemen
          </label>
          <div className="col-sm-9">
            <p className="col-form-label pb-0 pt-0 fw-normal">
              {value?.department_name}
            </p>
          </div>
        </div>
        <hr />
        <h5>Presensi</h5>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">Terlambat</label>
          <div className="col-sm-9">
            <div className="col-form-label pb-0 pt-0 fw-normal">
              <div className="row">
                <div className="col">
                  0.50% : {data?.late_attendance?.lte_30_minute || '-'}
                </div>
                <div className="col">
                  1% : {data?.late_attendance?.lte_60_minute || '-'}
                </div>
                <div className="col">
                  1.25% : {data?.late_attendance?.lte_90_minute || '-'}
                </div>
                <div className="col">
                  1.50% : {data?.late_attendance?.mt_90_minute || '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Pulang Cepat
          </label>
          <div className="col-sm-9">
            <div className="col-form-label pb-0 pt-0 fw-normal">
              <div className="row">
                <div className="col">
                  0.50% : {data?.home_early?.lte_30_minute || '-'}
                </div>
                <div className="col">
                  1% : {data?.home_early?.lte_60_minute || '-'}
                </div>
                <div className="col">
                  1.25% : {data?.home_early?.lte_90_minute || '-'}
                </div>
                <div className="col">
                  1.50% : {data?.home_early?.mt_90_minute || '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Tidak Hadir
          </label>
          <div className="col-sm-9">
            <div className="col-form-label pb-0 pt-0 fw-normal">
              3% : {data?.absence?.no_work || '-'}
            </div>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Tidak Rekam
          </label>
          <div className="col-sm-9">
            <div className="col-form-label pb-0 pt-0 fw-normal">
              1.5% : {data?.no_attendance || '-'}
            </div>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">
            Izin &#62; 2
          </label>
          <div className="col-sm-9">
            <div className="col-form-label pb-0 pt-0 fw-normal">
              3% : {data?.absence?.pmt_two_days || '-'}
            </div>
          </div>
        </div>
        <hr />
        <h5>Capaian Kerja (Dihitung persentase)</h5>
        <div className="row">
          <label className="col-sm-3 col-form-label pb-0 pt-0">Nilai</label>
          <div className="col-sm-9">
            <p className="col-form-label pb-0 pt-0 fw-normal text-end">
              {data?.achievement_value}%
            </p>
          </div>
        </div>
        <hr />
        <h5>Gaji</h5>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <label className="col-sm-4 col-form-label pb-0 pt-0">
                Gaji Pokok
              </label>
              <div className="col-sm-8">
                <p className="col-form-label pb-0 pt-0 fw-normal text-end">
                  {formatRupiah(data?.salary)}
                </p>
              </div>
            </div>
            <div className="row">
              <label className="col-sm-4 col-form-label pb-1 pt-0">
                Komisi Tambahan
              </label>
              <div className="col-sm-8">
                <p className="col-form-label pb-1 pt-0 fw-normal text-end ">
                  0
                </p>
              </div>
            </div>
            <div className="row border-top">
              <label className="col-sm-4 col-form-label pt-1"></label>
              <div className="col-sm-8">
                <p className="col-form-label pt-1 fw-bolder text-end">
                  {formatRupiah(totalSalary)}
                </p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label pb-0 pt-0">
                Potongan Presensi
              </label>
              <div className="col-sm-8">
                <p className="col-form-label pb-0 pt-0 fw-normal text-end">
                  {data?.attendance_deduction
                    ? '-' + formatRupiah(data?.attendance_deduction)
                    : '0'}
                </p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label pb-0 pt-0">
                Potongan Capaian
              </label>
              <div className="col-sm-8">
                <p className="col-form-label pb-0 pt-0 fw-normal text-end">
                  {data?.achievement_deduction
                    ? '-' + formatRupiah(data?.achievement_deduction)
                    : '0'}
                </p>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-4 col-form-label pb-1 pt-0">
                Potongan PPN(10%)
              </label>
              <div className="col-sm-8">
                <p className="col-form-label pb-1 pt-0 fw-normal text-end">
                  {data?.tax ? '-' + formatRupiah(data?.tax) : '0'}
                </p>
              </div>
            </div>

            <div className="row border-top">
              <label className="col-sm-4 col-form-label pt-1"></label>
              <div className="col-sm-8">
                <p className="col-form-label pt-1 fw-bolder text-end">
                  {formatRupiah(totalSalaryWithDeductions)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          onClick={downloadPDF}
          variant="danger"
          disabled={isDownloadingPDF}
        >
          <i className="fa fa-file-pdf"></i>{' '}
          {isDownloadingPDF ? 'Downloading...' : 'Download PDF'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewDetail;
