import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import useInput from '@hooks/useInput';
import usePersist from '@hooks/usePersist';
import { authApi } from '@services/authApi';
import { setToken } from '@services/api';
import { useEffect, useRef, useState } from 'react';
import {
  setHeaderAuthorizaton,
  useHttpPrivateRequest,
  useHttpRequest,
} from '@hooks/useApiRequest';
import { sendErrorMessage } from '@utils/notification';

function Form() {
  const { setAuth } = useAuth();
  const apiRequest = useHttpPrivateRequest();
  const httpRequest = useHttpRequest();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';

  const userRef = useRef();
  const errRef = useRef();

  const [username, resetUsername, usernameAttirbutes] = useInput(
    'username',
    ''
  );
  const [password, setPassword] = useState('');

  const [persist, toggle] = usePersist('persist', true);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginReq = await httpRequest(authApi.login({ username, password }));
      const loginRes = loginReq?.data;
      const token = setToken(loginRes?.data?.token);

      setHeaderAuthorizaton(apiRequest, token);
      const profileReq = await apiRequest(authApi.profile());
      const profileRes = profileReq?.data;

      setAuth({
        loggedInUser: profileRes?.data,
        roles: loginRes?.data?.roles,
        token,
      });

      resetUsername();
      setPassword();

      navigate(from, { replace: true });
    } catch (error) {
      if (!error?.response) {
        sendErrorMessage('No Server Response');
      } else if (error.response?.status === 400) {
        sendErrorMessage('Missing Username or Password');
      } else if (error.response?.status === 401) {
        sendErrorMessage('Forbidden');
      } else {
        sendErrorMessage(error?.response?.data?.message || error.message);
      }
      errRef.current.focus();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-floating mb-20px">
        <input
          type="text"
          className="form-control fs-13px h-45px border-0"
          placeholder="Username"
          id="username"
          ref={userRef}
          autoComplete="off"
          {...usernameAttirbutes}
          required
        />
        <label
          htmlFor="username"
          className="d-flex align-items-center text-gray-600 fs-13px"
        >
          Username
        </label>
      </div>
      <div className="form-floating mb-20px">
        <input
          type="password"
          className="form-control fs-13px h-45px border-0"
          placeholder="Password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <label
          htmlFor="password"
          className="d-flex align-items-center text-gray-600 fs-13px"
        >
          Password
        </label>
      </div>
      <div className="form-check mb-20px">
        <input
          className="form-check-input border-0"
          type="checkbox"
          id="persist"
          checked={persist}
          onChange={toggle}
        />
        <label
          className="form-check-label fs-13px text-gray-500"
          htmlFor="persist"
        >
          Trust This Device
        </label>
      </div>
      <div className="mb-20px">
        <button
          type="submit"
          className="btn btn-success d-block w-100 h-45px btn-lg"
        >
          Log In
        </button>
      </div>
    </form>
  );
}

export default Form;
