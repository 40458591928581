import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpStorageRequest } from '@hooks/useApiRequest';
import { storageApi } from '@services/storageApi';

function DeleteAttachement(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiStorageRequest = useHttpStorageRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    setAbortController(controller);

    const filename = props.filename;

    try {
      await apiStorageRequest(storageApi.delete(filename, signal));
      setIsLoading(false);
      props.onDeleted(filename);
      //sendSuccessMessage('lampiran berhasil dihapus');
      setShow(false);
    } catch (error) {
      //sendErrorMessage(error?.response?.data?.message || error.message);
      error.log(error?.response?.data?.message || error.message);
      setIsLoading(false);
      setShow(false);
    }
  };

  return (
    <Modal
      size="sm"
      contentClassName="shadow"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Hapus Lampiran</Modal.Title>
      </Modal.Header>
      <Modal.Body>Anda yakin akan menghapus lampiran ini?</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button onClick={handleDelete} variant="danger" disabled={isLoading}>
          {isLoading ? 'Menghapus...' : 'Hapus'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAttachement;
