import { BASE_API } from './api';

export const logBookApi = {
  getMonthlyAssessments: (date, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-monthly-assessment-by-date',
      params: { date },
      signal: signal,
    };
  },
  addActivity: (payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/add-log-book',
      data: payload,
      signal: signal,
    };
  },

  deleteActivity: (ids, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/delete-log-book',
      data: { id: ids },
      signal: signal,
    };
  },
  viewActivity: (id, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/view-detail-log-book/' + id,
      signal: signal,
    };
  },
  listActivities: (period, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/view-log-book',
      params: { startDate: period.startDate, endDate: period.endDate },
      signal: signal,
    };
  },
};
