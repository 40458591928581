import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
//import jwt_decode from 'jwt-decode';

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  //get roles from access token
  //const decoded = auth?.token ? jwt_decode(auth.token) : undefined;
  //const roles = decoded?.UserInfo?.roles || [];
  const roles = auth?.roles || [];

  return roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.loggedInUser ? (
    <Navigate to="/Forbidden" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
