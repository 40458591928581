import { useContext, useEffect, useState } from 'react';
import { DataContext, PeriodContext } from './ResultAchievementPage';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import ViewDetail from './ViewDetail';

function DataList(props) {
  const { periodid } = useContext(PeriodContext);
  const { data, reloadData } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    showEdit && setShowEdit(false);
  }, [showEdit]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    periodid && reloadData(periodid, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) => (
        <Button
          size="xs"
          variant="default"
          onClick={() => {
            setSelectedRow(row);
            setShowDetail(true);
          }}
        >
          <i className="fas fa-eye"></i>
        </Button>
      ),
    },
    {
      name: 'Periode',
      selector: (row) => row.periode_name,
      cell: (row) => row.periode_name,
      sortable: true,
    },
    {
      name: 'Nilai',
      selector: (row) => row.apraisal_result,
      cell: (row) => row.apraisal_result,
      sortable: true,
      width: '350px;',
      hide: 'md',
    },
  ];

  return (
    <>
      <ViewDetail data={selectedRow} show={showDetail} />

      <DataTable
        responsive={false}
        columns={columns}
        data={data}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
      />
    </>
  );
}

export default DataList;
