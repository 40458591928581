import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import PeriodOptions from './PeriodOptions';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { achievementAssessmentApi } from '@services/achievementAssessmentApi';
import DataList from './DataList';
import StatusLabel from '@components/label/StatusLabel';
import SubmitContractButton from './SubmitContractButton';
import CommentLabel from '@components/label/CommentLabel';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function AchievementAssessmentPage(props) {
  const [title] = useState('Capaian Kerja');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Penilaian Kinerja' },
    { title },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [monthPeriodOptions, setMonthPeriodOptions] = useState([]);
  const [selectedMonthPeriod, setSelectedMonthPeriod] = useState(null);

  const parentid = selectedPeriod?.value;
  const periodid = selectedMonthPeriod?.value;

  const [data, setData] = useState();

  const initialTotal = {
    tAchievement: 0,
    rAchievement: 0,
    tQuality: 0,
    rQuality: 0,
    tTime: 0,
    rTime: 0,
  };

  const [total, setTotal] = useState(initialTotal);

  const [list, setList] = useState([]);
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const apiRequest = useHttpPrivateRequest();

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const reloadData = async (periodid, signal) => {
    try {
      const request = await apiRequest(
        achievementAssessmentApi.get(periodid, signal)
      );

      const resData = request?.data?.data;

      setData(resData);
      setTotal({
        tAchievement: resData?.target_achievement,
        rAchievement: resData?.result_achievement,
        tQuality: resData?.target_quality,
        rQuality: resData?.result_quality,
        tTime: resData?.target_time,
        rTime: resData?.result_time,
      });

      setList(resData?.list);
      setStatus(resData?.status);
      setComment(resData?.comment);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    } catch (error) {
      setData([]);
      setTotal(initialTotal);
      setList([]);
      setStatus('');
      setComment('');
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
  };

  const listCount = list?.length || 0;

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          monthPeriodOptions,
          setMonthPeriodOptions,
          selectedMonthPeriod,
          setSelectedMonthPeriod,
          parentid,
          periodid,
        }}
      >
        <Panel>
          <PanelHeader>Filter Periode</PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            data,
            setData,
            reloadData,
            total,
            setTotal,
            list,
            setList,
            selectedRows,
            setSelectedRows,
            toggleCleared,
            setToggleCleared,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-start mb-3 gap-3 ">
                  {periodid &&
                  (status === 'DRAFT' || status === 'REJECTED') &&
                  listCount > 0 ? (
                    <SubmitContractButton />
                  ) : null}
                </div>
                {status && (
                  <div className="col-4 fs-4 d-flex justify-content-end align-items-center mb-3 gap-3">
                    Status <StatusLabel status={status} />
                  </div>
                )}
              </div>

              {comment && <CommentLabel comment={comment} status={status} />}

              <DataList />
            </PanelBody>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default AchievementAssessmentPage;
