import { ADMIN_API } from './api';

export const companyApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-company',
      params: {
        // page: params.page || 1,
        // limit: params.pageSize || 10,
        // order: params.order || 'division_name',
        // sort: params.sort || 'ASC',
        // q: params.q || '',
      },
      signal,
    };
  },
};
