import { ADMIN_API, BASE_API } from './api';

export const periodApi = {
  getYears: (signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-period-year',
      signal: signal,
    };
  },
  getMonths: (parentid, signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-period?parentId=' + parentid,
      signal: signal,
    };
  },

  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-period',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'type',
        type: params.type || 'ALL',
        sort: params.sort || 'ASC',
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-period',
      data: {
        name: payload.name,
        periodeYear: parseInt(payload.periodeYear),
        start: payload.start,
        end: payload.end,
        expired: payload.expired,
        periodType: payload.periodType,
        parentId: payload.parentId,
        unitTime: payload.unitTime,
      },
      signal,
    };
  },
  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/edit-period-by-id',
      data: {
        id: payload.id,
        name: payload.name,
        periodeYear: parseInt(payload.periodeYear),
        start: payload.start,
        end: payload.end,
        expired: payload.expired,
        periodType: payload.periodType,
        parentId: payload.parentId,
        unitTime: payload.unitTime,
      },
      signal,
    };
  },
  updateStatusByAdmin: (periodid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-period/' + periodid,
      signal,
    };
  },
};
