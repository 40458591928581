import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { DataContext } from './DivisionPage';

function Filter(props) {
  const { reloadData, filterParams, mergeFilters } = useContext(DataContext);

  const orderOptions = [{ value: 'division_name', label: 'Satuan Organisasi' }];

  const sortOptions = [
    { value: 'ASC', label: 'Ascending' },
    { value: 'DESC', label: 'Descending' },
  ];

  const [abortController, setAbortController] = useState(null);

  const [orderSelected, setOrderSelected] = useState('');
  const [sortSelected, setSortSelected] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    mergeFilters({
      order: orderSelected?.value,
      sort: sortSelected?.value,
    });
    setShow(true);
  };

  const handleOrderChange = (e) => {
    setOrderSelected(e);
    mergeFilters({ order: e?.value });
  };
  const handleSortChange = (e) => {
    setSortSelected(e);
    mergeFilters({ sort: e?.value });
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);
    reloadData(filterParams, signal);
    handleClose();
  };

  return (
    <Modal
      size="xs"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Satuan Organisasi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          id="filterForm"
          onSubmit={handleSubmit}
          className="mt-3 pe-5 ps-5"
        >
          <p>Urutkan Berdasarkan:</p>
          <div className="row">
            <div className="col-12 col-sm-6 mb-3">
              <Select
                placeholder="Pilih Kolom"
                options={orderOptions}
                value={orderSelected || ''}
                onChange={handleOrderChange}
              />
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <Select
                options={sortOptions}
                value={sortSelected || ''}
                onChange={handleSortChange}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Batal
        </Button>
        <Button form="filterForm" type="submit" variant="primary">
          Filter
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Filter;
