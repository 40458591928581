import { BASE_API } from './api';

export const UserApi = {
  profile: (signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/profile',
      signal: signal,
    };
  },

  changePassword: (payload, signal) => {
    return {
      method: 'post',
      url: BASE_API + '/v1/change-password',
      data: {
        oldPassword: payload.oldPassword,
        password: payload.newPassword,
        recaptcha: 'test-captcha',
      },
      signal: signal,
    };
  },
};
