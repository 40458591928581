import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import AppRoute from './config/app-route';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-notifications-component/dist/theme.css';
import './index.css';
import './scss/react.scss';

import { AppSettingProvider } from './context/AppSetting';
import { AuthProvider } from './context/AuthProvider.js';
import { ReactNotifications } from 'react-notifications-component';

const container = document.getElementById('root');
const root = createRoot(container);
function App() {
  let element = useRoutes(AppRoute);

  return element;
}

root.render(
  <StrictMode>
    <BrowserRouter>
      <AppSettingProvider>
        <AuthProvider>
          <ReactNotifications />
          <App />
        </AuthProvider>
      </AppSettingProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
