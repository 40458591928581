import React, { useContext, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { DataContext, PeriodContext } from './LogBookPage';
import ViewDetail from './ViewDetail';
import ActivityForm from './ActivityForm';

function CalendarView() {
  const { data, getData } = useContext(DataContext);
  const { setStartPeriod, setEndPeriod } = useContext(PeriodContext);
  const [events, setEvents] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const [showDetail, setShowDetail] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [plugins] = useState([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    listPlugin,
    bootstrapPlugin,
  ]);

  const [headerToolbar] = useState({
    left: 'today',
    center: 'title',
    right: 'prev,next',
  });

  const [buttonText] = useState({
    today: 'Today',
    month: 'Month',
    day: 'Day',
  });

  const [views] = useState({
    timeGrid: {
      eventLimit: 6,
    },
  });

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    showForm && setShowForm(false);
  }, [showForm]);

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  useEffect(() => {
    setEvents(() => {
      return data.map((item) => {
        return {
          id: item.id,
          title: item.title,
          start: item.start_date,
          end: item.date_end,
        };
      });
    });
  }, [data]);

  const handleDatesSet = (dateInfo) => {
    const { start, end } = dateInfo;
    const startDate = new Date(start).toISOString().slice(0, 10);
    const endDate = new Date(end).toISOString().slice(0, 10);

    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    let period = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartPeriod(startDate);
    setEndPeriod(endDate);
    getData(period, signal);
  };

  return (
    <div>
      <FullCalendar
        datesSet={handleDatesSet}
        plugins={plugins}
        initialView="dayGridMonth"
        themeSystem="bootstrap"
        headerToolbar={headerToolbar}
        buttonText={buttonText}
        events={events}
        dateClick={(info) => {
          setShowForm(true);
          setSelectedDate(info.dateStr);
        }}
        eventClick={(info) => {
          setSelectedId(info.event.id);
          setShowDetail(true);
        }}
        views={views}
      />
      <ActivityForm show={showForm} initialDate={selectedDate} />
      <ViewDetail selectedId={selectedId} show={showDetail} />
    </div>
  );
}

export default CalendarView;
