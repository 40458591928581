import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import PeriodOptions from './PeriodOptions';
import GenerateButton from './GenerateButton';
import DuplicateButton from './DuplicateButton';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { contractAssessmentApi } from '@services/contractAssessmentApi';
import DataList from './DataList';
import StatusLabel from '@components/label/StatusLabel';
import SubmitContractButton from './SubmitContractButton';
import PrintDocumentModal from './PrintDocument';
import DocumentContent from './DocumentContent';
import CommentLabel from '@components/label/CommentLabel';
import AddAdditionalTaskButton from './AddAdditionalTaskButton';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function ContractAssessmentPage(props) {
  const [title] = useState('Kontrak Kerja');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Penilaian Kinerja' },
    { title },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const periodid = selectedPeriod?.value;

  const [data, setData] = useState();
  const [list, setList] = useState([]);
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const apiRequest = useHttpPrivateRequest();

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const reloadData = async (periodid, signal) => {
    try {
      const request = await apiRequest(
        contractAssessmentApi.get(periodid, signal)
      );
      const response = request?.data;
      setData(response?.data);
      setList(response?.data?.list);
      setStatus(response?.data?.status);
      setComment(response?.data?.comment);
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    } catch (error) {
      setComment('');
      setData([]);
      setList([]);
      setStatus('');
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
  };

  const requireCasecading = list.some((item) => item.cascading !== '');
  const listCount = list?.length || 0;

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          periodid,
        }}
      >
        <Panel>
          <PanelHeader>Filter Periode</PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            data,
            setData,
            reloadData,
            list,
            setList,
            requireCasecading,
            selectedRows,
            setSelectedRows,
            toggleCleared,
            setToggleCleared,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-start mb-3 gap-3 ">
                  {status !== 'APPROVED' && status !== 'APPROVAL' ? (
                    <>
                      <GenerateButton />
                      <DuplicateButton />
                      <AddAdditionalTaskButton />
                    </>
                  ) : null}

                  {(status === 'DRAFT' || status === 'REJECTED') &&
                  listCount > 0 ? (
                    <SubmitContractButton />
                  ) : null}
                  {status === 'APPROVED' ? (
                    <PrintDocumentModal content={<DocumentContent />} />
                  ) : null}
                </div>
                {status && (
                  <div className="col-4 fs-4 d-flex justify-content-end align-items-center mb-3 gap-3">
                    Status <StatusLabel status={status} />
                  </div>
                )}
              </div>

              {comment && <CommentLabel comment={comment} status={status} />}

              <DataList />
            </PanelBody>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default ContractAssessmentPage;
