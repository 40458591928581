import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import SearchForm from './search/form.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';

import { useAppSetting } from './../../context/AppSetting';

function Header() {
  const [state, dispatch] = useAppSetting();

  return (
    <div
      id="header"
      className={
        'app-header ' + (state.appHeaderInverse ? 'app-header-inverse' : '')
      }
    >
      <div className="navbar-header">
        {state.appSidebarTwo && (
          <button
            type="button"
            className="navbar-mobile-toggler"
            onClick={state.toggleAppSidebarEndMobile}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
        <Link to="/" className="navbar-brand">
          {/* <span className="navbar-logo"></span>{' '} */}
          <img className="me-3" src={'/assets/img/logo/logo.png'} alt="logo" />
          <b>{'Siremun'}</b>
        </Link>

        {state.appHeaderMegaMenu && (
          <button
            type="button"
            className="navbar-mobile-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#top-navbar"
          >
            <span className="fa-stack fa-lg text-inverse">
              <i className="far fa-square fa-stack-2x"></i>
              <i className="fa fa-cog fa-stack-1x"></i>
            </span>
          </button>
        )}
        {state.appTopMenu && !state.appSidebarNone && (
          <button
            type="button"
            className="navbar-mobile-toggler"
            onClick={state.toggleAppTopMenuMobile}
          >
            <span className="fa-stack fa-lg text-inverse">
              <i className="far fa-square fa-stack-2x"></i>
              <i className="fa fa-cog fa-stack-1x"></i>
            </span>
          </button>
        )}
        {state.appSidebarNone && state.appTopMenu && (
          <button
            type="button"
            className="navbar-mobile-toggler"
            onClick={state.toggleAppTopMenuMobile}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
        {!state.appSidebarNone && (
          <button
            type="button"
            className="navbar-mobile-toggler"
            onClick={() => {
              dispatch({ type: 'TOGGLE_APP_SIDEBAR_MOBILE' });
            }}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
      </div>

      {state.appHeaderMegaMenu && <DropdownMegaMenu />}

      <div className="navbar-nav">
        {/* <SearchForm />
        <DropdownNotification /> */}

        {state.appHeaderLanguageBar && <DropdownLanguage />}

        <DropdownProfile />

        {state.appSidebarTwo && (
          <div className="navbar-divider d-none d-md-block"></div>
        )}

        {state.appSidebarTwo && (
          <div className="navbar-item d-none d-md-block">
            <Link
              to="/"
              onClick={state.toggleAppSidebarEnd}
              className="navbar-link icon"
            >
              <i className="fa fa-th"></i>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
