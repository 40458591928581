import { createContext, useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DataList from './DataList';
import { salaryReportApi } from '@services/salaryReportApi';
import PeriodOptions from './PeriodOptions';

export const PeriodContext = createContext({});
export const DataContext = createContext({});

function SalaryReportPage(props) {
  const [title] = useState('Laporan Gaji');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Laporan' },
    { title: 'Gaji' },
  ]);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [monthPeriodOptions, setMonthPeriodOptions] = useState([]);
  const [selectedMonthPeriod, setSelectedMonthPeriod] = useState(null);

  const parentid = selectedPeriod?.value;
  const periodid = selectedMonthPeriod?.value;
  const periodName = selectedMonthPeriod?.label;

  const [data, setData] = useState();
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items from the server
  const [filterParams, setFilterParams] = useState({}); // Total number of items from the server

  const mergeFilters = (newData) => {
    setFilterParams((prevData) => ({
      ...prevData, // Spread the previous state
      ...newData, // Spread the new data
    }));
  };

  const [loading, setLoading] = useState(true);

  const apiRequest = useHttpPrivateRequest();

  const reloadData = async (periodid, params, signal) => {
    try {
      setLoading(true);
      const request = await apiRequest(
        salaryReportApi.getListByAdmin(periodid, params, signal)
      );

      const response = request?.data;

      setData(response.data);
      setTotalItems(response.meta?.count);

      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  };

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <PeriodContext.Provider
        value={{
          periodOptions,
          setPeriodOptions,
          selectedPeriod,
          setSelectedPeriod,
          monthPeriodOptions,
          setMonthPeriodOptions,
          selectedMonthPeriod,
          setSelectedMonthPeriod,
          parentid,
          periodid,
          periodName,
        }}
      >
        <Panel>
          <PanelHeader>Periode</PanelHeader>
          <PanelBody>
            <PeriodOptions />
          </PanelBody>
        </Panel>

        <DataContext.Provider
          value={{
            data,
            setData,
            reloadData,
            page,
            setPage,
            pageSize,
            setPageSize,
            totalItems,
            setTotalItems,
            filterParams,
            setFilterParams,
            mergeFilters,
            loading,
            setLoading,
          }}
        >
          <Panel>
            <PanelHeader>Panel Data</PanelHeader>
            <PanelBody>
              <DataList />
            </PanelBody>
          </Panel>
        </DataContext.Provider>
      </PeriodContext.Provider>
    </Page>
  );
}

export default SalaryReportPage;
