import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { DataContext, PeriodContext } from './LogBookPage';

function PeriodOptions(props) {
  const { getData } = useContext(DataContext);
  const { startPeriod, setStartPeriod, endPeriod, setEndPeriod } =
    useContext(PeriodContext);
  const [abortController, setAbortController] = useState(null);

  const currentDate = new Date();
  let defaultStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  let defaultEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const formatDefaultDate = (date) => {
    var getYear = date.toLocaleString('default', { year: 'numeric' });
    var getMonth = date.toLocaleString('default', { month: '2-digit' });
    var getDay = date.toLocaleString('default', { day: '2-digit' });

    return getYear + '-' + getMonth + '-' + getDay;
  };

  useEffect(() => {
    setStartPeriod(formatDefaultDate(defaultStartDate));
    setEndPeriod(formatDefaultDate(defaultEndDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleStartPeriodChange = (currentDate) => {
    if (currentDate) {
      setStartPeriod(currentDate);
    }
  };

  const isValidEndPeriod = (currentDate) => {
    if (isDate(currentDate) && isDate(startPeriod)) {
      const startDateWithoutTime = startPeriod.clone().startOf('day');
      const currentDateWithoutTime = currentDate.clone().startOf('day');
      return !currentDateWithoutTime.isBefore(startDateWithoutTime, 'day');
    }

    return true;
  };
  const isValidStartPeriod = (currentDate) => {
    return true;
  };
  const handleEndPeriodChange = (currentDate) => {
    if (currentDate) {
      setEndPeriod(currentDate);
    }
  };
  const handleSeachButton = (e) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    let period = {
      startDate: formatDate(startPeriod),
      endDate: formatDate(endPeriod),
    };

    getData(period, signal);
  };

  function formatDate(date) {
    try {
      if (date && date?.isValid()) {
        return date.format('YYYY-MM-DD');
      }
    } catch (e) {
      return date;
    }
  }

  function isDate(date) {
    try {
      return date && date?.isValid();
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      <div className="form-group d-flex justify-content-start gap-3">
        <label className="col-form-label">Periode</label>
        <div className="">
          <div className="row gx-2">
            <div className="col-12 sm-12 mb-1 col-md-5">
              <DateTime
                value={startPeriod}
                isValidDate={isValidStartPeriod}
                onChange={handleStartPeriodChange}
                inputProps={{ placeholder: 'Waktu awal' }}
                closeOnSelect={true}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
              />
            </div>
            <div className="col-9 mb-1 col-md-5">
              <DateTime
                value={endPeriod}
                isValidDate={isValidEndPeriod}
                onChange={handleEndPeriodChange}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                inputProps={{ placeholder: 'Waktu akhir' }}
                closeOnSelect={true}
              />
            </div>
            <div className="col-3 mb-1 col-md-2">
              <Button onClick={handleSeachButton}>Cari</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeriodOptions;
