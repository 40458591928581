import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Form as BSForm } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import 'react-datepicker/dist/react-datepicker.css';
import { jobsApi } from '@services/jobsApi';
import { DataContext } from './JobsPage';

function Form(props) {
  const value = props.data;
  const isEdit = props.type === 'UPDATE';

  const { setData } = useContext(DataContext);

  const [jobName, setJobName] = useState();
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [activeStatus, setActiveStatus] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setIsLoading(false);

    setJobName(value?.title || '');
    setMinSalary(value?.min_salary);
    setMaxSalary(value?.max_salary);
    setActiveStatus(
      value?.status === 'ENABLE' || value?.status === 'Y' ? true : false
    );

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      title: jobName,
      min_salary: minSalary,
      max_salary: maxSalary,
      status: activeStatus ? 'Y' : 'N',
    };

    const apiConfig = isEdit
      ? jobsApi.updateByAdmin(payload, signal)
      : jobsApi.createByAdmin(payload, signal);

    try {
      const response = await apiRequest(apiConfig);

      setIsLoading(false);

      if (isEdit) {
        //update datatable
        setData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === value.id) {
              return {
                ...prev,
                ...payload,
              };
            }
            return prev;
          });
        });
        sendSuccessMessage('Berhasil perbarui data');
      } else {
        payload.id = response.data.data.id;
        setData((prevList) => {
          return [...prevList, payload];
        });

        sendSuccessMessage('Berhasil simpan data');
      }

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Golongan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Golongan <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan golongan"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Gaji Minimal <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="number"
                step="0.01"
                className="form-control"
                placeholder="Masukkan gaji minimal"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Gaji Maksimal <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="number"
                step="0.01"
                className="form-control"
                placeholder="Masukkan gaji maksimal"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">Status</label>
            <div className="col-12 col-sm-8 d-flex align-items-center">
              <BSForm.Check
                type="switch"
                id="active-status"
                checked={activeStatus}
                onChange={(event) => {
                  setActiveStatus(!activeStatus);
                }}
                label={activeStatus ? 'Aktif' : 'Nonaktif'}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
