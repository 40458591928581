import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { salaryReportApi } from '@services/salaryReportApi';
import { DataContext, PeriodContext } from './SalaryReportPage';

function GenerateDialog(props) {
  const value = props.data;
  const { periodid, periodName } = useContext(PeriodContext);

  const { reloadData, filterParams } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setIsLoading(false);

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleGenerate = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    try {
      await apiRequest(salaryReportApi.generate(periodid, signal));
      setIsLoading(false);

      reloadData(periodid, filterParams, signal);

      sendSuccessMessage(
        `Berhasil generate data laporan gaji periode ${periodName}`
      );

      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      contentClassName="shadow"
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Generate Laporan Gaji</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Apakah anda yakin akan generate laporan gaji periode <b>{periodName}</b>
        ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button onClick={handleGenerate} variant="primary" disabled={isLoading}>
          {isLoading ? 'Generating...' : 'Generate'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenerateDialog;
