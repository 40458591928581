import EditForm from './EditForm';
import { useContext, useEffect, useState } from 'react';
import { DataContext, PeriodContext } from './AssessmentComponentPage';
import DataTable from 'react-data-table-component';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import ViewDetail from './ViewDetail';
import DeleteDialog from './DeleteDialog';
import InfoTotal from './InfoTotal';

function DataList(props) {
  const { parentid, periodid } = useContext(PeriodContext);
  const {
    data,
    list,
    reloadData,
    selectedRows,
    setSelectedRows,
    toggleCleared,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const status = data?.status;

  const handleRowSelected = (rows) => {
    !showDelete && setSelectedRows(rows.selectedRows);
  };

  useEffect(() => {
    showDetail && setShowDetail(false);
  }, [showDetail]);

  useEffect(() => {
    showEdit && setShowEdit(false);
  }, [showEdit]);

  useEffect(() => {
    showDelete && setShowDelete(false);
  }, [showDelete]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (parentid || periodid) && reloadData(periodid, signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodid]);

  const columns = [
    {
      button: true,
      name: '#',
      width: '42px',
      cell: (row) =>
        status !== 'APPROVED' && status !== 'APPROVAL' ? (
          <DropdownButton
            title={<i className="fas fa-ellipsis-v"></i>}
            variant="default"
          >
            <Dropdown.Item
              onClick={() => {
                setSelectedRow(row);
                setShowDetail(true);
              }}
            >
              <i className="fas fa-eye"></i> Lihat Rincian
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                setSelectedRow(row);
                setShowEdit(true);
              }}
            >
              <i className="fas fa-edit"></i> Edit Rincian
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              className="text-danger"
              onClick={() => {
                setSelectedRows([row]);
                setShowDelete(true);
              }}
            >
              <i className="fas fa-trash"></i> Hapus Data
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <Button
            size="xs"
            variant="default"
            onClick={() => {
              setSelectedRow(row);
              setShowDetail(true);
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
    },

    {
      name: 'Indikator',
      selector: (row) => row.indicator,
      cell: (row) => (
        <p
          className="cursor-pointer btn-link text-decoration-none m-0 p-0"
          onClick={() => {
            setSelectedRow(row);
            setShowDetail(true);
          }}
        >
          {row.indicator}
        </p>
      ),
      sortable: true,
      grow: 2,
    },

    {
      name: 'Satuan',
      selector: (row) => row.unit,
      cell: (row) => row.unit,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
    {
      name: 'Target',
      selector: (row) => row.target,
      cell: (row) => row.target,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
    {
      name: 'Kualitas',
      selector: (row) => row.quality,
      cell: (row) => row.quality,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
    {
      name: 'Waktu',
      selector: (row) => row.time,
      cell: (row) => row.time,
      sortable: true,
      width: '100px',
      hide: 'sm',
    },
  ];

  return (
    <>
      <ViewDetail data={selectedRow} show={showDetail} />
      <DeleteDialog data={selectedRows} show={showDelete} />
      <EditForm data={selectedRow} show={showEdit} />

      {selectedRows && selectedRows.length > 0 && (
        <div className="row mb-3">
          <div className="col-12">
            <Button
              variant="danger"
              className="d-block w-100"
              onClick={() => {
                setShowDelete(true);
              }}
            >
              <i className="fas fa-trash"></i> Hapus {selectedRows.length} Data
              Beban Kerja
            </Button>
          </div>
        </div>
      )}

      <DataTable
        responsive={false}
        columns={columns}
        data={list}
        selectableRows={status !== 'APPROVED' && status !== 'APPROVAL'}
        selectedRows={selectedRows}
        clearSelectedRows={toggleCleared}
        onSelectedRowsChange={handleRowSelected}
        selectableRowDisabled={(row) => {
          return showDelete;
        }}
        pagination
        paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
      />

      {list && list.length > 0 && <InfoTotal />}
    </>
  );
}

export default DataList;
