import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PeriodContext } from './AchievementAssessmentPage';

function ViewDetail(props) {
  const value = props.data;
  const { selectedMonthPeriod } = useContext(PeriodContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Rincian Capaian Kerja {selectedMonthPeriod?.label}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <label className="col-sm-3 col-form-label">Indikator</label>
          <div className="col-sm-9">
            <p className="col-form-label fw-normal">{value.indicator}</p>
          </div>
        </div>

        {props?.showCategory && (
          <div className="row">
            <label className="col-sm-3 col-form-label">Kategori</label>
            <div className="col-sm-9">
              <p className="col-form-label fw-normal">{value.category}</p>
            </div>
          </div>
        )}

        <div className="row">
          <label className="col-sm-3 col-form-label">Target Kuantitas</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">{value.target_value} </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_value}{' '}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Target Kualitas</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.target_quality_value}{' '}
            </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_quality_value}{' '}
            </p>
          </div>
        </div>
        <div className="row">
          <label className="col-sm-3 col-form-label">Target Waktu</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.target_time_value}{' '}
            </p>
          </div>
          <label className="col-sm-3 col-form-label">Realisasi</label>
          <div className="col-sm-3">
            <p className="col-form-label fw-normal">
              {value.achievement_time_value}{' '}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewDetail;
