import Page from '@components/page/page';
import useAuth from '@hooks/useAuth';
import useLogout from '@hooks/useLogout';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Forbidden = () => {
  const [title] = useState('Akses Terlarang');
  const navigate = useNavigate();

  const { auth } = useAuth();

  const logout = useLogout();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  useEffect(() => {
    if (!auth?.loggedInUser) {
      navigate('/login');
    }
  }, [auth?.loggedInUser, navigate]);

  const goBack = () => navigate(-1);

  return (
    <Page title={title} hideTitle>
      <div className="error">
        <div className="error-code">403</div>
        <div className="error-content">
          <div className="error-message">Akses Terlarang!</div>
          <div className="error-desc mb-4">
            Kamu tidak punya akses untuk halaman ini.
          </div>
          <div>
            <div className="d-flex justify-content-center gap-3">
              <Link onClick={goBack} className="btn btn-default px-3">
                <i className="fas fa-arrow-left"></i> Kembali
              </Link>

              <Link to="/home" className="btn btn-primary px-3">
                <i className="fas fa-home"></i> Beranda
              </Link>

              {auth && (
                <Button variant="default" onClick={handleLogout}>
                  <i className="fas fa-door-open me-2"> </i>Logout
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Forbidden;
