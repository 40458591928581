import React, { useEffect, useState } from 'react';
import Page from '@components/page/page.jsx';
import { Panel, PanelHeader, PanelBody } from '@components/panel/panel.jsx';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import { UserApi } from '@services/userApi';
// import { Button } from 'react-bootstrap';
import { typePerformanceApi } from '@services/typePerformanceApi';

function HomePage() {
  const [title] = useState('Informasi Pribadi');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Informasi Pribadi' },
  ]);

  const apiRequest = useHttpPrivateRequest();
  const [data, setData] = useState('');
  const [typePerformances, setTypePerformances] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    const getProfile = async () => {
      try {
        const request = await apiRequest(UserApi.profile(controller.signal));
        const response = request?.data;
        setData(response?.data);
      } catch (error) {}
    };

    getProfile();

    const getTypePerformance = async () => {
      try {
        const request = await apiRequest(
          typePerformanceApi.get(controller.signal)
        );
        const response = request?.data;
        setTypePerformances(response?.data);
      } catch (error) {}
    };

    getTypePerformance();

    return () => {
      controller.abort();
    };
  }, [apiRequest]);

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-8 offset-md-2 mt-5 mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Personal Info</h4>
                {/* <Button variant="dark" size="sm">
                  <i className="fas fa-edit"></i> Edit
                </Button> */}
              </div>
              <hr />
              <div className="row">
                <label className="col-sm-3 col-form-label">Nama Lengkap</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{data.fullname}</p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Kewenangan</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal d-flex align-items-center gap-2">
                    {typePerformances &&
                      typePerformances.map((typePerformance, index) => {
                        return (
                          <span key={index} className="badge bg-success fs-6">
                            {typePerformance.performance_type}
                          </span>
                        );
                      })}
                  </p>
                </div>
              </div>

              <h4 className="mt-5">Info Pegawai</h4>
              <hr />
              <div className="row">
                <label className="col-sm-3 col-form-label">NIP Pegawai</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {data.employee_gen}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Tipe Pegawai</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {data.employee_type}
                  </p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Nama Golongan</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{data.section}</p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Satuan Organisasi</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{data.division}</p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Unit Organisasi</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{data.department}</p>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3 col-form-label">Atasan Langsung</label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">{data.manager}</p>
                </div>
              </div>
              <div className="row">
                <label className="col-sm-3 col-form-label">
                  Golongan Atasan Langsung
                </label>
                <div className="col-sm-9">
                  <p className="col-form-label fw-normal">
                    {data.section_manager}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </Page>
  );
}

export default HomePage;
