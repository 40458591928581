import { useState } from 'react';
import Page from '@components/page/page';
import { Panel, PanelBody, PanelHeader } from '@components/panel/panel';
import Select from 'react-select';

import ComponentsTable from './ComponentsTable';

function SalaryPage() {
  const [title] = useState('Daftar Gaji');
  const [breadcrumb] = useState([
    { title: 'Beranda', url: '/home' },
    { title: 'Penilaian Kinerja' },
    { title: 'Daftar Garji' },
  ]);

  const periodOptions = [
    { value: '01-01-2023', label: 'Januari 2023' },
    { value: '01-02-2023', label: 'Februari 2023' },
    { value: '01-03-2023', label: 'Maret 2023' },
  ];

  return (
    <Page title={title} breadcrumb={breadcrumb}>
      <Panel>
        <PanelHeader>Filter Periode</PanelHeader>
        <PanelBody>
          <Select options={periodOptions} />
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader>Panel Data</PanelHeader>
        <PanelBody>
          <ComponentsTable />
        </PanelBody>
      </Panel>
    </Page>
  );
}

export default SalaryPage;
