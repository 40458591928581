import { sendErrorMessage, sendSuccessMessage } from '@utils/notification';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DataContext } from './PeriodPage';
import { useHttpPrivateRequest } from '@hooks/useApiRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString, stringToDate } from '@utils/common';
import Select from 'react-select';
import { periodApi } from '@services/periodApi';

function Form(props) {
  const value = props.data || {};
  const isEdit = props.type === 'UPDATE';

  const { setData } = useContext(DataContext);

  const periodTypeOptions = [
    { value: 'CONTRACT', label: 'CONTRACT' },
    { value: 'MONTHLY', label: 'MONTHLY' },
  ];

  const [subPeriodOptions, setSubPeriodOptions] = useState([]);
  const [selectedSubPeriod, setSelectedSubPeriod] = useState('');

  const unitTimeOptions = [
    { value: 'TRIWULAN', label: 'TRIWULAN' },
    { value: 'SEMESTER', label: 'SEMESTER' },
    { value: 'TAHUN', label: 'TAHUN' },
  ];

  const [selectedUnitTime, setSelectedUnitTime] = useState('');

  const [periodName, setPeriodName] = useState('');
  const [periodYear, setPeriodYear] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [expired, setExpired] = useState('');
  const [periodType, setPeriodType] = useState('');
  const [parentId, setParentId] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const initialForm = () => {
    setPeriodName('');
    setPeriodYear('');
    setStart('');
    setEnd('');
    setExpired('');
    setPeriodType('');
    setSelectedUnitTime('');
    setParentId('');
  };

  const handleShow = () => {
    setIsLoading(false);
    initialForm();

    if (isEdit) {
      setPeriodName(value?.name);
      setPeriodYear(value?.periodeYear);
      setStart(stringToDate(value?.start));
      setEnd(stringToDate(value?.end));
      setExpired(stringToDate(value?.expired));

      const optionIndex = periodTypeOptions.findIndex(
        (option) => option.value === value?.periodType
      );

      setPeriodType(periodTypeOptions[optionIndex]);

      const optionUnitTimeIndex = unitTimeOptions.findIndex(
        (option) => option.value === value?.unitTime
      );

      setSelectedUnitTime(unitTimeOptions[optionUnitTimeIndex]);
    }

    if (value?.periodType === 'MONTHLY') {
      getSubPeriod(value?.parentId);
    }

    setShow(true);
  };

  useEffect(() => {
    props.show && handleShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const apiRequest = useHttpPrivateRequest();

  useEffect(() => {
    return () => {
      // Cancel any pending API requests when the component unmounts
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const payload = {
      id: value.id,
      name: periodName,
      periodeYear: periodYear,
      start: dateToString(start),
      end: dateToString(end),
      expired: dateToString(expired),
      periodType: periodType.value,
      parentId: parentId,
      parentName: selectedSubPeriod?.label,
      unitTime: selectedUnitTime?.value,
    };

    const apiConfig = isEdit
      ? periodApi.updateByAdmin(payload, signal)
      : periodApi.createByAdmin(payload, signal);

    try {
      const response = await apiRequest(apiConfig);

      setIsLoading(false);

      if (isEdit) {
        //update datatable
        setData((prevList) => {
          return prevList.map((prev) => {
            if (prev.id === value.id) {
              return {
                ...prev,
                ...payload,
              };
            }
            return prev;
          });
        });
      } else {
        setData((prevList) => {
          const createdData = response?.data?.data;
          return [...prevList, createdData];
        });
      }

      sendSuccessMessage('Berhasil perbarui data');
      setShow(false);
    } catch (error) {
      sendErrorMessage(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handlePeriodTypeChange = async (e) => {
    setPeriodType(e);
    if (e.value === 'MONTHLY') {
      getSubPeriod(e.value);
    } else {
      setSubPeriodOptions([]);
      setSelectedSubPeriod('');
      setParentId('');
    }
  };

  const getSubPeriod = async (selectedValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    setAbortController(controller);

    const params = {
      type: 'CONTRACT',
      pageSize: 1000,
    };

    try {
      const request = await apiRequest(
        periodApi.getListByAdmin(params, signal)
      );

      const response = request?.data?.data;
      const options = response.map((item) => {
        return { value: item.id, label: item.name };
      });

      const indexOption = options.findIndex(
        (option) => option.value === selectedValue
      );

      setSubPeriodOptions(options);
      setSelectedSubPeriod(options[indexOption]);
      setParentId(selectedValue);
    } catch (error) {
      setSubPeriodOptions([]);
      setSelectedSubPeriod('');
      setParentId('');
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{isEdit ? 'Edit ' : 'Tambah '} Periode</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="inputForm" onSubmit={handleSubmit} className="mt-3">
          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Nama Periode <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan nama periode"
                value={periodName}
                onChange={(e) => setPeriodName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Tahun <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <input
                type="number"
                maxLength="4"
                placeholder="Masukkan tahun periode"
                className="form-control"
                value={periodYear}
                onChange={(e) => setPeriodYear(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Mulai <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                selected={start}
                onChange={setStart}
                placeholderText="Pilih tanggal mulai"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Berakhir <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                onChange={setEnd}
                selected={end}
                placeholderText="Pilih tanggal berakhir"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Kadaluarsa <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <DatePicker
                className="form-control"
                onChange={setExpired}
                selected={expired}
                placeholderText="Pilih tanggal kadaluarsa"
                dateFormat="yyyy-MM-dd"
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Tipe <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih tipe periode"
                options={periodTypeOptions}
                value={periodType || ''}
                onChange={handlePeriodTypeChange}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-4 col-form-label">
              Satuan Waktu <sup style={{ color: 'red' }}>*</sup>
            </label>
            <div className="col-12 col-sm-8">
              <Select
                placeholder="Pilih satuan waktu"
                options={unitTimeOptions}
                value={selectedUnitTime || ''}
                onChange={(selected) => {
                  setSelectedUnitTime(selected);
                }}
                required
              />
            </div>
          </div>

          {periodType?.value === 'MONTHLY' && (
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">
                Sub Periode <sup style={{ color: 'red' }}>*</sup>
              </label>
              <div className="col-12 col-sm-8">
                <Select
                  placeholder="Pilih sub periode"
                  options={subPeriodOptions}
                  value={selectedSubPeriod || ''}
                  onChange={(selected) => {
                    setSelectedSubPeriod(selected);
                    setParentId(selected?.value);
                  }}
                  required
                />
              </div>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose} disabled={isLoading}>
          Batal
        </Button>
        <Button
          form="inputForm"
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isEdit ? 'Perbarui ' : 'Simpan'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Form;
