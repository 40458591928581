import { ADMIN_API } from './api';

export const employeeApi = {
  getListByAdmin: (params, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-employees',
      params: {
        page: params.page || 1,
        limit: params.pageSize || 10,
        order: params.order || 'name',
        sort: params.sort || 'ASC',
        q: params.q || '',
      },
      signal,
    };
  },

  getListOptions: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-list-employee-options',
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/create-employee',
      data: {
        employeeGen: payload?.employee_gen,
        firstName: payload?.first_name,
        midlename: payload?.middle_name,
        lastName: payload?.last_name,
        filename: payload?.filename,
        firstDegree: payload?.first_degree,
        lastDegree: payload?.last_degree,
        hireDate: payload?.hire_date,
        employeeType: payload?.employee_type,
        salary: payload?.salary,
        grade: payload?.grade,
        jobId: payload?.job_id,
        manager: payload?.manager_id,
        division: payload?.division_id,
        department: payload?.department_id,
        section: payload?.section_id,
      },
      signal,
    };
  },
  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/edit-employee-by-id',
      data: {
        id: payload?.id,
        employeeGen: payload?.employee_gen,
        firstName: payload?.first_name,
        midlename: payload?.middle_name,
        lastName: payload?.last_name,
        filename: payload?.filename,
        firstDegree: payload?.first_degree,
        lastDegree: payload?.last_degree,
        hireDate: payload?.hire_date,
        employeeType: payload?.employee_type,
        salary: payload?.salary,
        grade: payload?.grade,
        jobId: payload?.job_id,
        manager: payload?.manager_id,
        division: payload?.division_id,
        department: payload?.department_id,
        section: payload?.section_id,
      },
      signal,
    };
  },
  updateStatusByAdmin: (employeeid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/disable-employee-by-id/' + employeeid,
      signal,
    };
  },

  importByAdmin: (file, signal) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mime', file.type);

    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: ADMIN_API + '/v1/import-employees',
      data: formData,
      signal,
    };
  },

  resetPasswordByAdmin: (employeeid, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/reset-password-by-employee/' + employeeid,
      signal,
    };
  },
};
