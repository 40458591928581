import { ADMIN_API, BASE_API } from './api';

export const typePerformanceApi = {
  get: (signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-type-performance-employee',
      signal,
    };
  },

  getListOptions: (signal) => {
    return {
      method: 'get',
      url: BASE_API + '/v1/get-type-performance',
      signal,
    };
  },

  getByAdmin: (employeeId, signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/get-employee-performance/' + employeeId,
      signal,
    };
  },

  getListOptionsByAdmin: (signal) => {
    return {
      method: 'get',
      url: ADMIN_API + '/v1/list-performances',
      params: {
        limit: 1000,
      },
      signal,
    };
  },

  createByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/add-employee-performance',
      data: {
        employee_id: payload?.employee_id,
        performance_type: payload?.performance_type,
      },
      signal,
    };
  },

  updateByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/update-employee-performance',
      data: {
        employee_id: payload?.employee_id,
        performance_type: payload?.performance_type,
      },
      signal,
    };
  },

  deleteByAdmin: (payload, signal) => {
    return {
      method: 'post',
      url: ADMIN_API + '/v1/delete-employee-performance',
      data: {
        employee_id: payload?.employee_id,
        performance_type: payload?.performance_type,
      },
      signal,
    };
  },
};
